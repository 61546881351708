import React, { useState, useEffect } from 'react'
import { createAccount, getDatabases, setMembership } from '../../actions/usersActions';
import { Link, Redirect, useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';

export const NewAccount = () => {
    let history = useHistory();
    const dispatch = useDispatch()
    const [ new_account, setNewAccount ] = useState({
        email:null,
        nombre_cuenta:null,
        nombre:null,
        nombre_empresa:null,
        telefono:null,
        foto:null,
        cuenta:null,
        rol:"",
        isActive:"",
        password:null,
        database:null
    });
    const [suscripcion, setSuscripcion] = useState({
        dbname:null,
        plan:null,
        date_begin:null,
        date_finish:null,
        monthly_emails:null
    });
    const [emails_boolean, setemails_boolean ] = useState(false);
    const [displayMembership, setdisplayMembership] = useState('block');
    const [crece, setCrece] = useState('none');
    const [emprende, setEmprende] = useState('none');
    
    const userAuth = useSelector( state => state.auth.user )
    const databases = useSelector( state => state.user.databases )
    const currentUserAuthenticated = useSelector( state => state.auth.user )
    
    const membershipError = useSelector( state => state.user.membershipError )
    const membership = useSelector( state => state.user.membership )
    const loading = useSelector( state => state.user.loading )

    const handleSubmit = async (e) => {
        // alert('submit')
        e.preventDefault();
        if(new_account.database == null){
            if(suscripcion.monthly_emails == null || suscripcion.monthly_emails == ""){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:"Elegir una cantidad de emails mensuales es obligatorio"
                })
                return;
            }
            if(suscripcion.plan == null){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:"Elegir el plan es obligatorio"
                })
                return;
            }
            if(suscripcion.date_begin == null || suscripcion.date_finish == null){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:"Elegir un rango de tiempo para el plan es obligatorio"
                })
                return;
            }
        }
        if(new_account.email == null || 
            new_account.nombre_cuenta == null || 
            new_account.nombre == null || 
            new_account.nombre_empresa == null || 
            new_account.telefono == null || 
            new_account.rol == null 
            ){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:"Todos los campos de la cuenta son obligatorios"
                })
                return;
        }
        await dispatch(createAccount({new_account:new_account,suscripcion:suscripcion},history));
    }

    const actualizarSuscripcion = async () => {
        await dispatch(setMembership(suscripcion))
    }
    const handleChange = (e) => {
        // console.log('e.target.value',e.target.value)
        if(e.target.name == 'nombre_cuenta'){
            setNewAccount({
                ...new_account,
                [e.target.name]:e.target.value.split(' ').join('_')
            })
        }else{
            if(e.target.name == 'database'){
                if(e.target.value == ''){
                    setdisplayMembership('block')
                }else{

                    setdisplayMembership('none')
                }
            }

            setNewAccount({
                ...new_account,
                [e.target.name]:e.target.value
            })
        }
    }
    Date.isLeapYear = function (year) { 
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    };
    
    Date.getDaysInMonth = function (year, month) {
        return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };
    Date.prototype.isLeapYear = function () { 
        return Date.isLeapYear(this.getFullYear()); 
    };
    
    Date.prototype.getDaysInMonth = function () { 
        return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
    };
    
    Date.prototype.addMonths = function (value) {
        var n = this.getDate();
        this.setDate(1);
        this.setMonth(this.getMonth() + value);
        this.setDate(Math.min(n, this.getDaysInMonth()));
        return this;
    };

    

    const handleChangeSuscripcion = (e) => {
        const plan = e.target.value;
        const currentDate = new Date();
        let nextDate = currentDate;
        switch(plan){
            case "1 mes":
                nextDate = nextDate.addMonths(1);
            break;
            case "3 meses":
                nextDate = nextDate.addMonths(3);
            break;
            case "6 meses":
                nextDate = nextDate.addMonths(6);
            break;
            case "1 año":
                nextDate = nextDate.addMonths(12);
            break;
            default:
                return
        }

        var date = new Date();
        var strdate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        nextDate = nextDate.getFullYear() + "-" + ('0' + (nextDate.getMonth() + 1)).slice(-2) + "-" + ('0' + nextDate.getDate()).slice(-2);
      

        setSuscripcion({
            ...suscripcion,
            date_begin:strdate,
            date_finish:nextDate
        })
    }

    const handleChangePlan = (e) => {
        if(e.target.value == 'Emprende'){
            setSuscripcion({
                ...suscripcion,
                monthly_emails:2000,
                plan:e.target.value
            })
            setemails_boolean(true);
        }else{
            setSuscripcion({
                ...suscripcion,
                monthly_emails:"",
                plan:e.target.value
            })
            setemails_boolean(false);
        }
      
    }

    const handleChangeMontlyEmails = (e) => {
        setSuscripcion({
            ...suscripcion,
            monthly_emails:e.target.value
        })
    }
    useEffect(async () => {
        if(!databases){
            await dispatch(getDatabases());
        }else{
            setNewAccount({
                ...new_account,
                database:databases[0].database
            })
            
        }
        if(currentUserAuthenticated){
            if(currentUserAuthenticated.roles[0].name != 'impulsa'){
                return <Redirect to="/" />
            }
        }
    }, [databases])

    return (
            
            <form onSubmit={ handleSubmit } className="white_box QA_section card_height_100">
                {
                    loading ? <LoaderSpinner/> : null
                }
                <div className="p-4">
                    <span className="mr-3 naranja">Nueva Cuenta</span>
                </div>
                <div className='row'>

                <div className='col-md-6'>

                <Link to="/admin_accounts" className="btn btn-link" >Volver a la lista</Link>
                
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Correo Electrónico</label>
                                <input type="email" required name="email" onChange={ handleChange } placeholder="Correo Electrónico" className="form-control-lg form-control" value={new_account.email} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Nombre para conjunto de Landing Page</label>
                                <input type="text" required name="nombre_cuenta" onChange={ handleChange } placeholder="Nombre para conjunto de Landing Page" className="form-control-lg form-control" value={new_account.nombre_cuenta} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Nombre Empresa</label>
                                <input type="text" required name="nombre_empresa" onChange={ handleChange } placeholder="Nombre Empresa" className="form-control-lg form-control" value={new_account.nombre_empresa} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" required name="nombre" onChange={ handleChange } placeholder="Nombre" className="form-control-lg form-control" value={new_account.nombre} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Teléfono</label>
                                <input type="text" required name="telefono" onChange={ handleChange } placeholder="Teléfono" className="form-control-lg form-control" value={new_account.telefono} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Rol</label>
                                <select name="rol" required value={new_account.rol} onChange={ handleChange } className="form-control-lg form-control">
                                    <option value="">Seleccione</option>
                                    {
                                        userAuth && userAuth.roles[0].name == 'impulsa' ?
                                        <>
                                        <option value="admin">Administrador</option>
                                        <option value="impulsa">Impulsa</option> 
                                        </>
                                        :
                                        null
                                    }
                                    
                                    <option value="user">Usuario</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Estado</label>
                                <select name="isActive" required value={new_account.isActive} onChange={ handleChange } className="form-control-lg form-control">
                                    <option value="">Seleccione</option>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    {
                        userAuth && userAuth.roles && userAuth.roles[0].name == 'impulsa' ?
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Base de datos</label>
                                    <select className="form-control-lg form-control" name="database" value={new_account.database} onChange={ handleChange }>
                                        <option value="">Nueva base de datos</option>
                                        {
                                            databases && databases.length && databases.map( db => (
                                                db.database ? <option value={db.database}>{db.database}</option> : null
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div> : <input type="hidden" name="database" value={databases && databases.length ? databases[0].database : null} />
                        
                    }
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" required name="password" onChange={ handleChange } placeholder="Contraseña" className="form-control-lg form-control" value={new_account.password} />
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="btn btn-success mb-5" value="Crear Cuenta"/>
                

                </div>
                <div className='col-md-6' style={{"display":displayMembership}}>
                            <div className="form-group">
                                <fieldset>
                                    <legend>Afiliación</legend>
                                    <div className="form-group">
                                        <label>Emails Mensuales</label>
                                        <input type='text' className='form-control-lg form-control' value={suscripcion.monthly_emails} readOnly={emails_boolean} style={{"width":"100px"}} name="monthly_emails" onChange={handleChangeMontlyEmails}/>
                                    </div>
                                    <div className="form-group" >
                                        <label>Frecuencia</label>
                                        <select className="form-control-lg form-control"  name="frecuencia" onChange={handleChangeSuscripcion}>
                                            <option value="">Seleccione</option>
                                            <option value="1 mes">1 mes</option>
                                            <option value="3 meses">3 meses</option>
                                            <option value="6 meses">6 meses</option>
                                            <option value="1 año">1 año</option>
                                        </select>
                                    </div>
                                    <div className="form-group" >
                                        <label>Plan</label>
                                        <select className="form-control-lg form-control"  name="plan" onChange={handleChangePlan}>
                                            <option value="">Seleccione</option>
                                            <option value="Emprende">Emprende</option>
                                            <option value="Crece">Crece</option>
                                        </select>
                                    </div>
                                    <div id="emprende" className={{'display': emprende ? 'block' : 'none'}}>
                                        <h3>Emprende</h3>
                                        <ul>
                                            <li>Puede usar hasta 500 clientes</li>
                                            <li>Módulo contacto no lo puede utilizar. Este módulo solo disponible Plan Crece.</li>
                                            <li>Puede enviar 2.000 emails mensuales máximo (mes calendario)</li>
                                            <li>Puede crear 20 landing pages máximo, pero solo con la opción de link de impulsa.click (no con dominio personalizado)</li>
                                        </ul>    
                                    </div>
                                    <div id="crece" className={{'display': crece ? 'block' : 'none'}}>
                                        <h3>Crece</h3>
                                        <ul>
                                            <li>Puede usar clientes ilimitados</li>
                                            <li>Puede usar contactos ilimitados</li>
                                            <li>Puede crear landing page ilimitados con dominio propio </li>
                                            <li>Puede enviar hasta X cantidad mensual, según se destine en la creación de la cuenta</li>
                                            <li>Agregar: Cantidad de mails mensuales (10.000 a 100.000, de 10 mil en 10 mil)</li>
                                        </ul>    
                                    </div>
                                    
                                </fieldset>
                                
                            </div>
                </div>
            </div>
        </form>
    )
}
