import {
    TRY_SAVE_LANDING,
    TRY_SAVE_LANDING_SUCCESS,
    TRY_SAVE_LANDING_ERROR,
    TRY_GET_LANDING,
    TRY_GET_LANDING_SUCCESS,
    TRY_GET_LANDING_ERROR,
    TRY_GETBYID_LANDING,
    TRY_GETBYID_SUCCESS,
    TRY_GETBYID_ERROR,

    TRY_UPDATE_LANDING,
    TRY_UPDATE_LANDING_SUCCESS,
    TRY_UPDATE_LANDING_ERROR,

    TRY_UPDATE_DRAW_LANDING,
    TRY_UPDATE_DRAW_SUCCESS,
    TRY_UPDATE_DRAW_ERROR,

    TRY_GET_LADINGSHOW_LANDING,
    TRY_GET_LADINGSHOW_SUCCESS,
    TRY_GET_LADINGSHOW_ERROR,

    TRY_ADDCOUNT_LANDING,
    TRY_ADDCOUNT_SUCCESS,
    TRY_ADDCOUNT_ERROR,

    TRY_ADDVISIT_LANDING,
    TRY_ADDVISIT_SUCCESS,
    TRY_ADDVISIT_ERROR,

    TRY_ADDCONVERTION_LANDING,
    TRY_ADDCONVERTION_SUCCESS,
    TRY_ADDCONVERTION_ERROR,

    TRY_SAVEDESIGN_LANDING,
    TRY_SAVEDESIGN_SUCCESS,
    TRY_SAVEDESIGN_ERROR,

    TRY_GETDESIGN_LANDING,
    TRY_GETDESIGN_SUCCESS,
    TRY_GETDESIGN_ERROR,
    TRY_SETDESIGN_LANDING,

    TRY_DELETEDESIGN_LANDING,
    TRY_DELETEDESIGN_SUCCESS,
    TRY_DELETEDESIGN_ERROR,

    DESIGN_CAT_LANDING,
    DESIGN_CAT_SUCCESS,
    DESIGN_CAT_ERROR,

    GET_FORMINFO,
    GET_FORMINFO_SUCCESS,
    GET_FORMINFO_ERROR,

    SEND_FORM_DATA,
    SEND_FORM_DATA_SUCCESS,
    SEND_FORM_DATA_ERROR,

    DUPLICATE,
    DUPLICATE_SUCCESS,
    DUPLICATE_ERROR,

    SETTING_CURRENT_LANDING,

    SEARCH_CUSTOM_PAGE,
    FINISH_CUSTOM_PAGE,

    SET_LANDING_VARIABLE

    }
from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function set_design( design ){

    return async ( dispatch )=> {
        dispatch( intentaset_design(design) );     
    }
}
const intentaset_design = (design) => ({
    type:TRY_SETDESIGN_LANDING,
    payload:design
})

export function set_current_landing_page( landing, form, account_info ){
    return async ( dispatch )=> {
        
        try {
      
            
            dispatch( setting_current_landing_page({landing:landing, form:form, account_info:account_info}) );

           
        } catch (error) {
           
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const setting_current_landing_page = (data) => ({
    type:SETTING_CURRENT_LANDING,
    payload:data
})

export function duplicate( landing_id ){
    return async ( dispatch )=> {
        dispatch( intentaduplicate() );
        try {
      
            let respuesta = await clienteAxios.post(`/lading_page/duplicate`, {landing_id});
           
            console.log(respuesta);
            dispatch( intentaduplicateSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentaduplicateError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaduplicate = () => ({
    type:DUPLICATE,
    payload:true
})

const intentaduplicateSuccess = ( data ) => ({
    type:DUPLICATE_SUCCESS,
    payload:data      
})

const intentaduplicateError = () => ({
    type:DUPLICATE_ERROR,
    payload:true      
})


export function sendFormData( data , data2){
    return async ( dispatch )=> {
        dispatch( intentasendFormData() );
        try {
      
            let respuesta = await clienteAxios.post(`/lading_page/sendFormData`, {data,data2});
           
            // console.log(respuesta);
            dispatch( intentasendFormDataSuccess(respuesta.data) )

           
        } catch (error) {
            console.log("error.response.data",error)

            

            if(error && error.response && error.response.data && error.response.data.message){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.response.data.message
                })
            }else{

                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error, intenta de nuevo'
                })
                dispatch( intentasendFormDataError() );
            }
        }        
    }          
}

const intentasendFormData = () => ({
    type:SEND_FORM_DATA,
    payload:true
})

const intentasendFormDataSuccess = ( data ) => ({
    type:SEND_FORM_DATA_SUCCESS,
    payload:data      
})

const intentasendFormDataError = () => ({
    type:SEND_FORM_DATA_ERROR,
    payload:true      
})


export function getFormInfo( data , nologin = null){
    return async ( dispatch )=> {
        dispatch( intentagetFormInfo() );
        try {
            let respuesta;
            if(nologin){
                respuesta = await clienteAxios.post(`/lading_page/getFormInfo_nologin`, data);
            }else{
                respuesta = await clienteAxios.post(`/lading_page/getFormInfo`, data);
            }
            
            // console.log(respuesta);
            dispatch( intentagetFormInfoSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentagetFormInfoError() );

            //alerta de error
            // Swal.fire({
            //     icon:'error',
            //     title:'Hubo un error',
            //     text:'Hubo un error, intenta de nuevo' 
            // })
        }        
    }          
}

const intentagetFormInfo = () => ({
    type:GET_FORMINFO,
    payload:true
})

const intentagetFormInfoSuccess = ( data ) => ({
    type:GET_FORMINFO_SUCCESS,
    payload:data      
})

const intentagetFormInfoError = () => ({
    type:GET_FORMINFO_ERROR,
    payload:true      
})

export function get_design_categories(){
    return async ( dispatch )=> {
        dispatch( intentaget_design_categories() );
        try {
            const respuesta = await clienteAxios.get(`/lading_page/get_design_categories`);
            // console.log(respuesta);
            dispatch( intentaget_design_categoriesSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentaget_design_categoriesError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaget_design_categories = () => ({
    type:DESIGN_CAT_LANDING,
    payload:true
})

const intentaget_design_categoriesSuccess = ( data ) => ({
    type:DESIGN_CAT_SUCCESS,
    payload:data      
})

const intentaget_design_categoriesError = () => ({
    type:DESIGN_CAT_ERROR,
    payload:true      
})



export function delete_design( id ){
    return async ( dispatch )=> {
        dispatch( intentadelete_design() );
        try {
            const respuesta = await clienteAxios.delete(`/lading_page/delete_designs/${id}`);
            console.log(respuesta);
            dispatch( intentadelete_designSuccess(respuesta.data) )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El diseño ha sido eliminado.'
            })
        } catch (error) {
            console.log(error)

            dispatch( intentadelete_designError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentadelete_design = () => ({
    type:TRY_DELETEDESIGN_LANDING,
    payload:true
})

const intentadelete_designSuccess = ( data  ) => ({
    type:TRY_DELETEDESIGN_SUCCESS,
    payload:data      
})

const intentadelete_designError = () => ({
    type:TRY_DELETEDESIGN_ERROR,
    payload:true      
})


export function get_designs(sinhtml){
    return async ( dispatch )=> {
        dispatch( intentaget_designs() );
        try {
            // console.log('sinhtml',sinhtml)
            const respuesta = await clienteAxios.get(`/lading_page/get_designs/${sinhtml ? '' : '?html=true'}`);
            // console.log(respuesta);
            dispatch( intentaget_designsSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentaget_designsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaget_designs = () => ({
    type:TRY_GETDESIGN_LANDING,
    payload:true
})

const intentaget_designsSuccess = ( data  ) => ({
    type:TRY_GETDESIGN_SUCCESS,
    payload:data      
})

const intentaget_designsError = () => ({
    type:TRY_GETDESIGN_ERROR,
    payload:true      
})
export function guardarDiseno(data, nombre, categoria,imagen = null, design = null){
    return async ( dispatch )=> {
        dispatch( intentaguardarDiseno() );
        try {
            const formData = new FormData();
            formData.append("imagen", imagen);
            formData.append("nombre", nombre);
            formData.append("categoria", categoria);
            formData.append("data", JSON.stringify(data));
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            // console.log('formData',formData)
            // const respuesta = await clienteAxios.post(`/users/image`,formData, config);
            const respuesta = await clienteAxios.post(`/lading_page/save_design${design ? '?design='+design.id : ''}`,formData,config);

            dispatch( intentaguardarDisenoSuccess(respuesta.data) )

            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Diseño guardado con éxito!'
            })
           
        } catch (error) {
            console.log(error)

            dispatch( intentaguardarDisenoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaguardarDiseno = () => ({
    type:TRY_SAVEDESIGN_LANDING,
    payload:true
})

const intentaguardarDisenoSuccess = (  ) => ({
    type:TRY_SAVEDESIGN_SUCCESS,
    payload:true      
})

const intentaguardarDisenoError = () => ({
    type:TRY_SAVEDESIGN_ERROR,
    payload:true      
})

export function addVisit(account_name, slug){
    return async ( dispatch )=> {
        dispatch( intentaaddVisit() );
        try {
            const respuesta = await clienteAxios.put(`/lading_page/addvisit`,{account_name, slug});

            dispatch( intentaaddVisitSuccess(respuesta.data) )
      
           
        } catch (error) {
            console.log(error)

            dispatch( intentaaddVisitError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaaddVisit = () => ({
    type:TRY_ADDVISIT_LANDING,
    payload:true
})

const intentaaddVisitSuccess = (  ) => ({
    type:TRY_ADDVISIT_SUCCESS,
    payload:true      
})

const intentaaddVisitError = () => ({
    type:TRY_ADDVISIT_ERROR,
    payload:true      
})

export function addConvertion(account_name, slug){
    return async ( dispatch )=> {
        dispatch( intentaaddConvertion() );
        try {
            const respuesta = await clienteAxios.put(`/lading_page/addconvertion`,{account_name, slug});

            dispatch( intentaaddConvertionSuccess(respuesta.data) )
            // Swal.fire({
            //     icon:'success',
            //     title:'Éxito',
            //     text:'Diseño actualizado'
            // })
           
        } catch (error) {
            console.log(error)

            dispatch( intentaaddConvertionError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaaddConvertion = () => ({
    type:TRY_ADDCONVERTION_LANDING,
    payload:true
})

const intentaaddConvertionSuccess = (  ) => ({
    type:TRY_ADDCONVERTION_SUCCESS,
    payload:true      
})

const intentaaddConvertionError = () => ({
    type:TRY_ADDCONVERTION_ERROR,
    payload:true      
})

export function addCount(account_name, slug){
    return async ( dispatch )=> {
        dispatch( intentaaddCount() );
        try {
            const respuesta = await clienteAxios.post(`/lading_page/addCount`,{account_name, slug});

            dispatch( intentaaddCountSuccess(respuesta.data) )
            // Swal.fire({
            //     icon:'success',
            //     title:'Éxito',
            //     text:'Diseño actualizado'
            // })
           
        } catch (error) {
            console.log(error)

            dispatch( intentaaddCountError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaaddCount = () => ({
    type:TRY_ADDCOUNT_LANDING,
    payload:true
})

const intentaaddCountSuccess = (  ) => ({
    type:TRY_ADDCOUNT_SUCCESS,
    payload:true      
})

const intentaaddCountError = () => ({
    type:TRY_ADDCOUNT_ERROR,
    payload:true      
})




export function obtenerLandingToShow(account_name, slug){
    return async ( dispatch )=> {
        dispatch( intentaobtenerLandingToShow() );
        try {
            const respuesta = await clienteAxios.get(`/lading_page/show/${account_name}/${slug}`);

            dispatch( intentaobtenerLandingToShowSuccess(respuesta.data) )
            // Swal.fire({
            //     icon:'success',
            //     title:'Éxito',
            //     text:'Diseño actualizado'
            // })
           
        } catch (error) {
            console.log(error)

            dispatch( intentaobtenerLandingToShowError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaobtenerLandingToShow = () => ({
    type:TRY_GET_LADINGSHOW_LANDING,
    payload:true
})

const intentaobtenerLandingToShowSuccess = ( show ) => ({
    type:TRY_GET_LADINGSHOW_SUCCESS,
    payload:show      
})

const intentaobtenerLandingToShowError = () => ({
    type:TRY_GET_LADINGSHOW_ERROR,
    payload:true      
})



export function actualizarDiseno(data,id){
    return async ( dispatch )=> {
        dispatch( intentaactualizarDiseno() );
        try {
            const respuesta = await clienteAxios.put(`/lading_page/draw/${id}`,data);

            dispatch( intentaactualizarDisenoSuccess(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Diseño actualizado'
            })
           
        } catch (error) {
            console.log(error)

            dispatch( intentaactualizarDisenoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaactualizarDiseno = () => ({
    type:TRY_UPDATE_DRAW_LANDING,
    payload:true
})

const intentaactualizarDisenoSuccess = (  ) => ({
    type:TRY_UPDATE_DRAW_SUCCESS,
    payload:true      
})

const intentaactualizarDisenoError = () => ({
    type:TRY_UPDATE_DRAW_ERROR,
    payload:true      
})


export function updatedLanding(landing){
    return async ( dispatch )=> {
        dispatch( intentageupdatedLanding() );
        try {
            const respuesta = await clienteAxios.put(`/lading_page/`,landing);

            dispatch( intentageupdatedLandingSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentageupdatedLandingError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentageupdatedLanding = () => ({
    type:TRY_UPDATE_LANDING,
    payload:true
})

const intentageupdatedLandingSuccess = ( landing ) => ({
    type:TRY_UPDATE_LANDING_SUCCESS,
    payload:landing      
})

const intentageupdatedLandingError = () => ({
    type:TRY_UPDATE_LANDING_ERROR,
    payload:true      
})


export function getLandingById(id){
    return async ( dispatch )=> {
        dispatch( intentagetLandingById() );
        try {
            const respuesta = await clienteAxios.get(`/lading_page/${id}`);

            dispatch( intentagetLandingByIdSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentagetLandingByIdError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentagetLandingById = () => ({
    type:TRY_GETBYID_LANDING,
    payload:true
})

const intentagetLandingByIdSuccess = ( landing ) => ({
    type:TRY_GETBYID_SUCCESS,
    payload:landing      
})

const intentagetLandingByIdError = () => ({
    type:TRY_GETBYID_ERROR,
    payload:true      
})


export function getLandings(params){
    return async ( dispatch )=> {
        dispatch( intentaObtenerLandings() );
        try {
            let respuesta
            if(params){
                respuesta = await clienteAxios.get(`/lading_page/${params}`);

            }else{

                respuesta = await clienteAxios.get(`/lading_page/`);
            }

            dispatch( intentaObtenerLandingsSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentaObtenerLandingsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaObtenerLandings = () => ({
    type:TRY_GET_LANDING,
    payload:true
})

const intentaObtenerLandingsSuccess = ( landings ) => ({
    type:TRY_GET_LANDING_SUCCESS,
    payload:landings      
})

const intentaObtenerLandingsError = () => ({
    type:TRY_GET_LANDING_ERROR,
    payload:true      
})

export function set_landing_variable_action(){
    return async ( dispatch )=> {
        await dispatch( set_landing_variable() )
        
    }
}

const set_landing_variable = () => ({
    type:SET_LANDING_VARIABLE,
    payload:true
})

export function guardarLanding(data){
    return async ( dispatch )=> {
        dispatch( intentaguardarLanding() );
        try {
            const respuesta = await clienteAxios.post(`/lading_page/`,data);

            await dispatch( intentaguardarLandingSuccess(respuesta.data) )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Se ha creado con éxito'
            })
            
           
        } catch (error) {
            console.log(error)

            
            if(error.response.data.message){
                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.response.data.message
                })
            }else{
                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error, intenta de nuevo'
                })
                dispatch( intentaguardarLandingError() );
            }
        }        
    }          
}

const intentaguardarLanding = () => ({
    type:TRY_SAVE_LANDING,
    payload:true
})

const intentaguardarLandingSuccess = ( data ) => ({
    type:TRY_SAVE_LANDING_SUCCESS,
    payload:data      
})

const intentaguardarLandingError = () => ({
    type:TRY_SAVE_LANDING_ERROR,
    payload:true      
})

export const buscandoCustomPage = () => ({
    type:SEARCH_CUSTOM_PAGE,
    payload:true      
})

export const finishCustomPage = () => ({
    type:FINISH_CUSTOM_PAGE,
    payload:true      
})