import React, {useState, useEffect} from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerPlantillasAction, obtenerPlantilla, setLayoutToEdit, borrarPlantilla } from '../../actions/plantillasActions';
import { setLayout } from '../../actions/listasActions';
import $ from 'jquery'

import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link, useParams, useHistory  } from 'react-router-dom'
import Swal from 'sweetalert2';

export const LayoutList = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [getPlantillas, setgetPlantillas] = useState(false);

    const plantillas = useSelector( state => state.plantillas.plantillas )
    const loading = useSelector( state => state.clientes.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    // console.log(loading)

    useEffect( ()=> {
        // consultar la api
        cargarPlantillas();
        if(getPlantillas){
            cargarPlantillas();
            setgetPlantillas(false);

        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[getPlantillas,search_custom_page]);


    const cargarPlantillas = () => dispatch(obtenerPlantillasAction());

    const handleDelete = (plantilla_id) => {
        Swal.fire({
            title: 'Estas Seguro?',
            text: "Estas seguro de borrarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borralo!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(borrarPlantilla(plantilla_id))
                await cargarPlantillas();
                // dispatch(borrarCliente(client_id))
                // Swal.fire(
                //     'Borrado!',
                //     'El cliente fue borrado.',
                //     'success'
                // )
                // setGetClientes(true);
            }
          })
    }

    const handleConfig = async (plantilla) => {
        await dispatch(obtenerPlantilla(plantilla.id));
        await dispatch(setLayout(plantilla));
        history.push(`/layout_editor/${plantilla.id}`);
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de Plantillas</span>
                <Link to="/layout_create" className="btn btn-primary float-right" ><span className="fas fa-plus "></span>   Crear Plantilla</Link>
                <Link to="/mail_marketing" class="float-right p-2 mr-4 colorsito">Volver</Link>

            </div>
                        
            {
                loading ? <LoaderSpinner/> : null
            }
            <table className="table">
                <thead>
                    <th>Nombre</th>
                    <th>Descripcion</th>
                    <th>Tipo</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        plantillas && plantillas.map( plantilla => (
                            <tr key={plantilla.id}>
                                <td>{plantilla.nombre}</td>
                                <td>{plantilla.descripcion}</td>
                                <td>{plantilla.tipo == 'html' ? 'Editor visual' : "texto"}</td>
                                <td>
                                    <div className="input-group">
                                        <Link to={`/layout_view/${plantilla.id}`} className="btn btn-primary btn-sm"><i className="nav-icon fas fa-eye"></i></Link>
                                        <span onClick={ () => handleDelete(plantilla.id) } className="btn btn-danger btn-sm"><i className="nav-icon fas fa-remove"></i></span>
                                        <Link to={`/layout_edit/${plantilla.id}`} className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit"></i></Link>
                                        <button  onClick={ () => handleConfig(plantilla) }  className="btn btn-default btn-sm"><i class="fas fa-cog"></i></button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
