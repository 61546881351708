import {
    BEGIN_NEW_LIST,
    NEW_LIST_SUCCESS,
    NEW_LIST_ERROR,

    BEGIN_GET_LIST,
    GET_LIST_SUCCESS,
    GET_LIST_ERROR,

    BEGIN_GET_ONE_LIST,
    GET_ONE_LIST_SUCCESS,
    GET_ONE_LIST_ERROR,

    TRY_REMOVE_LIST,
    TRY_REMOVE_LIST_SUCCESS,
    TRY_REMOVE_LIST_ERROR,

    TRY_UPDATE_LIST,
    TRY_UPDATE_LIST_SUCCESS,
    TRY_UPDATE_LIST_ERROR,

    TRY_SAVE_DRAW,
    TRY_SAVE_DRAW_SUCCESS,
    TRY_SAVE_DRAW_ERROR,

    TRY_GET_DRAW,
    TRY_GET_DRAW_SUCCESS,
    TRY_GET_DRAW_ERROR,

    TRY_SAVE_BASIC,
    TRY_SAVE_BASIC_SUCCESS,
    TRY_SAVE_BASIC_ERROR,

    TRY_GET_BASICS,
    TRY_GET_BASICS_SUCCESS,
    TRY_GET_BASICS_ERROR,

    TRY_SET_LAYOUT,

    TRY_DELETE_DRAW,
    TRY_DELETE_DRAW_SUCCESS,
    TRY_DELETE_DRAW_ERROR

}
from '../types';


// cada reducer tiene su propio state

const initialState = {
    listas: [],
    newlist:null,
    listedit:null,
    newdraw:null,
    error:null,
    loading:false,
    draw:null,
    draws:[],
    layoutname:null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEGIN_NEW_LIST:
            return {
                ...state,
                loading:true,
                newlist:null
            }
        case NEW_LIST_SUCCESS:
            return {
                ...state,
                newlist:action.payload,
                loading:false
            }
        case NEW_LIST_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false
            }
        case BEGIN_GET_LIST:
            return {
                ...state,
                loading:true,
                listas:[],
                listedit:null
            }
        case GET_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                listas:action.payload
            }
        case GET_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case BEGIN_GET_ONE_LIST:
            return {
                ...state,
                loading:true
            }
        case GET_ONE_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                listedit:action.payload
            }
        case GET_ONE_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

        case TRY_REMOVE_LIST:
            return {
                ...state,
                loading:true
            }
        case TRY_REMOVE_LIST_SUCCESS:
            return {
                ...state,
                loading:false

            }
        case TRY_REMOVE_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRY_UPDATE_LIST:
            return {
                ...state,
                loading:true,
                listedit:null

            }
        case TRY_UPDATE_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                listedit:action.payload

            }
        case TRY_UPDATE_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload

            }
        case TRY_SAVE_DRAW:
            return {
                ...state,
                loading:true,
                newdraw:null
            }
        case TRY_SAVE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                newdraw:action.payload
            }
        case TRY_SAVE_DRAW_ERROR:
            return {
                ...state,
                loading:false,
                newdraw:null,
                error:action.payload
            }
        case TRY_GET_DRAW:
            return {
                ...state,
                loading:true,
                draw:null
            }
        case TRY_GET_DRAW_SUCCESS:
            return {
                ...state,
                loading:false,
                draw:action.payload
            }
        case TRY_GET_DRAW_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                draw:null
            }
        case TRY_SAVE_BASIC:
            return {
                ...state,
                loading:true
            }
        case TRY_SAVE_BASIC_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case TRY_SAVE_BASIC_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRY_GET_BASICS:
            return {
                ...state,
                loading:true,
                layoutname:null,
                draws:[]
            }
        case TRY_GET_BASICS_SUCCESS:
            return {
                ...state,
                loading:false,
                draws:action.payload
            }
        case TRY_GET_BASICS_ERROR:
            return {
                ...state,
                loading:false
            }
        case TRY_SET_LAYOUT:
            return {
                ...state,
                layoutname:{
                    id:action.payload.id,
                    nombre:action.payload.nombre,
                    categoria:action.payload.categoria ? action.payload.categoria : null,
                    descripcion:action.payload.descripcion ? action.payload.descripcion : null,
                    contenido:action.payload.html ? action.payload.html : action.payload.contenido,
                    tipo:action.payload.tipo ? action.payload.tipo : 'html',
                }
            }
        case TRY_DELETE_DRAW:
            return {
                ...state,
                loading:true
            }
        case TRY_DELETE_DRAW_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case TRY_DELETE_DRAW_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        default:
            return state;
    }
}