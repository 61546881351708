import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap4-modal';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Swal from 'sweetalert2';
import $ from 'jquery'
import ReactTooltip from 'react-tooltip';

import { useSelector, useDispatch } from 'react-redux';
import { obtenerContactFieldsAction, obtenerClientsFieldsAction, obtenerModulesFieldsAction, actualizarClientsFieldsAction, actualizarContactosFieldsAction, actualizarModulesFieldsAction, actualizarEstadoModulesAction } from '../../actions/customFieldsActions';


export const CustomFieldsIndex = () => {

    const dispatch = useDispatch()
    const contactfields = useSelector( state => state.customFields.contactsfields )
    const clientsfields = useSelector( state => state.customFields.clientsfields )
    const loading = useSelector( state => state.customFields.loading )
    const userAuth = useSelector( state => state.auth.user )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    const modulesfields = useSelector( state => state.customFields?.modulesfields )

    const [ modalVisible, setModalVisible ] = useState(false);
    const [ modalVisible2, setModalVisible2 ] = useState(false);
    const [ editField, setEditField] = useState(false);
    const [ indexEdit, setIndexEdit] = useState(null);
    const [ obtenerRegistros, setObtenerRegistros ] = useState(false);
    const [ tipo, setTipo ] = useState('clients');
    const [ campo, setCampo ] = useState({
        name:"",
        custom_name:"",
        required:false,
        type:null
    })
    const [nuevo_campo, setNuevoCampo] = useState({
        model:null,
        name:"",
        type:"",
        options:[],
        required:false
    })

    useEffect(()=>{
        if(obtenerRegistros === false){

            dispatch(obtenerContactFieldsAction());
            dispatch(obtenerClientsFieldsAction());
            dispatch(obtenerModulesFieldsAction());
            dispatch(obtenerModulesFieldsAction());

            setObtenerRegistros(true);
        }

        if(editField === false){
            setNuevoCampo({
                model:tipo,
                name:"",
                type:"",
                options:[],
                required:false
            })
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
    },[editField,search_custom_page,modulesfields])

    const handleChange = (e) => {
        setCampo({
            ...campo,
            [e.target.name] : e.target.value
        })
    }
    const cerrarVentana2 = () => {
        setModalVisible2(false);

    }
    const cerrarVentana = () => {
        setModalVisible(false);
        setCampo({
            name:"",
            custom_name:"",
            required:false,
            type:null
        })
    }
    const guardarCampoSubmit2 = async () => {
        console.log(nuevo_campo)
        if(nuevo_campo.name.trim() === '' || nuevo_campo.type.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Todos los campos son obligatorios'
            });
            return;
        }
        if( (nuevo_campo.type === 'select' || nuevo_campo.type === 'multiple') &&  nuevo_campo.options.length < 2){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'En tipo de campo selección simple o múltiple es obligatorio agregar por lo menos dos opciones'
            });
            return;
        }

        
        
        if(nuevo_campo.model === 'clients'){
            let copyArray = clientsfields;
            copyArray.custom.push(nuevo_campo);

            await dispatch( actualizarClientsFieldsAction(copyArray) )
            await dispatch( obtenerClientsFieldsAction());
        }else{
            let copyArray = contactfields;
            copyArray.custom.push(nuevo_campo);

                
            await dispatch( actualizarContactosFieldsAction(copyArray) )
            await dispatch( obtenerContactFieldsAction() );
        }

        setNuevoCampo({
            model:null,
            name:"",
            type:"",
            options:[],
            required:false
        })
        setModalVisible2(false)
    }
    const guardarCampoSubmit = async () => {
        if(tipo === "clients"){
            let index = null;
            for (let i = 0; i < clientsfields.normal.length; i++) {
                if(clientsfields.normal[i].name === campo.name ){
                    index = i;
                    break;
                }
            }
            if(index !== null){
                let copiedArray = [];
                copiedArray = clientsfields;
                copiedArray.normal[index] = campo;
                await dispatch( actualizarClientsFieldsAction(copiedArray) )
                await dispatch( obtenerClientsFieldsAction());

            }
        }else{
            let index2 = null;
            for (let i = 0; i < contactfields.normal.length; i++) {
                if(contactfields.normal[i].name === campo.name ){
               
                    index2 = i;
                    break;
                }
            }
            if(index2 !== null){
                let copiedContactsArray = [];
                copiedContactsArray = contactfields;
                copiedContactsArray.normal[index2] = campo;
     
                await dispatch( actualizarContactosFieldsAction(copiedContactsArray) )
                await dispatch( obtenerContactFieldsAction() );

            }
        }
    }
    const handleEdit = ( name, type ) => {
        setModalVisible(true);
        // console.log('type',type)
        if(type === 'contacts'){
            setTipo('contacts');
            const field = contactfields.normal.filter( field => field.name === name );
            if(field){
                
                setCampo(field[0]);
            }
        }else{
            const field = clientsfields.normal.filter( field => field.name === name );
            if(field){
            setTipo('clients');

                console.log('field[0]',field[0])
                setCampo(field[0]);
            }
        }
        
      
    }
    const openNewFieldModal = (model) => {
       
        setTipo(model)
        setEditField(false)
        setNuevoCampo({
            model:null,
            name:"",
            type:"",
            options:[],
            required:false
        })
        setModalVisible2(true);
        
        setNuevoCampo({
            ...nuevo_campo,
            model
        })
    }
    const handleChecked = (e) => {
        setCampo({
            ...campo,
            required:e.target.checked
        })
    }
    const handleCheckedNuevoCampo = (e) => {
        setNuevoCampo({
            ...nuevo_campo,
            required:e.target.checked
        })
    }
    const handleChange2 = (e) => {
        setNuevoCampo({
            ...nuevo_campo,
            [e.target.name]:e.target.value
        })
    }

    const handleDelete = (model, fieldName) => {
        Swal.fire({
            title: 'Estas seguro?',
            text: "No podras revertir esta acción",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borralo!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                let indexToBeDeleted = null;
                if(model === 'contacts'){
                    let copyArray = contactfields;
                    for (let index = 0; index < copyArray.custom.length; index++) {
                        if(copyArray.custom[index].name === fieldName ){
                            indexToBeDeleted = index;
                        }
                    }
                    if(indexToBeDeleted !== null){
                        copyArray.custom.splice(indexToBeDeleted, 1);
                        await dispatch( actualizarContactosFieldsAction(copyArray) )
                        await dispatch( obtenerContactFieldsAction() );
                    }
                }else{
                    let copyArray = clientsfields;
                    for (let index = 0; index < copyArray.custom.length; index++) {
                        if(copyArray.custom[index].name === fieldName ){
                            indexToBeDeleted = index;
                        }
                    }
                    if(indexToBeDeleted !== null){
                        copyArray.custom.splice(indexToBeDeleted, 1);
                        await dispatch( actualizarClientsFieldsAction(copyArray) )
                        await dispatch( obtenerClientsFieldsAction());
                    }
                }
                
                Swal.fire(
                    'Borrado!',
                    'El campo ha sido borrado.',
                    'success'
                )
            }
          })
    }

    const handleEditCustom = ( model, fieldName) => {
        setModalVisible2(true)
        setEditField(true)
        if(model === 'contacts'){
            for (let index = 0; index < contactfields.custom.length; index++) {
                if(contactfields.custom[index].name === fieldName){
                    setNuevoCampo(contactfields.custom[index])
                    setIndexEdit(index);
                }
            }
        }else{
            for (let index = 0; index < clientsfields.custom.length; index++) {
                if(clientsfields.custom[index].name === fieldName){
                    setNuevoCampo(clientsfields.custom[index])
                    setIndexEdit(index);
                }
            }
           
        }
        
    }
    const actualizarCampoSubmit = async () => {
        if(nuevo_campo.model === 'contacts'){
            let copy = contactfields
            copy.custom[indexEdit] = nuevo_campo;
            await dispatch( actualizarContactosFieldsAction(copy) )
            await dispatch( obtenerContactFieldsAction() );

        }
        if(nuevo_campo.model === 'clients'){
            let copy = clientsfields
            copy.custom[indexEdit] = nuevo_campo;
            await dispatch( actualizarClientsFieldsAction(copy) )
            await dispatch( obtenerClientsFieldsAction());
        }
    }
    const setIsChecked = (estado, id) => {
        dispatch( actualizarEstadoModulesAction(estado, id) );
    }
    
   
    const actualizarCampoCheckSubmit = async ( id, estado ) => {
        
        let data = {
            'id': id, 
            'estado': estado
        };
        
        await dispatch( actualizarModulesFieldsAction(data) );
        
    }
    return (
        <div className="white_box QA_section card_height_100">
            <Modal visible={modalVisible} >
              <div className="modal-header">
                <h5 className="modal-title">Guardar Campo</h5>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Nombre</label>
                  <input type="text" name="name" disabled className="form-control-lg form-control" value={campo.name} onChange={handleChange}/>
                </div>
                <div className="form-group">
                  <label>Nombre Personalizado</label>
                  <input type="text" name="custom_name" className="form-control-lg form-control" value={campo.custom_name} onChange={handleChange}/>
                </div>
                <div className="form-group">
                  <label>
                    <input type="checkbox" name="requerido" onChange={ handleChecked } checked={ campo.required }/> Requerido
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                  Cerrar
                </button>
                <button type="button" onClick={ guardarCampoSubmit } className="btn btn-primary">
                  Guadar
                </button>
              </div>
            </Modal>
            <Modal visible={modalVisible2} >
              <div className="modal-header">
                <h5 className="modal-title">{ editField ? "Actualizar" : "Crear" } Campo</h5>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Nombre</label>
                  <input type="text" name="name"  className="form-control-lg form-control" value={nuevo_campo.name} onChange={handleChange2}/>
                </div>
                <div className="form-group">
                  <label>Tipo</label>
                  <select className="form-control-lg form-control" name="type"  value={nuevo_campo.type} onChange={handleChange2}>
                      <option value="">Seleccione</option>
                      <option value="text">Texto</option>
                      <option value="select">Selección simple</option>
                      <option value="multiple">Selección multiple</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    <input type="checkbox" name="requerido" onChange={ handleCheckedNuevoCampo } checked={ nuevo_campo.required }/> Requerido
                  </label>
                </div>
                {
                    nuevo_campo.type == 'select' ||  nuevo_campo.type == 'multiple'? 

                    <div className="form-group">
                    <label>Opciones</label>
                        <ReactTagInput 
                            tags={nuevo_campo.options} 
                            placeholder="Ingrese el valor y presione enter"
                            onChange={(newMails) => setNuevoCampo({ ...nuevo_campo, options:newMails})}
                        />
                       
                    </div>:
                    null
                }
                
              </div>
              <div className="modal-footer">
                <button type="button" onClick={ cerrarVentana2 } className="btn btn-default">
                  Cerrar
                </button>
                {
                    editField ? 
                    <button type="button" onClick={ actualizarCampoSubmit } className="btn btn-primary">
                    Actualizar
                    </button>
                    : <button type="button" onClick={ guardarCampoSubmit2 } className="btn btn-primary">
                        Guadar
                    </button>
                }
                
              </div>
            </Modal>
            <div className="p-4">
                <span className="mr-3 naranja ">Campos Personalizados</span>
                <Link to="/config" className="float-right p-2 mr-4">Volver</Link>

            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row" >
                <div className="col-md-12">
                    <Tabs defaultActiveKey="Clientes"  transition={false} id="noanim-tab-example">
                        <Tab eventKey="Clientes" title="Clientes" >
                            <div className="p-4">
                                <span className="mr-3 naranja ">Campos de Clientes</span>
                                <button className="btn btn-primary  float-right" onClick={()=>openNewFieldModal('clients')}>Agregar Campo</button>
                                <ul className="list-group mt-5">
                                    <li className="list-group-item">Nombre <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Nombre')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Apellido <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Apellido')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Razón Social <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Razón Social')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Email <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Email')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Teléfono <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Teléfono')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Web <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Web')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Comentario <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Comentario')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Tags <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Tags')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Origen <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Origen')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Estado <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Estado')}><i className="far fa-edit"></i></button></li>
                                    <li className="list-group-item">Visibilidad <button data-tip="Editar Campo" className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Visibilidad')}><i className="far fa-edit"></i></button></li>
                                    {
                                        clientsfields && clientsfields.custom && clientsfields.custom.length ? clientsfields.custom.map( field => (
                                            <li className="list-group-item"> <span style={{ fontSize: "10px"}} >(custom)</span> <i class="fas fa-angle-right"></i> { field.name } <button className="btn btn-danger btn-xs float-right" onClick={() => handleDelete('clients', field.name)}><i class="far fa-trash-alt"></i></button> <button className="btn btn-default btn-xs float-right" onClick={() => handleEditCustom('clients',field.name)}><i className="far fa-edit"></i></button> </li>
                                        )) : null
                                    }
                                    <ReactTooltip />

                                </ul>
                            </div>
                        </Tab>
                        {
                            (userAuth && userAuth.currentPlan && userAuth.currentPlan.plan == 'Crece') ?
                            <Tab eventKey="Contactos" title="Contactos" >
                                <div className="p-4">
                                    <span className="mr-3 naranja ">Campos de Contactos</span>
                                    <button className="btn btn-primary  float-right" onClick={()=>openNewFieldModal('contacts')}>Agregar Campo</button>
                                    <ul className="list-group mt-5">
                                        <li className="list-group-item">Nombre <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Nombre','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Apellido <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Apellido','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Email <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Email','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Teléfono <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Teléfono','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Cargo <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Cargo','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Tags <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Tags','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Origen <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Origen','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Estado <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Estado','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Visibilidad <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Visibilidad','contacts')}><i className="far fa-edit"></i></button></li>
                                        <li className="list-group-item">Cliente <button data-tip="Editar Campo"  className="btn btn-default btn-xs float-right" onClick={() => handleEdit('Cliente','contacts')}><i className="far fa-edit"></i></button></li>
                                        {
                                            contactfields && contactfields.custom && contactfields.custom.length ? contactfields.custom.map( field => (
                                                <li className="list-group-item"> <span style={{ fontSize: "10px"}} >(custom)</span> <i class="fas fa-angle-right"></i> { field.name } <button className="btn btn-danger btn-xs float-right" onClick={() => handleDelete('contacts',field.name)}><i class="far fa-trash-alt"></i></button> <button className="btn btn-default btn-xs float-right" onClick={() => handleEditCustom('contacts', field.name)}><i className="far fa-edit"></i></button> </li>
                                            )) : null
                                        }
                                    <ReactTooltip />

                                    </ul>
                                </div>
                            </Tab> : null
                        }

                        {
                        (userAuth && userAuth.currentPlan && userAuth.currentPlan.plan == 'Crece') ?
                            <Tab eventKey="ControlModulos" title="Control de modulos" >
                                <div className="p-4">
                                    
                                    <ul className="list-group mt-5" >
                                    { 
                                        modulesfields && modulesfields.length > 0 ? modulesfields.map( row =>
                                            {
                                           
                                                console.log('row',row)
                                                return (<li className="list-group-item" key={`key_yi_${row.id}`}>
                                                    <input type="checkbox"  name={`name_${row.id}`} value={row.estado} onChange={(e) => { setIsChecked(row.estado === 1 ? 0 : 1 ,row.id); } } checked={row.estado === 1 ? 1 : false} /> {row.nombre}
                                                </li>)
                                            }
                                            
                                        ) : null
                                    }
                                    <ReactTooltip />
                                    </ul>
                                    
                                </div>
                            </Tab> : null
                        }
                        
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
