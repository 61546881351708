import {
    TRY_SAVE_FORM,
    SAVE_FORM_SUCCESS,
    SAVE_FORM_ERROR
}
from '../types';
import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function save_form( datos, file ){
    return async ( dispatch ) => {
        dispatch( try_save_form() );
        try {
            const formData = new FormData();
            Object.keys(datos).map( key => {
                formData.append(key, datos[key]);
            })
            formData.append("file", file);
            console.log('file',file)

            // formData.append("fileName", fileName);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            console.log('formData',formData)
            
            const respuesta = await clienteAxios.post(`/forms`,formData, config);
            dispatch( save_form_success(respuesta.data) )

            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Formulario configurado con éxito.'
            })
        } catch (error) {
            console.log(error)
            dispatch( save_form_error() );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const try_save_form = () => ({
    type:TRY_SAVE_FORM,
    payload:true
})

const save_form_success = () => ({
    type:SAVE_FORM_SUCCESS,
    payload:true
})

const save_form_error = () => ({
    type:SAVE_FORM_ERROR,
    payload:true   
})