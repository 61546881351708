import React, { useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {crearListaAction} from '../../actions/listasActions';
import {obtenerContactosAction} from '../../actions/contactosActions';
import {obtenerClientesAction} from '../../actions/clientesActions';
import Swal from 'sweetalert2';
import $ from 'jquery'

import { useSelector, useDispatch } from 'react-redux';
import {Link,useHistory } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
export const CreateMailList = () => {
    const dispatch = useDispatch()
    let history = useHistory();
    const [ options, setOptions ] = useState([]);
    const [ selected, setSelected ] = useState(null);

    const [ contactoOrigen, setContactoOrigen ] = useState('clientes');

    const clientes = useSelector( state => state.clientes.clientes)
    const contactos = useSelector( state => state.contactos.contactos)
    const contactosLoading = useSelector( state => state.contactos.loading )
    const clientesLoading = useSelector( state => state.clientes.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const user = useSelector( state => state.auth.user )

    useEffect( async () => {
        if(contactoOrigen === 'clientes'){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions(clientes?.records?.map( cliente => ({ value : cliente.id, label : cliente.nombre } )))
        }else{
            await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions(contactos?.records?.map( contacto => ({ value : contacto.id, label : contacto.nombre } )))
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[search_custom_page,user])
    const getOptions = async (input) => {
        if(contactoOrigen === 'clientes'){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${input}`}))
            return clientes?.records.map( cliente => ({ value : cliente.id, label : cliente.nombre } ))
        }else{
            await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${input}`}))
            return contactos?.records?.map( contacto => ({ value : contacto.id, label : contacto.nombre } ))
        }
    }
    const [cliente,setCliente] = useState({
        tags:[],
        origen:[]
    });

    const [ lista, setList ] = useState({
        nombre:'',
        descripcion:'',
        tipo:'estatica',
        tags:[],
        origen:[],
        contactoCliente:"clientes",
        creado:user?.id,
        options:[]
    });

    const handleChange = (e) => {
        setList({
            ...lista,
            [e.target.name] : e.target.value
        })
    }


    const handleChecked = (e) => {
        console.log(e.target.value)
        setList({
            ...lista,
            tipo:e.target.value
        })
    }

    const handleCheckedContactosClientes = (e) => {
        console.log(e.target.value);
        setContactoOrigen(e.target.value)
        setList({
            ...lista,
            contactoCliente:e.target.value
        })
    }
    const handleChangeSelect = (e) => {
        setList({
            ...lista,
            options:e
        })
        setSelected(e)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(cliente);
        // console.log(lista);
        // console.log(contactoOrigen);
        if(lista.nombre.trim() === '' || lista.descripcion.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Los campos nombre y descripción son obligatorios'
            });
            return;
        }

        if(lista.tipo != 'estatica'){

            setList({
                ...lista,
                tags:[...cliente.tags],
                origen:[...cliente.origen],
                contactoCliente:contactoOrigen
            })
            
            
        }else{
            setList({
                ...lista,
                tags:[...cliente.tags],
                origen:[...cliente.origen],
                contactoCliente:contactoOrigen,
                options
            })
        }

        // console.log('lista',lista)
        // console.log('cliente',cliente)

        // return;
        dispatch(crearListaAction(lista));

        setList({
            nombre:'',
            descripcion:'',
            tipo:'estatica',
            tags:[],
            origen:[],
            contactoCliente:"",
            creado:user.id

        })

        setOptions([]);

        history.push('/maillist')


    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Crear lista</span>
                {
                    clientesLoading ? <LoaderSpinner/> : null
                }
                {
                    contactosLoading ? <LoaderSpinner/> : null
                }
                <form>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="form-group">
                                <labe>Nombre</labe>
                                <input type="text" value={lista.nombre} className="form-control-lg form-control" onChange={handleChange} name="nombre"/>
                            </div>
                            <div className="form-group">
                                <labe>Descripción</labe>
                                <textarea className="form-control-lg form-control" onChange={handleChange} value={lista.descripcion} name="descripcion"></textarea>
                            </div>
                            <div className="form-group">
                                <labe>Tipo de Lista</labe>
                                <div className="checkbox p-1 mt-4">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} checked={lista.tipo == 'dinamica'}  value="dinamica"/> Dinámica
                                    </label>
                                </div>
                                <div className="checkbox p-1">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} checked={lista.tipo != 'dinamica'}  value="estatica"/> Estática
                                    </label>
                                </div>
                            </div>
                            {
                                lista.tipo == 'dinamica' ? 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <ReactTagInput 
                                            tags={lista.tags} 
                                            placeholder="Ingrese el texto de los tags, y presione enter."
                                            onChange={(newTags) => setList({ ...lista, tags:newTags})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Origen</label>
                                        <ReactTagInput 
                                            tags={lista.origen} 
                                            placeholder="Ingrese el texto de los origenes, y presione enter."
                                            onChange={(newOrigen) => setList({ ...lista, origen:newOrigen})}
                                        />
                                    
                                    </div>
                                </fieldset>
                                : 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    {/* <select className="form-control-lg form-control" name="clientes_contactos"></select> */}
                                    <div>
                                    <AsyncSelect
                                        isMulti
                                        value={selected}
                                        onChange={handleChangeSelect}
                                        // options={options}
                                        loadOptions={getOptions}
                                    />
                                    </div>
                                </fieldset>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button className="btn btn-primary" onClick={ handleSubmit }>Crear Lista</button>
                            <Link className="ml-3 colorsito" to="/maillist">Volver</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
