import React,{ useEffect, useState} from 'react'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'

import { getLandingById, updatedLanding } from '../../actions/landingActions';
import { obtenerUsuarios } from '../../actions/usersActions';
import Swal from 'sweetalert2';
import $ from 'jquery'
import { BreadCrumbLanding } from '../../components/BreadCrumbLanding';


export const EditLandingPage = () => {
    let history = useHistory();

    const loading = useSelector( state => state.landings.loading )
    const landingEdit = useSelector( state => state.landings.landingEdit )
    const users = useSelector( state => state.user.users )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ busco, setBusco ] = useState(false);

    const dispatch = useDispatch()

    const { id } = useParams()

    const [ landing, setLanding ] = useState({
        nombre : '',
        tipo : '',
        url : '',
        estado:'',
        propietario : '',
    });
    useEffect( async () => {
        if(!busco){
            await dispatch(obtenerUsuarios());
            await dispatch(getLandingById(id));

            setBusco(true);

            
        }
        if(landingEdit){
            setLanding(landingEdit)

        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[landingEdit,search_custom_page])
    const handleChange = (e) => {
        setLanding({
            ...landing,
            [e.target.name] : e.target.value
        })
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(updatedLanding(landing));
        Swal.fire({
            icon:'success',
            title:'Éxito',
            text:'Registro actualizado!'
        })
        history.push('/landing_pages')

    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbLanding active={1}/>

                <span className="mr-3 naranja">Editar Landing Page</span>
                <Link to="/landing_pages" className="btn btn-link float-right" >  Volver</Link>

            </div>

      
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row pl-4">
                <div className="col-md-4">
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label>Nombre de landing page</label>
                            <input type="text" className="form-control-lg form-control" onChange={ handleChange } value={landing.nombre} name="nombre"/>
                        </div>
                        <div className="form-group">
                            <label>Tipo de URL</label>
                            <select onChange={ handleChange } className="form-control-lg form-control" value={landing.tipo} name="tipo">
                                <option value="">Seleccione</option>
                                <option value="sistema">Del sistema</option>
                                <option value="propia">Dominio Propio</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Estado</label>
                            <select onChange={ handleChange } className="form-control-lg form-control" value={landing.estado} name="estado">
                                <option value="">Seleccione</option>
                                <option value="activo">Activo</option>
                                <option value="inactivo">Inactivo</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Url del Landing</label>
                            <input type="text" className="form-control-lg form-control" onChange={ handleChange } value={landing.url} name="url"/>
                        </div>
                        <div className="form-group">
                            <label>Propietario</label>
                            <select onChange={ handleChange } className="form-control-lg form-control" value={landing?.propietario?.id} name="propietario">
                                <option value="">Seleccione</option>
                                {
                                    users && users.length && users.map( (user,index) => (
                                        
                                        <option key={index} value={user.id}>{user.email}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <input type="submit" value="Actualizar" className="btn btn-success"/>
                    </form>
                </div>
            </div>
        </div>
    )
}
