import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { crearPlantillaAction } from '../../actions/plantillasActions';
import Select from 'react-select';

export const LayoutCreate = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const loading = useSelector(state => state.plantillas.loading);
    const layoutEdit = useSelector(state => state.plantillas.layoutEdit);

    const [plantilla, setPlantilla] = useState({
        nombre: "",
        descripcion: "",
        tipo: "",
        image_preview: null  // Cambiado a null para manejar el archivo
    });

    var options = [
        { value: 'texto', label: 'Texto' },
        { value: 'html', label: 'Html' }
    ];

    const { nombre, descripcion, tipo, image_preview } = plantilla;

    const handleChange = (e) => {
        if (typeof e.target === 'undefined') {
            setPlantilla({
                ...plantilla,
                "tipo": e.value
            });
        } else if (e.target.name === "file") {
            // Aquí capturamos el archivo
            setPlantilla({
                ...plantilla,
                image_preview: e.target.files[0]  // Almacena el archivo como File
            });
        } else {
            setPlantilla({
                ...plantilla,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (nombre.trim() === "" || descripcion.trim() === "") {
            // Alerta de error
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: 'Los campos nombre y descripción son obligatorios'
            });
            return;
        }

        // Preparar los datos para enviar
        const formData = new FormData();
        formData.append('nombre', nombre);
        formData.append('descripcion', descripcion);
        formData.append('tipo', tipo);
        if (image_preview) {
            formData.append('image_preview', image_preview);  // Adjuntar el archivo de imagen
        }

        // Dispatch con el FormData
        await dispatch(crearPlantillaAction(formData));

        setPlantilla({
            nombre: "",
            descripcion: "",
            tipo: "",
            image_preview: ""
        });
    };

    useEffect(() => {
        if (layoutEdit) {
            history.push(`/layout_editor/${layoutEdit.id}`);
        }
    }, [layoutEdit]);

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Crear Plantilla</span>
            </div>
            {loading ? <LoaderSpinner /> : null}
            <form onSubmit={handleSubmit} className="col-md-4 ml-3">
                <div className="form-group">
                    <label>Nombre</label>
                    <input type="text" className="form-control-lg form-control" required name="nombre" value={nombre} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Descripción</label>
                    <textarea className="form-control-lg form-control" required name="descripcion" value={descripcion} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label>Tipo</label>
                    <Select
                        name="tipo"
                        defaultValue={plantilla.tipo}
                        options={options}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Imagen Preview</label>
                    <input type="file" className="form-control-lg form-control" name="file" onChange={handleChange} /> {/* Eliminar 'value' */}
                </div>
                <input type="submit" value="Crear Plantilla" className="btn btn-primary" />
                <Link to="/layoutlist" className="ml-4 colorsito">Volver</Link>
            </form>
        </div>
    );
};
