import React,{ useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link, useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { crearNuevoContacto } from '../../actions/contactosActions';
import { obtenerClientesAction } from '../../actions/clientesActions';
import { obtenerContactFieldsAction } from '../../actions/customFieldsActions';

import $ from 'jquery'


export const NewContact = () => {
    let history = useHistory();

    const dispatch = useDispatch()
    
    const loading = useSelector( state => state.contactos.loading )
    const error = useSelector( state => state.contactos.error )
    const newcontacto = useSelector( state => state.contactos.newcontacto )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const contactFields = useSelector( state => state.customFields.contactsfields.normal )
    const contactFieldsCustom = useSelector( state => state.customFields.contactsfields.custom )
    const clientes = useSelector( state => state.clientes.clientes )
    const user = useSelector( state => state.auth.user )

    useEffect( () => {
        dispatch(obtenerContactFieldsAction())
        dispatch(obtenerClientesAction({paginate:true}));
        if(error){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Ocurrio un error creando el contacto.'
            });
            return;
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[error,newcontacto,user,search_custom_page])
    console.log('user',user)
    const [contacto,setContacto] = useState({
        nombre:'',
        apellido:'',
        mail:[],
        telefono:[],
        cargo:'',
        creador:user?.id,
        estado:'activo',
        visibilidad:'admin',
        tags:[],
        cliente_id:null,
        origen:[],
        custom:{}
    });

    const handleChange = (e) => {
        setContacto({
            ...contacto,
            [e.target.name]:e.target.value
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
       
        // validar
        if(contactFields && contactFields.length && contactFields[0].required && contacto.nombre.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[0].custom_name ? contactFields[0].custom_name : contactFields[0].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[1].required && contacto.mail.length === 0){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[1].custom_name ? contactFields[1].custom_name : contactFields[1].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[2].required && contacto.telefono.length === 0){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[2].custom_name ? contactFields[2].custom_name : contactFields[2].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[3].required && contacto.cargo.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[3].custom_name ? contactFields[3].custom_name : contactFields[3].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[4].required && contacto.tags.length === 0){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[4].custom_name ? contactFields[4].custom_name : contactFields[4].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[5].required && contacto.origen.length === 0){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[5].custom_name ? contactFields[5].custom_name : contactFields[5].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[6].required && contacto.estado.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[6].custom_name ? contactFields[6].custom_name : contactFields[6].name} es requerido`
            });
            return;
        }
        if(contactFields && contactFields.length && contactFields[7].required && contacto.visibilidad.trim() === ''){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:`El campo ${contactFields[7].custom_name ? contactFields[7].custom_name : contactFields[7].name} es requerido`
            });
            return;
        }
        //  enviar
      
        await dispatch(crearNuevoContacto(contacto))

        history.push('/contacts')

    }

    
    const handleChangeCustom = (e) => {
        const daticos = e.target.name.replace(/\[|\]/gi, '').split('custom')[1];
        console.log('daticos',daticos)
        if(e.target.type == 'text'){

            setContacto({
                ...contacto,
                custom:{
                    ...contacto.custom,
                    [daticos]:e.target.value
                }
            });
        }else{

            console.log('option.selected',e.target.options.selected)
            const updatedOptions = [...e.target.options]
            .filter(option => option.selected)
            .map(x => x.value);
          console.log("updatedOptions", updatedOptions);
          setContacto({
              ...contacto,
              custom:{
                ...contacto.custom,
                  [daticos]:updatedOptions
            }
          });
        }
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Nuevo Contacto</span>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
                <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">

                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[0].custom_name) ? contactFields[0].custom_name : "Nombre"}</label>
                        <input type="text" value={contacto.nombre} onChange={handleChange} className="form-control-lg form-control" name="nombre"/>
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[1].custom_name) ? contactFields[1].custom_name : "Apellido"}</label>
                        <input type="text" value={contacto.apellido} onChange={handleChange} className="form-control-lg form-control" name="apellido"/>
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[2].custom_name) ? contactFields[2].custom_name : "Email"}</label>
                        <ReactTagInput 
                            tags={contacto.mail} 
                            placeholder="Ingrese el email, y presione enter."
                            onChange={(newMails) => setContacto({ ...contacto, mail:newMails})}
                        />
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[3].custom_name) ? contactFields[3].custom_name : "Telefono"}</label>
                        <ReactTagInput 
                            tags={contacto.telefono} 
                            placeholder="Ingrese el telefono, y presione enter."
                            onChange={(newtlfs) => setContacto({ ...contacto, telefono:newtlfs})}
                        />
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[4].custom_name) ? contactFields[4].custom_name : "Cargo"}</label>
                        <input type="text" value={contacto.cargo} onChange={handleChange} className="form-control-lg form-control" name="cargo"/>
                    </div>
                </div>
                <div className="col-md-6">
                    
                   
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[5].custom_name) ? contactFields[5].custom_name : "Tags"}</label>
                        <ReactTagInput 
                            tags={contacto.tags} 
                            placeholder="Ingrese el texto de los tags, y presione enter."
                            onChange={(newTags) => setContacto({ ...contacto, tags:newTags})}
                        />
                       
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[6].custom_name) ? contactFields[6].custom_name : "Origen"}</label>
                        <ReactTagInput 
                            tags={contacto.origen} 
                            placeholder="Ingrese el texto de los origenes, y presione enter."
                            onChange={(newOrigen) => setContacto({ ...contacto, origen:newOrigen})}
                        />
                      
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[9]?.custom_name) ? contactFields[9]?.custom_name : "Cliente"}</label>
                        <select value={contacto.cliente_id} required  className="form-control-lg form-control" onChange={handleChange} name="cliente_id">
                            <option value="">Seleccione</option>
                            {
                                clientes && clientes.records && clientes.records.length && clientes.records.map( cliente => (
                                    <option value={cliente.id}>{cliente.nombre}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group" style={{"display":"none"}}>
                        <label>{ (contactFields && contactFields.length && contactFields[7].custom_name) ? contactFields[7].custom_name : "Estado"}</label>
                        <select value={contacto.estado} required  className="form-control-lg form-control" onChange={handleChange} name="estado">
                            <option value="">Seleccione</option>
                            <option value="activo" selected>Activo</option>
                            <option value="bloqueado">Bloqueado</option>
                            <option value="papelera">Papelera</option>
                        </select>
                    </div>
                    <div className="form-group" style={{"display":"none"}}>
                        <label>{ (contactFields && contactFields.length && contactFields[8].custom_name) ? contactFields[8].custom_name : "Visibilidad"}</label>
                        <select value={contacto.visibilidad} required  className="form-control-lg form-control" onChange={handleChange} name="visibilidad">
                            <option value="">Seleccione</option>
                            <option value="admin" selected>Admin</option>
                        </select>
                    </div>
                    {
                        contactFieldsCustom && contactFieldsCustom.length ? contactFieldsCustom.map( input => (
                            <div className="form-group">
                                <label>{ input.name }</label>
                                {
                                    input.type !== 'text' ?
                                        input.type === 'select' ?
                                            <select required={ input.required ? true : false } options={input.options} value={contacto[input.name]}  className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom]["+input.name+"]"}>
                                                <option value="">Seleccione</option>
                                                {
                                                    input.options?.map( (option,i) => (
                                                        <option key={`__${i}`} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                        : 
                                        <select required={ input.required ? true : false } options={input.options} value={contacto[input.name]} multiple  className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom]["+input.name+"]"}>
                                                {
                                                    input.options?.map( (option,i) => (
                                                        <option key={`_${i}`} value={option}>{option}</option>
                                                    ))
                                                }
                                        </select>
                                    : <input type="text" onChange={handleChangeCustom}  className="form-control-lg form-control" name={"[custom]["+input.name+"]"} />
                                    
                                }
                                
                            </div>
                        )) : null
                    }
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <input type="submit" className="btn btn-success" value="Crear"/>
                        <Link to="/contacts" className="p-2 colorsito">Volver</Link>

                    </div>
                </div>
                </form>
        </div>
    )
}
