import React,{ useRef, useState, useEffect} from 'react'
import { LoaderSpinner } from '../../components/LoaderSpinner'
import { useSelector, useDispatch } from 'react-redux';
import EmailEditor from 'react-email-editor';
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { create_design } from '../../actions/campaignsActions';

export const NewDesignMailTemplate = () => {
    let history = useHistory();

    const emailEditorRef = useRef();

    const dispatch = useDispatch()
    const loading = useSelector( state => state.campaigns.loading );
    const design = useSelector( state => state.campaigns.design )
    const [tipo, setTipo] = useState('texto');
    const [nombre, setnombre] = useState('');
    const [categoria, setCategoria] = useState('');
    const [ imagen, setImagen ] = useState();
    useEffect(()=>{

    },[design])
    const exportHtml = async () => {
        
        emailEditorRef.current.editor.exportHtml( async (data) => {
            const {  html } = data;
            if(nombre && imagen){
                await dispatch(create_design({data, nombre, categoria, imagen, design}))
                history.push('/mailmarketing_template')
            }else{
                if(design){
                    await dispatch(create_design({data, nombre, categoria, imagen, design}))
                    history.push('/mailmarketing_template')
                }
                if(!nombre && !design){

                    Swal.fire({
                        icon:'error',
                        title:'Hubo un error',
                        text:'El nombre del diseño es obligatorio.'
                    })
                }
                if(!imagen && !design){
                    Swal.fire({
                        icon:'error',
                        title:'Hubo un error',
                        text:'La imagen del diseño es obligatoria.'
                    })
                }
            }
     
        });
    };
    const onLoad = () => {

        if(design && design.html && emailEditorRef){
            setTimeout (()=>{

                const html = JSON.parse(design.html);
                console.log('html',html)
                emailEditorRef.current?.editor?.loadDesign(html.design);
            }, 4000);
        }
    }
    const handleChange = (e) => {
        if(e.target.name == 'tipo'){
            setTipo(e.target.value)
        }
        
    }

    const handleChangePreview = (e) => {
        setImagen(e.target.files[0])
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div>
                <div className="p-4">
                    <span className="mr-3 naranja">{design ? 'Editar Plantilla' : 'Nueva Plantilla'} </span>
                </div>
                {
                    loading ? <LoaderSpinner/> : null
                }
                <div className="btn-group ml-4">
                    
                    <Link to="/mailmarketing_template" className="btn btn-link float-right" >  Volver</Link>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group p-4">
                            <label>Nombre</label>
                            <input type="text" className="form-control-lg form-control" name="nombre" onChange={ (e) => setnombre(e.target.value) } value={nombre} placeholder="Nombre"/>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group p-4">
                            <label>Categoria</label>
                            <input type="text" className="form-control-lg form-control" name="categoria" onChange={ (e) => setCategoria(e.target.value) } value={categoria} placeholder="Categoria"/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group p-4">
                            <label style={{display:'block'}}>Imagen</label>
                            <input type="file" name="preview" onChange={ handleChangePreview }/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 p-4">
                        <button  className="btn btn-success  " onClick={ () => exportHtml() } >{design ? 'Actualizar diseño' : 'Guardar diseño'} </button>
                    </div>
                </div>
                <EmailEditor
                    onLoad={onLoad}
                    ref={emailEditorRef}
                    minHeight={`1100px`}
                />
            </div>
        </div>
    )
}
