import React,{ useEffect, useState } from 'react'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom'
import ReactTagInput from "@pathofdev/react-tag-input";
import { obtenerListasAction } from "../../actions/listasActions";
import { getFormInfo, getLandings} from "../../actions/landingActions";

import { save_form } from "../../actions/formActions";
import $ from 'jquery'
import { BreadCrumbLanding } from '../../components/BreadCrumbLanding';

export const FormLanding = () => {
    const dispatch = useDispatch()
    let history = useHistory();

    const { id } = useParams()
    const loading = useSelector( state => state.landings.loading )
    const forminfo = useSelector( state => state.landings.forminfo )
    const landings = useSelector( state => state.landings.landings )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [placeholder,setplaceholder] = useState('');
    
    const listas = useSelector( state => state.listas.listas )
    const  [displayResponse, setdisplayResponse] = useState('block');
    const  [displayResponse2, setdisplayResponse2] = useState('none');
    const [file, setFile] = useState();
    const [busco, setbusco] = useState(false);
    const [form, setForm] = useState({
        landing_page_id:id,
        action:"",
        respuesta:"",
        client_list:null,
        tags:[],
        origen:[],
        receiver:[],
        ws:null,
        metatitle:null,
        googleAnalitics:null,
        facebookPixel:null,
        customScript:null,
        favicon:null
    })

    const { action } = form;

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('submit')
        await dispatch( save_form(form,file) );
        history.push(`/preview_landing/${id}`)

    }
    const handleChange = (e) => {
        if(e.target.name == 'action'){
            if(e.target.value == 'mensaje'){
                setplaceholder('Ingresa el mensaje aqui...');
                setdisplayResponse('block')
                setdisplayResponse2('none')
              
            }
            if(e.target.value == 'redireccionar_url'){
                setplaceholder('Ingresa aquí la Url...');
                setdisplayResponse('block')
                setdisplayResponse2('none')
              
            }
            if(e.target.value == 'redireccionar_landing'){
                setplaceholder('Escoge un landing page...');
                setdisplayResponse('none')
                setdisplayResponse2('block')
                
            }
            setForm({
                ...form,
                "respuesta":""
            })
            setForm({
                ...form,
                [e.target.name] : e.target.value
            })
        }else{

            setForm({
                ...form,
                [e.target.name] : e.target.value
            })
        }
    }
    const onFileChange = event => { 
        // Update the state 
        console.log(event.target.files[0] )
        // setForm({ 
        //     ...form,
        //     file: event.target.files[0] 
        // }); 
        setFile(event.target.files[0])
    }; 
    
    useEffect( async  () => {
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
        if(!busco){
            
            await dispatch(getLandings());
            await dispatch(obtenerListasAction());
            await dispatch(getFormInfo({id}));
            setbusco(true);
            
        }

        if(forminfo){
            setForm({
                landing_page_id:forminfo.landing_page_id,
                action:forminfo.action,
                respuesta:forminfo.action_response,
                client_list:forminfo.client_list,
                tags:forminfo.tags?.split(','),
                origen:forminfo.origen?.split(','),
                receiver:forminfo.emails?.split(','),
                ws:forminfo.ws,
                metatitle:forminfo.meta_title,
                googleAnalitics:forminfo.google_analitics,
                facebookPixel:forminfo.facebook_pixel,
                customScript:forminfo.custom_script,
                favicon:forminfo.favicon
                
            })
            if(forminfo.action == 'mensaje'){
                setplaceholder('Ingresa el mensaje aqui...');
                setdisplayResponse('block')
                setdisplayResponse2('none')
              
            }
            if(forminfo.action == 'redireccionar_url'){
                setplaceholder('Ingresa aquí la Url...');
                setdisplayResponse('block')
                setdisplayResponse2('none')
              
            }
            if(forminfo.action == 'redireccionar_landing'){
                setplaceholder('Escoge un landing page...');
                setdisplayResponse('none')
                setdisplayResponse2('block')
                
            }
           
        }
    },[forminfo,search_custom_page])

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbLanding active={3}/>

                <span className="mr-3 naranja">Configuración de formulario</span>
                <Link to="/landing_pages" className="btn btn-link float-right " > Volver</Link>

            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        
                        <div className="form-group">
                            <label>Acción al completar formulario</label>
                            <select className="form-control-lg form-control" name="action" value={form.action} onChange={handleChange}>
                                <option value="">Seleccione</option>
                                <option value="mensaje">Mostrar mensaje</option>
                                <option value="redireccionar_url">Redireccionar a url</option>
                                <option value="redireccionar_landing">Redireccionar a landing page</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>{placeholder}</label>
                            <input type="text" className="form-control-lg form-control" style={{display:displayResponse}} value={form.respuesta} placeholder={placeholder} disabled={action == "" ? true : false}  name="respuesta" value={form.respuesta} onChange={handleChange}/>
                            
                            <select className="form-control-lg form-control" name="respuesta" style={{display:displayResponse2}} value={form.respuesta} onChange={handleChange}>
                                <option value="">Seleccione</option>
                                {
                                    landings.length && landings.map( land => (
                                        land.estado == 'activo' && land.id != id && <option value={land.id}>{land.nombre}</option>
                                    ))
                                }
                            </select>
                            
                        </div>
                        <div className="form-group">
                            <label>Agregar tags</label>
                            <ReactTagInput 
                                tags={form.tags} 
                                placeholder="Ingrese el tag, y presione enter."
                                onChange={(tags) => setForm({ ...form, tags:tags})}
                            />
                        </div>
                        <div className="form-group">
                            <label>Agregar origen</label>
                            <ReactTagInput 
                                tags={form.origen} 
                                placeholder="Ingrese el origen, y presione enter."
                                onChange={(origenes) => setForm({ ...form, origen:origenes})}
                            />
                        </div>
                        <div className="form-group">
                            <label>Agregar cliente a lista</label>
                            <select className="form-control-lg form-control" name="client_list" value={form.client_list} onChange={handleChange}>
                                <option>Seleccione</option>
                                {
                                    (listas && listas.length) ? listas.map( lista => (
                                        lista.tipo == 'estatica' ?
                                        <option value={lista.id}>{lista.nombre}</option> : null
                                    )) : null
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Agregar mails que reciben</label>
                            <ReactTagInput 
                                tags={form.receiver} 
                                placeholder="Ingrese el email, y presione enter."
                                onChange={(receiver) => setForm({ ...form, receiver:receiver})}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                            <label>Número de Whatsapp</label>
                            <input name="ws" onChange={handleChange} value={form.ws} type="text" className="form-control-lg form-control"/>
                            
                        </div>
                        <div className="form-group">
                            <label>Meta title</label>
                            <textarea name="metatitle" onChange={handleChange} value={form.metatitle} className="form-control-lg form-control"></textarea>
                            <p className="text-muted">No use etiquetas. Ej: <code>{'<script></script>'}</code></p>
                        </div>
                        <div className="form-group">
                            <label>Google Analtyics / Tag Manager</label>
                            <textarea name="googleAnalitics" onChange={handleChange} value={form.googleAnalitics} className="form-control-lg form-control"></textarea>
                            <p className="text-muted">No use etiquetas. Ej: <code>{'<script></script>'}</code></p>
                        </div>
                        <div className="form-group">
                            <label>Facebook Pixel</label>
                            <textarea name="facebookPixel" onChange={handleChange} value={form.facebookPixel} className="form-control-lg form-control"></textarea>
                            <p className="text-muted">No use etiquetas. Ej: <code>{'<script></script>'}</code></p>
                        </div>
                        <div className="form-group">
                            <label>Script personalizado</label>
                            <textarea name="customScript" onChange={handleChange} value={form.customScript} className="form-control-lg form-control"></textarea>
                            <p className="text-muted">No use etiquetas. Ej: <code>{'<script></script>'}</code></p>
                        </div>
                        <div className="form-group">
                            <label className="d-block">Adjuntar favicon</label>
                            <input type="file" name="favicon" onChange={onFileChange} />
                        </div>
                    </div>
                </div>
                <input type="submit" value="Guardar" className="btn btn-success"/>
            </form>
        </div>
    )
}
