import { combineReducers } from 'redux';
import clientsReducer from './clientsReducer';
import contactsReducer from './contactsReducer';
import userReducer from './usersReducer';
import listasReducer from './listsReducer';
import layoutsReducer from './layoutsReducer';
import customFieldsReducer from './customFieldsReducer';
import campaignReducer from './campaignReducer';
import authReducer from './authReducer';
import landingReducer from './landingReducer';
import domainReducer from './domainReducer';

export default combineReducers({
    clientes: clientsReducer,
    contactos: contactsReducer,
    user:userReducer,
    listas:listasReducer,
    plantillas:layoutsReducer,
    customFields:customFieldsReducer,
    campaigns:campaignReducer,
    landings:landingReducer,
    domains:domainReducer,
    auth:authReducer
})