import React from 'react'
import { Link, useParams  } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './textEditor.css';

export const TextEditor = () => {
    const { idCampaign } = useParams()

    const guadar = () => {
        alert("ok")
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Editor de Texto</span>
            </div>
            <div className="btn-group ml-4 mb-5">
                <Link to={`/campaign_design/plaintext/basicDesign/${idCampaign}`} className="colorsito">Volver</Link>
            </div>
                <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Escribe Aqui</p>"
                  
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                <button className="btn btn-success mt-3" onClick={guadar}>Guardar</button>
        </div>
      )
}
