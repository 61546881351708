import React, { useEffect } from 'react'
import { Link, useParams, useHistory  } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { obtenerPlantillasDe } from '../../actions/plantillasActions';
import { setLayout } from '../../actions/listasActions';
import $ from 'jquery'

export const CampaignPlantext = () => {
    const { idCampaign } = useParams()
    const dispatch = useDispatch()
    const plantillas = useSelector( state => state.plantillas.plantillas )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    let historyHook = useHistory();
    const idTrozos = window.location.pathname.split('/');

    useEffect( async () => {
        await dispatch( obtenerPlantillasDe('texto') );
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[search_custom_page]);

    const handleClick = async (allObject) => {
        
        await dispatch(setLayout(allObject))
        // console.log('`/campaign_editor/${idTrozos[2]}`',`/campaign_editor/${idTrozos[2]}`)
        historyHook.push(`/campaign_editor/${idCampaign}/${idTrozos[2]}`);
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Texto plano</span>
            </div>
            <div className="btn-group ml-4">
                <Link to={`/campaign_design/${idCampaign}`} className="colorsito">Volver</Link>
            </div>
            <div className="row text-center mt-5">
            <div className="row mt-5">
                <div className="col-md-2">
                    <div  onClick={() => handleClick( "blanco" ) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey", height:"303px"}}>
                        <div className="theme-image text-center" >
                            <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                               
                                <div style={{"height": "210,66px","width":"241,27px"}}></div>

                            </div>
                        </div>
                        <div className="panel-footer text-center">
                            <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>En Blanco</p>

                        </div>
                    </div>
                </div>
                {
                    plantillas && plantillas.length && plantillas.map( plantilla => (
                        plantilla.contenido ? <div className="col-md-2" key={plantilla.id}>
                            <div  onClick={ () => handleClick(plantilla) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey", height:"303px"}}>
                                <div className="theme-image text-center" >
                                    <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                                    
                                        <div style={{"height": "210,66px","width":"241,27px"}}></div>

                                    </div>
                                </div>
                                <div className="panel-footer text-center">
                                    <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>{plantilla.nombre}</p>

                                </div>
                            </div>
                        </div> : null
                    ))
                }
            </div>
        </div>
        </div>
        
    )
}

