import {
    TRYING_GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,

    TRYING_GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,

    TRYING_CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_ERROR,

    TRYING_UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,

    TRYING_DELETE_ACCOUNT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_ERROR,

    TRYING_GET_DATABASES,
    GET_DATABASES_SUCCESS,
    GET_DATABASES_ERROR,

    TRYING_UPDATE_IMAGE,
    UPDATE_IMAGE_SUCCESS,
    UPDATE_IMAGE_ERROR,

    TRYING_GET_IMAGE,
    GET_IMAGE_SUCCESS,
    GET_IMAGE_ERROR,

    TRYING_GET_MEMBERSHIP,
    GET_MEMBERSHIP_SUCCESS,
    GET_MEMBERSHIP_ERROR,

    TRYING_SET_MEMBERSHIP,
    SET_MEMBERSHIP_SUCCESS,
    SET_MEMBERSHIP_ERROR,


    RESET_USER_CREATED
    }
from '../types';


const initialState = {
    user: null,
    users: [],
    error:null,
    loading:false,
    databases:null,
    image:null,
    membership:null,
    membershipLoading:false,
    membershipError:null,
    successUserCreated:null
}

export default function (state = initialState, action){
    switch(action.type){
        case TRYING_GET_USER:
            return {
                ...state,
                loading:true,
                user:null
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading:false,
                user:action.payload
            }
        case GET_USER_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

        case TRYING_GET_USERS:
            return {
                ...state,
                loading:true,
                user:null,
                successUserCreated:null
            }
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading:false,
                users:action.payload
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case RESET_USER_CREATED:
            return {
                ...state,
                successUserCreated:null
            }
        case TRYING_CREATE_ACCOUNT:
            return {
                ...state,
                loading:true,
                successUserCreated:null
            }
        case CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading:false,
                successUserCreated:true
            }
            
        case CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                loading:false,
                successUserCreated:false,
                error:action.payload
            }
        case TRYING_UPDATE_USER:
            return {
                ...state,
                loading:true,
                successUserCreated:false
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading:false,
                successUserCreated:true
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRYING_DELETE_ACCOUNT:
            return {
                ...state,
                loading:true
            }
        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case DELETE_ACCOUNT_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

        case TRYING_GET_DATABASES:
            return {
                ...state,
                databases:null
            }
        case GET_DATABASES_SUCCESS:
            return {
                ...state,
                databases:action.payload
            }
        case GET_DATABASES_ERROR:
            return {
                ...state,
                databases:null,
                error:action.payload
            }
        case TRYING_UPDATE_IMAGE:
            return {
                ...state,
                loading:true
            }
        case UPDATE_IMAGE_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case UPDATE_IMAGE_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRYING_GET_IMAGE:
            return {
                ...state,
                image:null
            }
        case GET_IMAGE_SUCCESS:
            return {
                ...state,
                image:action.payload
            }
        case GET_IMAGE_ERROR:
            return {
                ...state,
                image:null,
                error:action.payload
            }
        case TRYING_GET_MEMBERSHIP:
            return {
                ...state,
                membershipLoading:true
            }
        case GET_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                membership:action.payload,
                membershipError:null,
                membershipLoading:false
            }
        case GET_MEMBERSHIP_ERROR:
            return {
                ...state,
                membershipError:action.payload,
                membershipLoading:false
            }
        case TRYING_SET_MEMBERSHIP:
            return {
                ...state,
                membershipLoading:true

            }
        case SET_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                membershipLoading:false
            }
        case SET_MEMBERSHIP_ERROR:
            return {
                ...state,
                membershipLoading:false,
                membershipError:action.payload,
            }
        default:
            return state;
    }
}