import React, { useEffect, useState } from 'react'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getLandings, duplicate } from '../../actions/landingActions';

import moment from 'moment';

import $ from 'jquery'
// import language from './spanish.json' 
// import { DataGrid } from '@mui/DataGrid';

import ReactTooltip from 'react-tooltip';


export const LandingPages = () => {
    const dispatch = useDispatch()

    const loading = useSelector( state => state.landings.loading )
    let landings = useSelector( state => state.landings.landings )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    let auth = useSelector( state => state.auth.user )

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [busco, setBusco] = useState(false);
    const [landingsp, setLandingP] = useState([])
    
    const duplicateLanding = async (landing_id ) => {
      await dispatch(duplicate(landing_id))
      await dispatch(getLandings(`?paginate=true&page=0&size=5`));
    }


    useEffect(async()=>{
        if(busco == false){
            await dispatch(getLandings(`?paginate=true&page=0&size=5`));
            setBusco(true);
         
        }
        
      if(search_custom_page == null){
        $('#loading').hide();
      }

    },[busco,search_custom_page])

    const handleChangePage = async (page) => {
      await dispatch(getLandings(`?paginate=true&page=${page}&size=5`))
    }
    
  
   
    return (
      <div className="white_box QA_section card_height_100">
      <div className="p-4">
          <span className="mr-3 naranja">Lista de Landing Pages</span>
          <Link to="/new_landing_page" className="btn btn-primary float-right " ><span className="fas fa-plus "></span>   Crear landing page</Link>
          {
            auth?.roles[0]?.name == 'admin' || auth?.roles[0]?.name == 'impulsa' ? <Link to="/design_list" className="btn btn-primary float-right mr-2" ><span className="fas fa-plus "></span>   Lista diseños</Link> : null
          }
      </div>
      {
          loading ? <LoaderSpinner/> : null
      }
      <table className="table">
          <thead>
            <th >Nombre</th>
            <th width="150px">Estado</th>
            <th>Propietario</th>
            <th>Creado</th>
            <th>Visitas</th>
            <th>Conversiones</th>
            <th>% de Conversiones</th>
            <th></th>
          </thead>
          <tbody>
            {landings && landings.records?.length && landings.records.map((row) => (
            <tr key={row.name}>
              <td > {row.nombre}</td>
              <td width="150px">{row.estado == 'activo' ? <span className="badge bg-success">activo</span> : <span className="badge bg-danger">inactivo</span>}</td>
              <td>{row.propietario?.nombre}</td>
              <td>{moment(row.createdAt).fromNow()}</td>
              <td>{row.visitas ? row.visitas : 0}</td>
              <td>{row.conversiones ? row.conversiones : 0}</td>
              <td>{(row.conversiones && row.visitas) ? ((row.conversiones * 100 / row.visitas).toFixed(2) +'%') : '0%'}</td>
              <td>
                <Link className=" btn redondo btn-sm" data-tip="Configurar formulario" to={`/form_landing/${row.id}`}><i className="fab fa-wpforms"></i></Link>
              
                <Link className=" btn redondo btn-sm" data-tip="Crear diseño" to={`/draw_landing/${row.id}`}><i className="fas fa-pencil-ruler"></i></Link>
                <Link className=" btn redondo btn-sm" data-tip="Actualizar Datos" to={`/edit_landing/${row.id}`}><i className="far fa-edit"></i></Link>
                {
                  row.html && auth ?
                  <>
                  <Link className=" btn redondo btn-sm" data-tip="Resumen"  to={`/preview_landing/${row.id}`}><i className="fas fa-list"></i></Link> 
                  <Link className=" btn redondo btn-sm" data-tip="Entrar al landing"  target="_blank" to={`/p/${auth?.account_name}/${row.url}`}><i className="fas fa-eye"></i></Link> 
                  </>
                  : null
                }

                <button className="btn redondo btn-sm" style={{border:"none", background:"none"}} data-tip="Duplicar" onClick={() => duplicateLanding(row.id)}><i class="fas fa-clone"></i></button>
                <ReactTooltip />
              </td>
            </tr>
            ))}
          </tbody>
      </table>
      <div className="text-center">
                  <nav aria-label="Page navigation example">
                      <ul className="pagination">
                          <div className="page-item mr-10">
                              Pág {landings.currentPage + 1} -

                              {landings.totalItems} Registros 
                          </div>
                          <li className="page-item"><a className="page-link" onClick={ ()=> handleChangePage( landings.currentPage>0 ? landings.currentPage -1 : 0)}>Anterior</a></li>
                          {
                              [...Array(landings.totalPages).keys()].map( (page,index) => {
                                  if(index < 15){

                                      if(page  == landings.currentPage ){
                                          return <li className="page-item active">
                                          <a className="page-link" onClick={ ()=> handleChangePage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                          </li>
                                      }else{
                                          return <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(page)} >{page + 1}</a></li>
                                      }
                                  }
                              })
                          }
                          {
                              landings.totalPages > 15 ? 
                              <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(landings.totalPages-1)}>{landings.totalPages-1}</a></li> : null
                          }
                          <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(landings.currentPage < landings.totalPages -1 ? landings.currentPage + 1 : landings.totalPages -1)}>Siguiente</a></li>
                      </ul>
                  </nav>
              </div>
      </div>
    )
}
