import React,{ useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { get_designs, set_design, delete_design } from '../../actions/landingActions';

import { useSelector, useDispatch } from 'react-redux';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import clienteAxios from '../../config/axios';

export const DesignList = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [busco, setBusco] = useState(false);

    const loading = useSelector( state => state.landings.loading )
    const designs = useSelector( state => state.landings.designs )

    useEffect( async ()=>{
        if(!busco){
            await dispatch(get_designs('sinhtml'));
            setBusco(true)
        }
    },[busco])

    const handleClick = async (design) => {
        const designQuery = await clienteAxios.get(`/lading_page/get_designs?gethtml=${design.id}`);

        dispatch(set_design(designQuery.data));
        history.replace('/new_design')
    }

    const handleDelete = async ( e , id ) => {
        e.preventDefault();
        await dispatch(delete_design(id));
        setBusco(false)
        console.log(id)
    }

    const get_image = async (id) => {
        const imagen = await clienteAxios.get(`${process.env.REACT_APP_BACKEND}/lading_page/get_image_template/${id}`);
        console.log('id',id)
        console.log('imagen',imagen.data)
        return imagen;
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de diseños</span>
                <Link to="/new_design" className="btn btn-primary float-right " > Crear Diseño</Link>
                <Link to="/landing_pages" className="btn btn-link float-right " > Volver</Link>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row mt-5">
               
                {
                    designs && designs.length ? designs.map( design => (
                        <div className="col-md-3">
                            
                            <div class="card" style={{"width": "18rem;"}} key={design.id} onClick={ () => handleClick(design) }>
                                <img class="card-img-top" src={`${process.env.REACT_APP_BACKEND}/lading_page/get_image_template/${design.id}`} alt="User "/>
                          
                                <div class="card-body">
                                    <h5 class="card-title">{design.categoria}</h5>
                                    <p class="card-text">{design.nombre}</p>
                                    <button onClick={ (e) => handleDelete(e,design.id) } class="btn btn-primary">Eliminar</button>
                                </div>
                            </div>
                           
                         </div>
                    )) : <span>Cargando diseños disponibles... ...</span>
                }
            </div>
        </div>
    )
}
