import React, { useEffect, useState, useMemo } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'


import { obtenerListasAction } from '../../actions/listasActions';
import { agregarElementosAlista, getElementosAlista } from '../../actions/campaignsActions';

// redux
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery'
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';

export const CampaignList = () => {
    const dispatch = useDispatch()
    let history = useHistory();

    const { id } = useParams()
    const listas = useSelector( state => state.listas.listas )
    const campaignList = useSelector( state => state.campaigns.campaignList )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [listId, setListId ] = useState([]);
    const [ busco, setBusco] = useState(false);

    useEffect(async ()=>{
        if(!busco){
            await dispatch(obtenerListasAction());
            await dispatch(getElementosAlista(id));
            
            setBusco(true);
        }

        if(campaignList){
            setListId(campaignList.list_id)
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    //cambie esto por busco y funciona mejor. Tenia campaignList
    },[busco,search_custom_page])

    const handleClick = (e,id) => {
        // console.log('checked',e.target.checked)
        // console.log('id',id)
        if(!e.target.checked){
            let list = listId.filter( iteml => id != iteml)
            console.log('list delete',list)
            setListId(list)
        }else{
            let list = listId;
            list.push(id)
            console.log('new list ',list)
            setListId([...list])
        }
    }
    const actualizarInfo = async () => {
        // console.log("idCampaign",id)
        await dispatch(agregarElementosAlista(id,listId))

        history.push(`/campaign_design/${id}`)


    }
    const handleChecked = (id) => {
        const find = listId.filter( l => id == l)
        if(find.length){
            return true;
        }
        return false;
    }
    const handleChange = () => {
        console.log('handleChange')
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbCampaign active={2}/>

                <span className="mr-3 naranja">Listas</span>
                <Link to="/mail_marketing" className="float-right p-2 mr-4 colorsito">Volver</Link>
            </div>
            <div className="row p-5">
              <div className="col-md-12">
                <table className="table table-bordered">
                    <thead>
                        <th></th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Tipo</th>
                        <th>Creado</th>
                        <th width="200px" className="text-center">Correos Electronicos</th>
                    </thead>
                    <tbody>
                    {
                        listas && listas.map( lista => (
                            <tr key={lista.id}>
                                <td className="text-center" width="60px"> <input type="checkbox" onChange={handleChange} checked={ handleChecked(lista.id) } onClick={ (e) => handleClick(e,lista.id) } /></td>
                                <td>{lista.nombre}</td>
                                <td>{lista.descripcion}</td>
                                <td>{lista.contactoCliente}</td>
                                <td>{lista.creado && JSON.parse(lista.creado)?.nombre}</td>
                                <td width="200px" className="text-center">{lista.tipo == 'dinamica' ? 'dinamica' : lista.options.length}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
              </div>
            </div>
                <button className="btn btn-success ml-5"  onClick={ () => actualizarInfo() }>Guardar Cambios</button>
        </div>
    )
}
