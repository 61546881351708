/* global $ */
import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    OBTENER_USUARIO,
    SET_CURRENT_MENU,
    SET_HOME_DATA
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';


export function setCurrentMenu(menu){
    return async (dispatch) => {
        dispatch(actionSetCurrentMenu(menu));
    }
}
const actionSetCurrentMenu = (menu) => ({
    type:SET_CURRENT_MENU,
    payload:menu
})


export function set_session_with_params(params) {

    return async (dispatch) => {
        try {
            dispatch(obtenerSesion(params));
           
        } catch (error) {

            console.log(error);
            dispatch(logout());
        }
        
    }
}
export function verificarSesion(){
    return async (dispatch) => {
        try {
            const result = await clienteAxios.post('/users/me');
            // console.log('result verificarSesion',result.data)
            dispatch(obtenerSesion(result.data));
           
        } catch (error) {

            console.log(error);
            dispatch(logout());
        }
        
    }
}
const obtenerSesion = (usuario) => ({
    type:OBTENER_USUARIO,
    payload:usuario
})


export function login(email, password, setUserID) {
    return async (dispatch) => {
        dispatch(tryLogin());
        try {
            const respuesta = await clienteAxios.post(`/auth/signin`, { email, password });
            dispatch(loginSuccess(respuesta.data));

            if (respuesta.data.user.twoFactorSecret !== null) {
                clienteAxios.defaults.headers['x-access'] = respuesta.data.token;
                setUserID(respuesta.data.user.id); // Aquí se establece el userID en el estado del componente
                $('#modal2FACode').modal('show');
            } else {
                clienteAxios.defaults.headers['x-access'] = respuesta.data.token;
                window.location.href = '/home';
            }
        } catch (error) {
            console.log(error.response.data);
            dispatch(loginError(error.message));
            // Alerta de error
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: error.response.data.message,
            });
        }
    };
}


const tryLogin = () => ({
    type:LOGIN,
    payload:true
})


const loginSuccess = ( userData ) => ({
    type:LOGIN_SUCCESS,
    payload:userData
})

const loginError = () => ({
    type:LOGIN_ERROR,
    payload:true
})

export function logout(history){
    return async ( dispatch ) => {
        dispatch( tryLogout() );
        try {
            // const respuesta = await clienteAxios.post(`/auth/signin`, {email,password});
            // console.log('respuesta',respuesta.data)
            dispatch( logoutSuccess() )
            delete clienteAxios.defaults.headers['x-access']
            history.push("/login");
        } catch (error) {
            console.log(error)

            dispatch( logoutError(error.message) );

            //alerta de error
            // Swal.fire({
            //     icon:'error',
            //     title:'Hubo un error 1',
            //     text:'Hubo un error, intenta de nuevo'
            // })
        }
    }   
}  

const tryLogout = () => ({
    type:LOGOUT,
    payload:true
})


const logoutSuccess = (  ) => ({
    type:LOGOUT_SUCCESS,
    payload:true
})

const logoutError = () => ({
    type:LOGOUT_ERROR,
    payload:true
})


export function setHomeDataAction(data){
    return async ( dispatch ) => {
        dispatch(setHomeData(data));
    }   
}  

const setHomeData = (data) => ({
    type:SET_HOME_DATA,
    payload:data
})

export function validarCodigo2FA(userID, codigo2FA) {
    return async (dispatch) => {
        dispatch(intentandovalidarCodigo2FA());

        try {
            // Hacemos la solicitud POST, enviando el código 2FA en el body
            const response = await clienteAxios.post('/auth/validar-2fa/', { userID, codigo2FA });
            const data = response.data;

            if (response.status === 200) {
                dispatch(successvalidarCodigo2FA(data));
                window.location.href = '/home';
            } else {
                const errorMessage = data.message || 'Error al validar 2FA.';
                console.error('Error al validar 2FA:', errorMessage);
                throw new Error(errorMessage);
            }

        } catch (error) {
            console.error('Request failed:', error);
            dispatch(errorvalidarCodigo2FA(error.message));

            // Mostrar alerta de error
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al validar 2FA. Inténtalo de nuevo.'
            });
        }
    };
}

const intentandovalidarCodigo2FA = () => ({
    type: 'TRYING_GENERATE_2FA',
    payload: true
});

const successvalidarCodigo2FA = (data) => ({
    type: 'SUCCESS_DESACTIVAR_2FA',
    payload: {
        estado: data.estado
    }
});

const errorvalidarCodigo2FA = (error) => ({
    type: 'ERROR_DESACTIVAR_2FA',
    payload: error
});