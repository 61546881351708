// import logo from './logo.svg';
// import './App.css';
import { AppRouter } from './Router/AppRouter';
import { GoogleReCaptchaProvider , GoogleReCaptcha } from 'react-google-recaptcha-v3';

//redux
import { Provider} from 'react-redux';
import store from './store'

import tokenAuth from './config/token';
import $ from 'jquery'
//revisar si tenemos un token
const token = localStorage.getItem('app_marketing');
if(token){
    // console.log('token',token)
    
    tokenAuth(token);
}



function App() {
  
  return (
    <>
     
      {/* <link rel="stylesheet" href="/css/adminlte.css" ></link>
      <link rel="stylesheet" href="/css/styles.css" ></link> */}
      <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"></link>
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" />

    <div className="App">
      <Provider store={store}>
        <AppRouter/>
      </Provider>
    </div>

    </>
  );
}

export default App;
