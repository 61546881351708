import React from 'react'
import {
    Link
  } from "react-router-dom";
import { NavUser } from './NavUser';
import { TopStatistics } from './TopStatistics';
export const TopNav = () => {

    const isHome = () => {
        return window.location.href.search('home') !== -1 ? true : false
    }

    return (
        <>
            <div className="container-fluid no-gutters">
                <div className="row">
                    <div className="col-lg-12 p-0 ">
                        <div className="header_iner" style={{ position: isHome() ? "relative" : "abosolute"}}>
                            {
                                isHome() ? <TopStatistics/> : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
       
    )
}
