import React, { useState } from 'react'
import { LoaderSpinner } from '../components/LoaderSpinner';
import clienteAxios from '../config/axios'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';


export const ResetPassword = () => {

    const [ email, setEmail ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email){
            setLoading(true);
            const respuesta = await clienteAxios.post('/users/reset_password',{email});
            setLoading(false);
            if(respuesta.data.success){
                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:respuesta.data.message
                })
            }
        }
    }

    const handleOnchange = ( e ) => {
        setEmail(e.target.value)
    }

    return (
        <div id="loginWrapper">
            <div id="login">
                    
            
            <div className="container">
                <div id="login-row" className="row justify-content-center align-items-center ">
                    <div id="login-column" className="col-md-6">
                        <div id="login-box" className="col-md-12 reset_password">
                        <div className="text-center mt-4">
                                <img src="/Logos-impulsa-4.png" className="mt-4"/>
                            </div>
                            <form id="login-form" className="form" action="" method="post" onSubmit={ handleSubmit }>
                                <div className='form-group'>
                                    {
                                        loading ? <LoaderSpinner/> : null
                                    }
                                    <label>Ingrese el correo electr&oacute;nico</label>
                                    <input type="text" className='form-control-lg form-control' onChange={handleOnchange} placeholder='Ingresa el correo eletrónico para recuperar la contraseña.'/>
                                </div>        
                                <Link to="/login" className="colorsito">Login</Link>
                                <input type="submit" className='btn btn-success pull-right' value="Recuperar contraseña"/>
                                <div className='clearfix'></div>                      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
