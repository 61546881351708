import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import Modal from 'react-bootstrap4-modal';
import { LoaderSpinner } from '../../components/LoaderSpinner';

import moment from "moment"
import 'moment/locale/es';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import ReactTooltip from 'react-tooltip';

import { obtenerUsuarios, obtenerUsuario, actualizarCuenta, deleteAccount, createAccount, reset_user_created } from '../../actions/usersActions';


import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import $ from 'jquery'
export const UsersList = () => {
    
    const dispatch = useDispatch()
    const [editing, setEditing] = useState(false);
    const [user, setUser ] = useState({
        nombre:'',
        telefono:'',
        email:'',
        password:'',
        rol:'',
        fromadmin:true
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const loading = useSelector( state => state.auth.loading )
    const successUserCreated = useSelector( state => state.user.successUserCreated )
    const usertoedit = useSelector( state => state.user.user )
    const users = useSelector( state => state.user.users )
    const [ modalVisible, setModalVisible ] = useState(false);
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
  


    useEffect( async ()=> {
        await dispatch(obtenerUsuarios());
        if(successUserCreated){
            cerrarVentana();
            setUser({
                nombre:'',
                telefono:'',
                email:'',
                password:'',
                fromadmin:true
            })
            await dispatch(obtenerUsuarios());
            await dispatch(reset_user_created());
        }
        if(usertoedit){
            setEditing(true)
            setUser({
                id:usertoedit.data.id,
                nombre:usertoedit.data.nombre,
                telefono:usertoedit.data.telefono,
                email:usertoedit.data.email,
                rol:usertoedit.data.rol,
                password:'',
                fromadmin:true
            })
            setModalVisible(true);
        }
        if(search_custom_page == null) {
          $('#loading').hide();
        }
    },[successUserCreated,usertoedit,search_custom_page])
    
    
    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Estas seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                await dispatch(deleteAccount(id));
                await dispatch(obtenerUsuarios());
            }
          })
        
    }
    const cerrarVentana = () => {
        setModalVisible(false);
        setEditing(false)
        setUser({
            nombre:'',
            telefono:'',
            email:'',
            password:'',
            fromadmin:true
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(editing){
            await dispatch(actualizarCuenta({new_account:user}));
        }else{
            await dispatch(createAccount({new_account:user}));
        }
    }
    const handleEdit = async (id) => {
        console.log('id',id)
        await dispatch(obtenerUsuario(id))
    }
    const handleChange = (e) => {
      
        setUser({
            ...user,
            [e.target.name] : e.target.value
        })
    }

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
      };

      // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const rolMascara = {
      'user':3,
      'admin':2,
      'impulsa':1,
    }


    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de Usuarios</span>

                <button onClick={() => setModalVisible(true)} className="btn btn-primary float-right" ><span className="fas fa-plus"></span>   Crear Usuario</button>
            </div>
            <Modal visible={modalVisible} >
            <div className="modal-header">
                <h5 className="modal-title">Registro de usuario</h5>
            </div>
            <div className="modal-body">
                <form id="register-form" className="form" action="" method="post" onSubmit={ handleSubmit }>
                    
                    {
                        loading ? <LoaderSpinner/> : null
                    }
                   
                    <div className="form-group">
                        <label htmlFor="username" className="label-login">Nombre:</label><br/>
                        <input type="text" name="nombre" required value={user.nombre} onChange={ handleChange } id="nombre" className="form-control-lg form-control"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="username" className="label-login">Email:</label><br/>
                        <input type="email" name="email" required value={user.email} onChange={ handleChange } id="email" className="form-control-lg form-control"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="username" className="label-login">Teléfono:</label><br/>
                        <input type="text" name="telefono" required value={user.telefono} onChange={ handleChange } id="telefono" className="form-control-lg form-control"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="rol" className="label-role">Rol:</label><br/>
                        <select  name="rol" onChange={ handleChange } value={rolMascara[user.rol]} className="form-control-lg form-control">
                          {/* <option value="1">Impulsa</option> */}
                          <option value="2">Administracion</option>
                          <option value="3">Usuario</option>
                        </select>
                       
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className="label-login">Password:</label><br/>
                        <input type="password" name="password" required={!editing} value={user.password} onChange={ handleChange } id="password" className="form-control-lg form-control"/>
                    </div>
                    <div className="form-group">
                        {
                            editing ? 
                            <input type="submit" name="submit" className="btn btn-success btn-md" value="Editar usuario" />
                            : 
                            <input type="submit" name="submit" className="btn btn-success btn-md" value="Crear usuario" />
                        }
                        
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                Cerrar
                </button>
               
            </div>
            </Modal>
            <TableContainer component={Paper} className="mt-3">
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}}>Email</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Nombre Cuenta</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Nombre</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Teléfono</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Rol</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Estado</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Creada</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} width="150px"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                        ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : users
                        ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                            {row.email}
                            </TableCell>
                            <TableCell  align="right">
                            {row.nombre_empresa}
                            </TableCell>
                            <TableCell  align="right">
                            {row.nombre}
                            </TableCell>
                            <TableCell  align="right">
                            {row.telefono}
                            </TableCell>
                            <TableCell  align="right">
                            {row.rol}
                            </TableCell>
                            <TableCell  align="right">
                            {row.isActive == '1' ? <span className='badge badge-success'>Activo</span> : <span className='badge badge-danger'>Inactivo</span>}
                            </TableCell>
                          
                            <TableCell  align="right">
                            {moment(row.createdAt).fromNow()}
                            </TableCell>
                            
                            <TableCell  align="right">
                                <div className="input-group">
                                    <span onClick={ () => handleDelete(row.id) }  data-tip="Borrar Usuario" className="btn redondo_rojo btn-sm"><i className="nav-icon fas fa-remove"></i></span>
                                    <span onClick={ () => handleEdit(row.id)}  data-tip="Editar Usuario" className="btn redondo btn-sm"><i className="nav-icon fas fa-edit"></i></span>
                                </div>
                                    <ReactTooltip />

                            </TableCell>
                        </TableRow>
                        ))}

                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={3}
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={`Filas por pág: `}
                            SelectProps={{
                            
                            inputProps: {
                                'aria-label': 'Filas por pagina',
                            },
                            native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </TableContainer>
            
        </div>
    )
}
