import React from 'react'
import { Link, useParams  } from 'react-router-dom';

export const BasicDesign = ({ history }) => {

    const { idCampaign } = useParams()

    const handleClick = () => {
        
        history.push(`/campaign_design/plaintext/basicDesign/textEditor/${idCampaign}`);
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Diseños Básicos</span>
            </div>

                <div className="btn-group ml-4">
                    <Link to={`/campaign_design/plaintext/${idCampaign}`} className="colorsito">Volver</Link>
                </div>
                
            <div className="row mt-5">
                <div className="col-md-2">
                    <div  onClick={() => handleClick( "blanco" ) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey", height:"303px"}}>
                        <div className="theme-image text-center" >
                            <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                               
                                <div style={{"height": "210,66px","width":"241,27px"}}></div>

                            </div>
                        </div>
                        <div className="panel-footer text-center">
                            <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>En Blanco</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
