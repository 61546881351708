/* global $ */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    obtenerUsuario,
    actualizarImagen,
    getImagen,
    actualizarCuenta,
    generar2FA,
    desactivate2FA,
    validarCodigo2FA,
    validarCodigo2FAActive
} from '../../actions/usersActions';
import { LoaderSpinner } from '../../components/LoaderSpinner';

export const Perfil = () => {
    const d = new Date();
    const dispatch = useDispatch();
    const [busco, setBusco] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [img, setImg] = useState(null);
    const [codigo2FA, setCodigo2FA] = useState('');

    const [currentUser, setCurrentUser] = useState({
        nombre: '',
        email: '',
        telefono: '',
        password: '',
        twoFactorSecret: ''
    });

    const user = useSelector(state => state.auth.user);
    const loading = useSelector(state => state.auth.loading);
    const search_custom_page = useSelector(state => state.landings.search_custom_page);

    useEffect(() => {
        if (busco === false) {
            if (user) {
                setCurrentUser({
                    id: user.id,
                    nombre: user.name,
                    email: user.email,
                    telefono: user.telefono,
                    twoFactorSecret: user.twoFactorSecret
                });
                setImg(`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}`);
            }
            setBusco(true);
        }
        if (search_custom_page == null) {
            $('#loading').hide();
        }
    }, [user, search_custom_page]);

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const handleChange = (e) => {
        setCurrentUser({
            ...currentUser,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user.twoFactorSecret) {
            $('#modal2FACode').modal('show');
        } else {
            await dispatch(actualizarCuenta({ new_account: currentUser }));
        }
    };

    

    const onFormSubmit = async (e) => {
        e.preventDefault();
        await dispatch(actualizarImagen(file, fileName));
        setTimeout(() => {
            const d = new Date();
            setImg(`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}?${d.getTime()}`);
        }, 1000);
    };

    const handleGenerate2FA = async () => {
        await dispatch(generar2FA(user.id));
    };

    const handleDesactivate2FACode = async () => {
        if (user.twoFactorSecret) {
            $('#modal2FACodeDesactivate').modal('show');
        }
    };

    const handleDesactivate2FA = async () => {
        if (user.twoFactorSecret) {
            const isValid = await dispatch(validarCodigo2FAActive(user.id, codigo2FA));
            if (isValid) {
                $('#modal2FACodeDesactivate').modal('hide');
                await dispatch(desactivate2FA(user.id));
                setTimeout(() => {
                    window.location.reload(); // Recarga la página después de 5 segundos
                }, 2000); // 5000 ms = 5 segundos
            }
        }
    };

    const handleVerify2FACodeActive = async () => {
        const isValid = await dispatch(validarCodigo2FAActive(user.id, codigo2FA));
        if (isValid) {
            $('#modal2FACode').modal('hide');
            setTimeout(() => {
                window.location.reload(); // Recarga la página después de 5 segundos
            }, 2000); // 5000 ms = 5 segundos
        }
    };

    const handleVerify2FACode = async () => {
        const isValid = await dispatch(validarCodigo2FA(user.id, codigo2FA));
        if (isValid) {
            $('#modal2FACode').modal('hide');
            await dispatch(actualizarCuenta({ new_account: currentUser }));
        }
    };

    return (
        <div className="white_box QA_section card_height_100">
            <h4 className="p-3 naranja">Perfil</h4>
            {loading ? <LoaderSpinner /> : null}

            <div className="row mt-5 mb-5">
                <div className="col-md-4">
                    {user ?
                        <img src={`${process.env.REACT_APP_BACKEND}/users/getImage/${user.id}?${d.getTime()}`} className="img-circle elevation-2 mb-5" width="250" alt="User" />
                        : <img src={`${process.env.REACT_APP_USER_IMAGE}`} className="img-circle elevation-2 mb-5" width="250" alt="User" />
                    }

                    <form onSubmit={onFormSubmit}>
                        <div className="form-group">
                            <label>Subir Imagen</label><br />
                            <input type="file" name="myImage" onChange={saveFile} />
                        </div>
                        <button type="submit" className="btn btn-success">Subir</button>
                    </form>
                </div>
                <div className="col-md-4">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Nombre</label>
                            <input type="text" name="nombre" value={currentUser.nombre} onChange={handleChange} className="form-control-lg form-control" />
                        </div>
                        <div className="form-group">
                            <label>Teléfono</label>
                            <input type="text" name="telefono" value={currentUser.telefono} onChange={handleChange} className="form-control-lg form-control" />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" value={currentUser.email} onChange={handleChange} className="form-control-lg form-control" />
                        </div>
                        <div className="form-group">
                            <label>Contraseña</label>
                            <input type="password" name="password" value={currentUser.password} onChange={handleChange} className="form-control-lg form-control" />
                        </div>
                        <input type="submit" value="Actualizar" className="btn btn-success" />
                    </form>
                    {user && (user.twoFactorSecret !== null && user.twoFactorSecret !== '') ? (
                        <button className="btn btn-primary mt-4" onClick={handleDesactivate2FACode}>
                            Desactivar 2FA
                        </button>
                        ) : (
                        <button className="btn btn-primary mt-4" onClick={handleGenerate2FA}>
                            Activar 2FA
                        </button>
                    )}
                </div>
            </div>
            {/* Modal */}
            <div className="modal fade" id="modal2FA" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Autenticación Google.</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Escanea el siguiente código QR con tu aplicación de Autenticación Google.</p>
                            <div id="qrTwofa"></div>
                            <p>Secret: <span id="secretTwofa"></span></p>
                            <br />
                            <p>Por favor, introduce el código de 6 dígitos de tu aplicación de Autenticación Google.</p>
                            <input type="text" className="form-control" value={codigo2FA} onChange={(e) => setCodigo2FA(e.target.value)} placeholder="Código 2FA" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={handleVerify2FACodeActive}>Verificar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal para el código de 2FA */}
            <div className="modal fade" id="modal2FACode" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Verificación 2FA</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Por favor, introduce el código de 6 dígitos de tu aplicación de Autenticación Google.</p>
                            <input type="text" className="form-control" value={codigo2FA} onChange={(e) => setCodigo2FA(e.target.value)} placeholder="Código 2FA" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={handleVerify2FACode}>Verificar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal para el código de 2FA Desactivar*/}
            <div className="modal fade" id="modal2FACodeDesactivate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Verificación 2FA</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Por favor, introduce el código de 6 dígitos de tu aplicación de Autenticación Google.</p>
                            <input type="text" className="form-control" value={codigo2FA} onChange={(e) => setCodigo2FA(e.target.value)} placeholder="Código 2FA" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={handleDesactivate2FA}>Verificar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
