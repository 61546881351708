import React,{ useEffect, useState} from 'react'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import $ from 'jquery'

// actions
import { obtenerLandingToShow, addVisit, addConvertion, getFormInfo, sendFormData } from '../../actions/landingActions';
import { NotFoundScreen } from '../NotFoundScreen';

export const ShowLanding = (props) => {
    let history = useHistory();
    const dispatch = useDispatch()
    const { account_name } = useParams()
    const [ slug, setSlug ] = useState('');
    const [html, setHtml] = useState('');
    const [busco, setbusco] = useState(false);
    const [mandoForm, setMandoForm] = useState(false);
    const [creoForm, setcreoForm] = useState(false);
    const loading = useSelector( state => state.landings.loading )
    const show = useSelector( state => state.landings.show )
    const forminfo = useSelector( state => state.landings.forminfo )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const getAllUrlParams = (form) => {
      if(form){
        
        return JSON.parse('{"' + decodeURI(new URLSearchParams(new FormData(form)).toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      }
    }
      const logSubmit = async (e) => {
        
        e.preventDefault();
        //obtiene el form para serializarlo
        let form = document.querySelector('form');
        
        if(form && !mandoForm){
          
          setMandoForm(true);
          //lo serializa
          let object = getAllUrlParams(form);
          
          //agrega una conversion porque paso el form
          await dispatch(addConvertion(account_name, slug));
          // pasa los datos del formulario al submit
          await dispatch(sendFormData(object,{account_name, slug}));
          
          //verifica la action despues que pasa el formulario si es mensaje
          if(forminfo && forminfo['action'] == 'mensaje'){
              //alerta de error

              Swal.fire({
                  icon:'success',
                  title:'',
                  text:forminfo.action_response
              })
              
          }
          //si la accion es algun tipo de redireccion
          if(forminfo && (forminfo['action'] == 'redireccionar_url' || forminfo['action'] == 'redireccionar_landing')){
              //alerta de error
              console.log('forminfo.action_response',forminfo.action_response)
              window.location.href = forminfo.action_response;
          }
        }
      }

      const obtenerInformacion = async (account_name,separating) => {
        await dispatch(obtenerLandingToShow(account_name, JSON.stringify(separating)));
        await dispatch(getFormInfo({account_name, slug:JSON.stringify(separating)},1));
      }
    useEffect(() => {
      var forms = document.querySelector('form');
      //obtiene grupo de landing page de primero y luego el slug
      
      if(!slug){
        // let slug_and_grouplanding = window.location.href.split(window.location.origin)[1].split('/p')[1];
        let separating = window.location.href.split(window.location.origin)[1].split('/p')[1].split('/');
        // alert(separating)
        // console.log('1',separating)
        separating.shift();
        // console.log('2',separating)
        separating.shift();
        // console.log('3',separating)
        // alert(JSON.stringify(separating))
        setSlug(JSON.stringify(separating));
      }
      if(forms){
        
         
        
      }
      
        if(forminfo){
            const favicon = document.getElementById("favicon");
            
            favicon.href = process.env.REACT_APP_BACKEND+`/lading_page/favicon/${account_name}/${slug}`;
            // alert("?")
            // console.log('favicon.href',favicon.href)
            if(forminfo.meta_title){
                document.title = forminfo.meta_title;
            }
            let script_;
            if(forminfo.google_analitics){
     
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.google_analitics;
                // script.src = forminfo.facebook_pixel;
            
                document.body.appendChild(script_);
            }
            if(forminfo.facebook_pixel){
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.facebook_pixel;
                // script.src = forminfo.facebook_pixel;
            
                document.body.appendChild(script_);
            }
            if(forminfo.custom_script){
                script_ = document.createElement("script");
                script_.innerHTML = forminfo.custom_script;
                // script.src = forminfo.custom_script;
            
                document.body.appendChild(script_);
            }
            
        }

        if(show){
            if(show.estado == 'inactivo'){
              
              history.push('/no_found')
            }
            const htmlget = JSON.parse(show.html);
            if(forminfo && forminfo.ws){
              setHtml(htmlget.html+`<a href="https://web.whatsapp.com/send?phone=${forminfo.ws}" class="ws" target="_blank">
                <img src="https://www.sistemaimpulsa.com/img/whatsapp.png" width="60" />
              </a>`);
            }else{
                setHtml(htmlget.html);
            }
            setTimeout(()=>{
              console.log('creoForm',creoForm)
              if(creoForm === false){
                setcreoForm(true)
                console.log("cuantas veces?")
                $('form').addClass("formmaldito")
                // $('form').on('submit',logSubmit)
                document.getElementsByTagName('form')[0].addEventListener("submit",logSubmit,false)
              }
            },1500)
          
            dispatch(addVisit(account_name, slug));

            

        }else{
            if(!busco){
              let separating = window.location.href.split(window.location.origin)[1].split('/p')[1].split('/');
              // alert(separating)
              // console.log('1',separating)
              separating.shift();
              // console.log('2',separating)
              separating.shift();
              // console.log('3',separating)
              // alert(JSON.stringify(separating))
              setSlug(JSON.stringify(separating));
              obtenerInformacion(account_name,separating)
              
              setbusco(true);
                
            }else{

            }
        }
        if(search_custom_page == null){
          $('#loading').hide();
        }
        return () => {
          $('form').off('submit',logSubmit)
          $('form').off('submit')
          if(document.getElementsByTagName('form')){

            document.getElementsByTagName('form')[0]?.removeEventListener('submit',logSubmit)
          }

        }
    },[forminfo,show,search_custom_page])

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: html}}>
              
            </div>
        </div>
    )
}
