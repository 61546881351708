import React,{ useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner'
import { useSelector, useDispatch } from 'react-redux';
import { get_marketing_designs, delete_design, set_design } from '../../actions/campaignsActions';
import clienteAxios from '../../config/axios';

export const MailMarketingTemplate = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const [busco, setBusco] = useState(false);

    const loading = useSelector( state => state.campaigns.loading )
    const designs = useSelector( state => state.campaigns.designs )
    useEffect(()=>{
        if(!busco){
            dispatch(get_marketing_designs('sinhtml'));
            setBusco(true)
        }
    },[busco,designs])
 

    const handleClick = async (design) => {
        const designQuery = await clienteAxios.get(`/campaigns/get_marketing_designs?gethtml=${design.id}`);
        console.log('designQuery.data',designQuery.data)
        dispatch(set_design(designQuery.data));
        history.replace('/new_design_mail_template')
    }

    const handleDelete = async ( e , id ) => {
        e.preventDefault();
        await dispatch(delete_design(id));
        setBusco(false)
        console.log(id)
    }

   

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de plantillas</span>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="btn-group ml-4">
                <Link to="/new_design_mail_template" className="btn btn-default"><span className="fas fa-plus "></span>  Crear Plantilla</Link>
                <Link to="/mail_marketing" className="btn btn-link float-right" >  Volver</Link>
            </div>
            <div className="row mt-5">
               
                {
                    designs && designs.length ? designs.map( design => (
                        <div className="col-md-3" key={design.id}>
                                <div className="card" style={{"width": "18rem;"}} onClick={ () => handleClick(design) }>
                                    <img  className="card-img-top" src={`${process.env.REACT_APP_BACKEND}/campaigns/get_image_template/${design.id}`} alt="User "/>
                                    <div className="card-body">
                                        <h5 className="card-title">{design.categoria}</h5>
                                        <p className="card-text">{design.nombre}</p>
                                        <button onClick={ (e) => handleDelete(e,design.id) } className="btn btn-primary">Eliminar</button>
                                    </div>
                                </div>
                         </div>
                       
                    )) : <span>Cargando diseños disponibles... ...</span>
                }
            </div>
        </div>
    )
}
