import React,{ useEffect, useState } from 'react'
import { Link, useParams  } from 'react-router-dom';
import $ from 'jquery'
import { useSelector, useDispatch } from 'react-redux';

import { setLayout } from '../../actions/listasActions';
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';
import clienteAxios from '../../config/axios';

export const CampaignDesign = () => {
    const dispatch = useDispatch()

    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    const campaigns = useSelector( state => state.campaigns.campaigns )
    const [una,setuna] = useState(false);
    const [currentC, setCurrentC] = useState(false);
    const { id } = useParams()

    useEffect(()=>{
        if(una === false){
            obtenerCampana();
            setuna(true)
        }
        if(search_custom_page == null){
            $('#loading').hide();
        }
        console.log('campaigns',campaigns)
        if(campaigns && campaigns.length > 0){

            let currentCampaigns = campaigns.filter( campaign => campaign.id == id);
            if(currentCampaigns.length > 0){
                setCurrentC(currentCampaigns[0])
                dispatch(setLayout(currentCampaigns[0]))

            }
        }
    },[search_custom_page,campaigns,currentC])

    const obtenerCampana = async () => {
        let c = await clienteAxios.get(`/campaigns/${id}`);
        // console.log('c.data.contenido',JSON.parse(c.data.contenido));
        // console.log('c.data',c.data)
        if(JSON.parse(c.data.contenido)){

            setCurrentC(c.data);
            dispatch(setLayout(c.data))
        }
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbCampaign active={3}/>

                <span className="mr-3 naranja">Diseño de Campaña</span>
            </div>
            <div className="btn-group ml-4">
                <Link to="/mail_marketing"  className="colorsito">Volver</Link>
            </div>
            <div className="row text-center mt-5">
            {currentC ? 
                <div className="row">
                <div className="col-md-12">
                    <Link to={`/campaign_editor/${id}/html/`} style={{"fontSize":"14em"}}>
                        <i class="far fa-images"></i>
                        <h4 style={{"color":"black"}}>Editar Diseño Actual</h4>
                    </Link>
                </div></div>:null    
                }
                <div className="col-md-6">
                    <Link to={`/campaign_design/plaintext/${id}`} style={{"fontSize":"14em"}}>
                        <i class="far fa-file"></i>
                        <h4 style={{"color":"black"}}>Texto Plano</h4>
                    </Link>
                </div>
                <div className="col-md-6">
                    <Link to={`/campaign_design/html/${id}`} style={{"fontSize":"14em"}}>
                        <i class="far fa-images"></i>
                        <h4 style={{"color":"black"}}>Editor visual</h4>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}
