import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

export const TopStatistics = () => {
    const dispatch = useDispatch()

    const home_data = useSelector( state => state.auth.home_data )
    console.log('home_data',home_data)
    let dollarUSLocale = Intl.NumberFormat('de-DE');

  return (
    <div className="wrap_statistics d-flex align-items-center">
        <div className="header_statistics">
            <div className="statistics_icon"><i className="nav-icon fas fa-users" aria-hidden="true"></i></div>
            <div className="statistics_numbers">{home_data && dollarUSLocale.format(home_data.cantidad_clientes)}</div>
            <div className="statistics_title">Clientes Registrados</div>
        
        </div>
        <div className="header_statistics">
            <div className="statistics_icon">
                <i class="nav-icon far fa-envelope-open" aria-hidden="true"></i>
            </div>
            <div className="statistics_numbers">{home_data && dollarUSLocale.format(home_data.last_campaing_data_aperturas[0]?.cantidad ? home_data.last_campaing_data_aperturas[0]?.cantidad : 0)}</div>
            <div className="statistics_title">Apertura último envío Mailing</div>
        </div>
        <div className="header_statistics">
            <div className="statistics_icon">
                <i class="nav-icon fas fa-mouse" aria-hidden="true"></i>
            </div>

            <div className="statistics_numbers">{home_data && dollarUSLocale.format(home_data.last_campaing_data_clicks[0]?.cantidad ? home_data.last_campaing_data_clicks[0]?.cantidad : 0)}</div>
            <div className="statistics_title">Clics último envío Mailing</div>
        </div>
        <div className="header_statistics">
            <div className="statistics_icon">
                <i class="nav-icon fas fa-laptop" aria-hidden="true"></i>
            </div>
            <div className="statistics_numbers">{home_data && dollarUSLocale.format(home_data.apertura_ultima_landing[0]?.visitas ? home_data.apertura_ultima_landing[0]?.visitas : 0)}</div>
            <div className="statistics_title">Apertura última Landing</div>
        </div>
        <div className="header_statistics">
            <div className="statistics_icon">
                <i class="nav-icon fas fa-mouse-pointer" aria-hidden="true"></i>
            </div>
            
            <div className="statistics_numbers">{home_data && dollarUSLocale.format(home_data.conversiones_ultima_landing[0]?.conversiones ? home_data.conversiones_ultima_landing[0]?.conversiones : 0)}</div>
            <div className="statistics_title">Formularios completados último landing</div>
        </div>
    </div>
  )
}
