import React,{useEffect,useState, useRef} from 'react'
import $ from 'jquery'; 
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { 
    get_domains_action,
    save_domain_action,
    verify_domain_action
} from '../../actions/dominiosActions';
import moment from 'moment'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import { LoaderSpinner } from '../../components/LoaderSpinner';


export const Domains = () => {
    const refCloseModal = useRef();
    const dispatch = useDispatch()
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    const domains = useSelector( state => state.domains.domains )
    const loading = useSelector( state => state.domains.loading )
    const [dominio, setDominio] = useState({
        dominio_rebote:'',
        dominio_traking:'',
        dominio_envio:'',
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [busco, setBusco] = useState(false);
    const [hostname, setHostname] = useState('');
    const [hostnameValue, setHostnameValue] = useState('');
    useEffect(() => {
        if(!busco){
            dispatch(get_domains_action());
            setBusco(true);
        }

        if(search_custom_page == null){
          $('#loading').hide();
        }
    },[search_custom_page,domains,loading])
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - domains.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChange = (e) => {
        setDominio({
            ...dominio,
            [e.target.name]:e.target.value
        })
    }

    const handleClick = async (e) => {
        if(!dominio.dominio_rebote){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:"El campo Sub Dominio de Rebote es obligatorio"
            })
            return false
        }
        // if(!dominio.dominio_traking){
        //     Swal.fire({
        //         icon:'error',
        //         title:'Hubo un error',
        //         text:"El campo Sub Dominio de Traking es obligatorio"

        //     })
        //     return false   
        // }
        if(!dominio.dominio_envio){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:"El campo Dominio de envío es obligatorio"

            })
            return false  
        }

        await dispatch(save_domain_action(dominio));
        refCloseModal.current.click();
        setDominio({
            dominio_rebote:'',
            dominio_traking:'',
            dominio_envio:'',
        })
        dispatch(get_domains_action());
    }
    const handleVerify = async (dominio) => {
        await dispatch(verify_domain_action(dominio));
        await dispatch(get_domains_action());

    }
    const handleSet = async (data) => {
        setHostname(data.selector+'._domainkey.'+data.dominio) 
        setHostnameValue("v=DKIM1; k=rsa; h=sha256; p="+data.public_string)
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Dominios</span>
            </div>
            <div className="btn-group ml-4">
                <Link to="/mail_marketing" className="btn btn-link float-right" >  Volver</Link>
                <button className="btn btn-success float-right" data-toggle="modal" data-target="#exampleModal"><span className="fas fa-plus "></span>  Agregar Dominio</button>
                
            </div>
            {
                    loading ? <LoaderSpinner/> : null
                }
            <div className="row mt-5">
                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Agregar Dominio</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Sub Dominio de Rebote</label>
                                    <input type="text" value={dominio.dominio_rebote} name="dominio_rebote" onChange={handleChange} className="form-control"/>
                                </div>
                                <div className="form-group" style={{"display":"none"}}>
                                    <label>Sub Dominio de Traking</label>
                                    <input type="text" value={dominio.dominio_traking} name="dominio_traking" onChange={handleChange} className="form-control"/>

                                </div>
                            </div>
                            <div className="col-md-6">
                                
                                <div className="form-group">
                                    <label>Dominio de envío</label>
                                    <input type="text" value={dominio.dominio_envio} name="dominio_envio" onChange={handleChange} className="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Bounce Domain</label>
                                    <input type="text" value={`${dominio.dominio_rebote}.${dominio.dominio_envio}`} disabled={true} className="form-control"/>
                                </div>
                                <div className="form-group"  style={{"display":"none"}}>
                                    <label>Traking Domain</label>
                                    <input type="text" value={`${dominio.dominio_traking}.${dominio.dominio_envio}`} disabled={true} className="form-control"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" ref={refCloseModal} data-dismiss="modal">Cerrar</button>
                        <button type="button" onClick={handleClick} className="btn btn-primary">Guardar</button>
                    </div>
                    </div>
                </div>
                </div>
                <div className="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel2">Configuración</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Crear un registro TXT con los siguientes parámetros</label>
                                </div>
                                <div className="form-group">
                                    <label>Hostname</label>
                                    <input type="text" value={hostname} disabled={true} className="form-control"/>
                                </div>
                                <div className="form-group" >
                                    <label>Value</label>
                                    <textarea type="text" rows={7} value={hostnameValue} disabled={true} className="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
                </div>

                <TableContainer component={Paper} className="mt-3">
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}}>#</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Dominio</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Dominio Rebote</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Verificado</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Creado</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                        ? domains.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : domains
                        ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                            {row.id}
                            </TableCell>
                            <TableCell  align="right">
                            {row.dominio}
                            </TableCell>
                            <TableCell  align="right">
                            {row.dominio_rebote}
                            </TableCell>
                            <TableCell  align="right">
                            {row.verified  == '1' ? <span  className="badge badge-success">Verificado</span> : <span className="badge badge-danger">No verificado</span>}
                            </TableCell>
                            <TableCell  align="right">
                            {moment(row.createdAt).fromNow()}
                            </TableCell>
                         
                            <TableCell  align="right">
                            <button className="btn btn-success btn-sm mr-1" data-toggle="modal" onClick={() => handleSet(row) } data-target="#exampleModal2">Configurar</button>
                            {row.verified  == '1' ? '' : <button className="btn btn-success  btn-sm" onClick={() => handleVerify(row.dominio) }>Verificar</button> }
                            </TableCell>
                        </TableRow>
                        ))}

                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={3}
                            count={domains.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={`Filas por pág: `}
                            SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
