import React, {useState , useEffect}  from 'react'
import { Link, useHistory  } from 'react-router-dom';
import './styles.css'
import { guardarDiseno, obtenerDisenoLista, obtenerDisenosBasicos, setLayout, deleteBasic } from '../../actions/listasActions';
import { obtenerPlantillasAction, setLayoutToEdit } from '../../actions/plantillasActions';
import { useSelector, useDispatch } from 'react-redux';
import { LoaderSpinner } from '../../components/LoaderSpinner';

export const Basic = ({history}) => {

    const dispatch = useDispatch()
    const [ busco, setBusco ] = useState(false);

    let historyHook = useHistory();
    const idTrozos = window.location.pathname.split('/');
    
    const loading = useSelector( state => state.listas.loading )
    const plantillas = useSelector( state => state.plantillas.plantillas )
    const draws = useSelector( state => state.listas.draws )

    const handleClick = ( allObject ) => {
        dispatch(setLayout(allObject))
        historyHook.push(`/layout_editor/${idTrozos[2]}`);
    }
    const deleteDraw = ( id ) => {
        dispatch(deleteBasic(id))
        setBusco(false)
    }
    useEffect( async () => {
        await dispatch(obtenerDisenoLista(idTrozos[2]))
        await dispatch(obtenerPlantillasAction())
        let p = plantillas.filter( plantilla => (plantilla.id == idTrozos[2]) )
        console.log('p',p)
        await dispatch(setLayoutToEdit(p[0]))
        if(busco === false){
            await dispatch(obtenerDisenosBasicos())
            setBusco(true);
        }
    },[busco])
    return (
        <div>
            <div className="p-4">
                <span className="mr-3 naranja">Diseños Básico</span>
                <Link onClick={() => history.goBack()}  className="float-right p-2 mr-4">Volver</Link>

            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row mt-5">
                <div className="col-md-2">
                    <div  onClick={() => handleClick( "blanco" ) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey", height:"303px"}}>
                        <div className="theme-image text-center" >
                            <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                               
                                <div style={{"height": "210,66px","width":"241,27px"}}></div>

                            </div>
                        </div>
                        <div className="panel-footer text-center">
                            <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>En Blanco</p>

                        </div>
                    </div>
                </div>
                {
                    plantillas && plantillas.length && plantillas.map( plantilla => (
                        plantilla.contenido ? <div className="col-md-2">
                            <div  onClick={ () => handleClick(plantilla) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey", height:"303px"}}>
                                <div className="theme-image text-center" >
                                    <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                                    
                                        <div style={{"height": "210,66px","width":"241,27px"}}></div>

                                    </div>
                                </div>
                                <div className="panel-footer text-center">
                                    <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>{plantilla.nombre}</p>

                                </div>
                            </div>
                        </div> : null
                    ))
                }
                {/* <div className="col-md-2">
                    <div  onClick={() => handleClick( "una_columna" ) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey"}}>
                        <div className="theme-image text-center" >
                            <div className="overlay" style={{"min-height": "180px"}}>
                                
                                <img src={window.location.origin+"/BF-basic-onecolumn.png"} width="100%"/>

                            </div>
                        </div>
                        <div className="panel-footer text-center">
                            <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>Una columna</p>
                        </div>
                    </div>
                </div> */}
                {
                    draws && draws.map( draw => (
                        draw.type === 'basic' ? 
                        <div className="col-md-2">
                            <div  onClick={() => handleClick( draw.id ) } className="overlay panel panel-default panel-custom-default ml-5" style={{"border": "1px solid grey"}}>
                                <div className="theme-image text-center" >
                                    <div className="overlay" style={{"minHeight": "240px","height": "310,66px","width":"241,27px"}}>
                                        
                                        <img src="" width="100%"/>

                                    </div>
                                </div>
                                <div className="panel-footer text-center">
                                    <p className="text-center" style={{ backgroundColor:"white", padding :"10px"}}>{draw._name}</p>
                                </div>
                            </div>
                            <button onClick={() => deleteDraw(draw.id)} style={{position:"absolute",right:"10px",top:"5px"}} className="float-right btn btn-default">Borrar</button>

                        </div> : null
                    ))
                }
            </div>
        </div>
    )
}
