import React,{ useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
// import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link } from 'react-router-dom';
import $ from 'jquery'

// redux
import { useSelector, useDispatch } from 'react-redux';
import {  obtenerContacto } from '../../actions/contactosActions';
import {  obtenerCliente } from '../../actions/clientesActions';
import { obtenerContactFieldsAction } from '../../actions/customFieldsActions';



export const ViewContact = () => {

    const [contactId, setContactId] = useState(null);
    const dispatch = useDispatch()

    const loading = useSelector( state => state.contactos.loading )
    const clientedit = useSelector( state => state.clientes.clientedit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    // const error = useSelector( state => state.contactos.error )
    const contactoSearch = useSelector( state => state.contactos.contactdit )
    const contactFields = useSelector( state => state.customFields.contactsfields.normal )
    const contactFieldsCustom = useSelector( state => state.customFields.contactsfields.custom )
    useEffect( async () => {
        await dispatch(obtenerContactFieldsAction())
        
        // console.log('window.location.pathname',)
        const idTrozos = window.location.pathname.split('/');
        // console.log('idTrozos',idTrozos)
        if(idTrozos.length === 3){
            console.log('idTrozos',idTrozos)
            setContactId(idTrozos[2]);
        }
        if(contactId && contactoSearch === null){
            console.log('contactId',contactId)
            dispatch(obtenerContacto(contactId))
        }
        if(contactoSearch){
            setContacto(contactoSearch);
            await dispatch(obtenerCliente(contactoSearch.cliente_id));
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[contactId,contactoSearch,search_custom_page])

    const [contacto,setContacto] = useState({
        cliente_id:null,
        nombre:'',
        apellido:'',
        mail:[],
        telefono:[],
        cargo:'',
        creador:'',
        estado:'',
        visibilidad:'',
        tags:[],
        origen:[]
    });

  

  
    

    return (
        <div className="white_box QA_section card_height_100" >
            <div className="p-4">
                <span className="mr-3 naranja">Ver Contacto</span>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
                <div className="row" >
                <div className="col-md-6">

                    {/* <div className="form-group">
                        <label>Cliente</label>
                        <input type="text" disabled value={contacto.cliente} className="form-control-lg form-control" name="cliente"/>
                    </div> */}
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[0].custom_name) ? contactFields[0].custom_name : "Nombre"}</label>
                        <input type="text" disabled value={contacto.nombre} className="form-control-lg form-control" name="nombre"/>
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[1].custom_name) ? contactFields[1].custom_name : "Apellido"}</label>
                        <input type="text" disabled value={contacto.apellido} className="form-control-lg form-control" name="apellido"/>
                    </div>
                  
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[2].custom_name) ? contactFields[2].custom_name : "Email"}</label>
                        <ReactTagInput 
                        readOnly
                            tags={contacto.mail} 
                            placeholder="Ingrese el email, y presione enter."
                            onChange={(newMails) => setContacto({ ...contacto, mail:newMails})}
                        />
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[3].custom_name) ? contactFields[3].custom_name : "Telefono"}</label>
                        <ReactTagInput 
                        readOnly
                            tags={contacto.telefono} 
                            placeholder="Ingrese el telefono, y presione enter."
                            onChange={(newtlfs) => setContacto({ ...contacto, telefono:newtlfs})}
                        />
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[4].custom_name) ? contactFields[4].custom_name : "Cargo"}</label>
                        <input type="text" disabled value={contacto.cargo} className="form-control-lg form-control" name="cargo"/>
                    </div>
                </div>
                <div className="col-md-6">
                    
                   
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[5].custom_name) ? contactFields[5].custom_name : "Tags"}</label>
                        <ReactTagInput 
                        readOnly
                            tags={contacto.tags} 
                            placeholder="Ingrese el texto de los tags, y presione enter."
                            onChange={(newTags) => setContacto({ ...contacto, tags:newTags})}
                        />
                       
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[6].custom_name) ? contactFields[6].custom_name : "Origen"}</label>
                        <ReactTagInput 
                        readOnly
                            tags={contacto.origen} 
                            placeholder="Ingrese el texto de los origenes, y presione enter."
                            onChange={(newOrigen) => setContacto({ ...contacto, origen:newOrigen})}
                        />
                      
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[9].custom_name) ? contactFields[9].custom_name : "Cliente"}</label>
                        <input type="text" className="form-control-lg form-control" disabled name="cliente_id" value={clientedit ? clientedit.nombre : ""}/>
                    </div>
                    <div className="form-group">
                        <label>{ (contactFields && contactFields.length && contactFields[7].custom_name) ? contactFields[7].custom_name : "Estado"}</label>
                        <select value={contacto.estado} disabled  className="form-control-lg form-control" name="estado">
                            <option value="activo">Activo</option>
                            <option value="bloqueado">Bloqueado</option>
                            <option value="papelera">Papelera</option>
                        </select>
                    </div>
                    <div className="form-group" style={{"display":"none"}}>
                        <label>{ (contactFields && contactFields.length && contactFields[8].custom_name) ? contactFields[8].custom_name : "Visibilidad"}</label>
                        <select value={contacto.visibilidad} disabled  className="form-control-lg form-control" name="visibilidad">
                            <option value="admin">Admin</option>
                        </select>
                    </div>
                    {
                        contactFieldsCustom && contactFieldsCustom.length ? contactFieldsCustom.map( input => (
                            <div className="form-group">
                                <label>{ input.name }</label>
                                {
                                    input.type !== 'text' ?
                                        input.type === 'select' ?
                                        <ReactTagInput 
                                            tags={contacto && contacto.custom ? contacto.custom[input.name] : []} 
                                            placeholder=""
                                            readOnly
                                        />
                                        : 
                                        <ReactTagInput 
                                            tags={contacto && contacto.custom ? contacto.custom[input.name] : []} 
                                            placeholder=""
                                            readOnly

                                        />
                                       
                                    : <input type="text" disabled className="form-control-lg form-control" value={contacto.custom ? contacto.custom[input.name] : ""}  name={input.name} />
                                    
                                }
                                
                            </div>
                        )) : null
                    }
                </div>

                <div className="row mb-5">
                    <div className="col-md-4">
                        <Link to="/contacts" className="p-2 colorsito">Volver</Link>

                    </div>
                </div>
                </div>
        </div>
    )
}
