import React, { useEffect, useState } from 'react'
// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerContactosAction, borrarContacto } from '../../actions/contactosActions';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import moment from "moment"
import 'moment/locale/es';
import ReactTooltip from 'react-tooltip';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import $ from 'jquery'

export const ContactList = () => {

    const dispatch = useDispatch()
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const contactos = useSelector( state => state.contactos.contactos )
    const loading = useSelector( state => state.contactos.loading )
    const [getcontactos, setGetContactos] = useState(false);


    console.log(contactos)

    useEffect( ()=> {
        // consultar la api
        cargarContactos();
        if(getcontactos){
            cargarContactos();
            setGetContactos(false);

        }

        if(search_custom_page == null){
          $('#loading').hide();
        }
        
    },[getcontactos,search_custom_page]);

    const cargarContactos = () => dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10`}));


    const handleDelete = (contact_id) => {
        Swal.fire({
            title: 'Estas Seguro?',
            text: "Estas seguro de borrarlo?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borralo!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(borrarContacto(contact_id))
                Swal.fire(
                    'Borrado!',
                    'El contacto fue borrado.',
                    'success'
                )
                setGetContactos(true);
            }
          })
    }

  
    const handleChangePage = async (page) => {
      await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=${page}&limit=10&size=10`}))

    }
 const handleSearch = async(e) => {
      // console.log(e.target.value)
      await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${e.target.value}`}))
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Lista de Contactos</span>
                <Link to="/new_contact" className="btn btn-primary float-right" ><span className="fas fa-plus "></span>   Crear Contacto</Link>

            </div>

      
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="table-responsive">
              <div className="row">
                <div className="col-md-4">
                  <input type="text" className="form-control input_busqueda" onChange={handleSearch} placeholder="Buscar [nombre contacto], [correo contacto], [tags], [origen]"/>
                </div>
              </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>Creador</th>
                  <th>Fecha creación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  contactos && contactos.records && contactos.records.length ? contactos.records.map( row => (
                    <tr>
                      <td>{row.nombre} {row.apellido}</td>
                      <td>{(row.mail).length > 0 ? (row.mail).join(', ') : "" }</td>
                      <td>{(row.telefono).length > 0 ? (row.telefono).join(', ') : ""}</td>
                      <td>{row.creador}</td>
                      <td>{moment(row.fecha).fromNow()}</td>
                      <td>
                        <div className="input-group">
                            <Link to={`/view_contact/${row.id}`} className="btn redondo btn-sm" data-tip="Ver Contacto"><i className="nav-icon fas fa-eye"></i></Link>
                            <span onClick={ () => handleDelete(row.id) } className="btn redondo_rojo btn-sm" data-tip="Eliminar Contacto"><i className="nav-icon fas fa-remove"></i></span>
                            <Link to={`/edit_contact/${row.id}`} className="btn redondo btn-sm" data-tip="Editar Contacto"><i className="nav-icon fas fa-edit"></i></Link>
                            <ReactTooltip />
                        </div>
                      </td>
                    </tr>
                  )): null
                }
              </tbody>
            </table>
                <div className="text-center">
                  <nav aria-label="Page navigation example">
                      <ul className="pagination">
                          <div className="page-item mr-10">
                              Pág {contactos.currentPage + 1} -

                              {contactos.totalItems} Registros 
                          </div>
                          <li className="page-item"><a className="page-link" onClick={ ()=> handleChangePage( contactos.currentPage>0 ? contactos.currentPage -1 : 0)}>Anterior</a></li>
                          {
                              [...Array(contactos.totalPages).keys()].map( (page,index) => {
                                  if(index < 15){

                                      if(page  == contactos.currentPage ){
                                          return <li className="page-item active">
                                          <a className="page-link" onClick={ ()=> handleChangePage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                          </li>
                                      }else{
                                          return <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(page)} >{page + 1}</a></li>
                                      }
                                  }
                              })
                          }
                          {
                              contactos.totalPages > 15 ? 
                              <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(contactos.totalPages-1)}>{contactos.totalPages-1}</a></li> : null
                          }
                          <li className="page-item"><a className="page-link" onClick={ () => handleChangePage(contactos.currentPage < contactos.totalPages -1 ? contactos.currentPage + 1 : contactos.totalPages -1)}>Siguiente</a></li>
                      </ul>
                  </nav>
              </div>

            </div>
        
        </div>
    )
}
