import {
    BEGIN_NEW_LIST,
    NEW_LIST_SUCCESS,
    NEW_LIST_ERROR,

    BEGIN_GET_LIST,
    GET_LIST_SUCCESS,
    // GET_LIST_ERROR,

    BEGIN_GET_ONE_LIST,
    GET_ONE_LIST_SUCCESS,
    GET_ONE_LIST_ERROR,

    TRY_REMOVE_LIST,
    TRY_REMOVE_LIST_SUCCESS,
    TRY_REMOVE_LIST_ERROR,

    TRY_UPDATE_LIST,
    TRY_UPDATE_LIST_SUCCESS,
    TRY_UPDATE_LIST_ERROR,

    TRY_SAVE_DRAW,
    TRY_SAVE_DRAW_SUCCESS,
    TRY_SAVE_DRAW_ERROR,

    TRY_GET_DRAW,
    TRY_GET_DRAW_SUCCESS,
    TRY_GET_DRAW_ERROR,

    TRY_SAVE_BASIC,
    TRY_SAVE_BASIC_SUCCESS,
    TRY_SAVE_BASIC_ERROR,

    TRY_GET_BASICS,
    TRY_GET_BASICS_SUCCESS,
    TRY_GET_BASICS_ERROR,

    TRY_SET_LAYOUT,

    TRY_DELETE_DRAW,
    TRY_DELETE_DRAW_SUCCESS,
    TRY_DELETE_DRAW_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function setLayout( layout ){
    return async ( dispatch )=> {
        dispatch( intentaSetLayout(layout) );
    }     
}

const intentaSetLayout = (layout) => ({
    type:TRY_SET_LAYOUT,
    payload:layout      
})

export function obtenerDisenosBasicos(){
    return async ( dispatch )=> {
        dispatch( intentaObtenerDisenosBasicos() );
        try {
            const respuesta = await clienteAxios.get(`/basics/` );

            dispatch( intentaObtenerDisenosBasicosSuccess(respuesta.data) )
            //alerta de error
           
        } catch (error) {
            console.log(error)

            dispatch( intentaObtenerDisenosBasicosError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaObtenerDisenosBasicos = () => ({
    type:TRY_GET_BASICS,
    payload:true      
})

const intentaObtenerDisenosBasicosSuccess = ( basics ) => ({
    type:TRY_GET_BASICS_SUCCESS,
    payload:basics      
})

const intentaObtenerDisenosBasicosError = () => ({
    type:TRY_GET_BASICS_ERROR,
    payload:true      
})

export function guardarBasico( basico ){
    return async ( dispatch )=> {
        dispatch( intentaGuardarBasico() );
        try {
             await clienteAxios.post(`/basics/`, basico );

            dispatch( intentaGuardarBasicoSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Ha sido Guardado el diseño básico con éxito.'
            })

        } catch (error) {
            console.log(error)

            dispatch( intentaGuardarBasicoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }      
}

const intentaGuardarBasico = () => ({
    type:TRY_SAVE_BASIC,
    payload:true    
})

const intentaGuardarBasicoSuccess = ( basic ) => ({
    type:TRY_SAVE_BASIC_SUCCESS,
    payload:basic    
})

const intentaGuardarBasicoError = () => ({
    type:TRY_SAVE_BASIC_ERROR,
    payload:true    
})

export function actualizarListaAction( data ){
    return async ( dispatch )=> {
        dispatch( intentaActualizarLista() );
        try {
            
             await clienteAxios.put(`/lists/${data.id}`, data );

            dispatch( intentaActualizarListaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Ha sido Actualizada la lista con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( intentaActualizarListaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaActualizarLista = () => ({
    type:TRY_UPDATE_LIST,
    payload:true
})

const intentaActualizarListaSuccess = () => ({
    type:TRY_UPDATE_LIST_SUCCESS,
    payload:true    
})

const intentaActualizarListaError = () => ({
    type:TRY_UPDATE_LIST_ERROR,
    payload:true    
})

export function deleteBasic( id ){
    return async ( dispatch )=> {
        dispatch( intentaBorrarDiseno() );
        try {
             await clienteAxios.delete(`/basics/${id}` );

            dispatch( intentaBorrarDisenoSuccess() );

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Diseño Eliminado'
            })

        } catch (error) {
            console.log(error)

            dispatch( intentaBorrarDisenoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }      
}

const intentaBorrarDiseno = () => ({
    type:TRY_DELETE_DRAW,
    payload:true    
})

const intentaBorrarDisenoSuccess = () => ({
    type:TRY_DELETE_DRAW_SUCCESS,
    payload:true    
})

const intentaBorrarDisenoError = () => ({
    type:TRY_DELETE_DRAW_ERROR,
    payload:true    
})

export function obtenerDisenoLista( id_lista ){
    return async ( dispatch )=> {
        dispatch( intentaObtenerDiseno() );
        try {
            const respuesta = await clienteAxios.get(`/basics/${id_lista}`);
            
            dispatch( intentaObtenerDisenoSuccess( respuesta.data ) )
            

        } catch (error) {
            console.log(error)

            dispatch( intentaObtenerDisenoError() );

            //alerta de error
            // Swal.fire({
            //     icon:'error',
            //     title:'Hubo un error',
            //     text:'Hubo un error, intenta de nuevo'
            // })
        }        
    }         
}

const intentaObtenerDiseno = () => ({
    type:TRY_GET_DRAW,
    payload:true     
})
const intentaObtenerDisenoSuccess = ( diseno ) => ({
    type:TRY_GET_DRAW_SUCCESS,
    payload:diseno     
})
const intentaObtenerDisenoError = () => ({
    type:TRY_GET_DRAW_ERROR,
    payload:true     
})

export function guardarDiseno ( diseno ){
    return async ( dispatch )=> {
        dispatch( intentaGuardarDiseno() );
        try {
             await clienteAxios.post(`/lists_draw/`, diseno );

            dispatch( intentaGuardarListaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Diseño Guardado'
            })

        } catch (error) {
            console.log(error)

            dispatch( intentaGuardarDisenoError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }       
}

const intentaGuardarDiseno = () => ({
    type:TRY_SAVE_DRAW,
    payload:true  
})

const intentaGuardarListaSuccess = (diseno) => ({
    type:TRY_SAVE_DRAW_SUCCESS,
    payload:diseno      
})

const intentaGuardarDisenoError = () => ({
    type:TRY_SAVE_DRAW_ERROR,
    payload:true      
})

export function borrarListaAction( id ){
    return async ( dispatch )=> {
        dispatch( intentaBorrarLista() );
        try {
             await clienteAxios.delete(`/lists/${id}` );

            dispatch( intentaBorrarListaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Ha sido borrada la lista con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( intentaBorrarListaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }      
}

const intentaBorrarLista = () => ({
    type:TRY_REMOVE_LIST,
    payload:true
})

const intentaBorrarListaSuccess = () => ({
    type:TRY_REMOVE_LIST_SUCCESS,
    payload:true    
})

const intentaBorrarListaError = () => ({
    type:TRY_REMOVE_LIST_ERROR,
    payload:true    
})

export function obtenerListaAction( id ){
    return async ( dispatch )=> {
        dispatch( intentantoElementoDeLista() );
        try {
            const respuesta = await clienteAxios.get(`/lists/${id}` );
            console.log('respuesta.data',respuesta.data)
            dispatch( obtenerListaSuccess(respuesta.data) )


        } catch (error) {
            console.log(error)

            dispatch( obtenerListaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }    
}

const intentantoElementoDeLista = () => ({
    type:BEGIN_GET_ONE_LIST,
    payload:true     
})

const obtenerListaSuccess = (lista) => ({
    type:GET_ONE_LIST_SUCCESS,
    payload:lista     
})

const obtenerListaError = () => ({
    type:GET_ONE_LIST_ERROR,
    payload:true     
})


export function obtenerListasAction(){
    return async ( dispatch )=> {
        dispatch( intentantoObtenerListas() );
        try {
            const respuesta = await clienteAxios.get('/lists' );
            dispatch( obtenerListasSuccess(respuesta.data) )
            

        } catch (error) {
            console.log(error)

            dispatch( obtenerListasError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }
}

const intentantoObtenerListas = () => ({
    type:BEGIN_GET_LIST,
    payload:true    
})

const obtenerListasSuccess = ( listas ) => ({
    type:GET_LIST_SUCCESS,
    payload:listas    
})

const obtenerListasError = ( error ) => ({
    type:GET_LIST_SUCCESS,
    payload:error    
})


export function crearListaAction( lista ){
    return async ( dispatch ) => {
        dispatch( haciendoPostLista() );
        try {
            const respuesta = await clienteAxios.post('/lists', lista );

            dispatch( postListaSuccess(respuesta.data) )

            //alerta de success
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Lista creada con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( postListaError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const haciendoPostLista = () => ({
    type:BEGIN_NEW_LIST,
    payload:true
})

const postListaSuccess = ( lista ) => ({
    type:NEW_LIST_SUCCESS,
    payload:lista
})

const postListaError = ( error ) => ({
    type:NEW_LIST_ERROR,
    payload:error
})