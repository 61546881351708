import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { obtenerPlantilla } from '../../actions/plantillasActions';
import $ from 'jquery'

export const LayoutView = () => {

    const [LayoutId, setLayoutId] = useState(null);
    const dispatch = useDispatch()

    const loading = useSelector( state => state.plantillas.loading )
    const error = useSelector( state => state.plantillas.error )
    const plantillaSearch = useSelector( state => state.plantillas.layoutEdit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ plantilla, setPlantilla ] = useState({
        nombre:"",
        descripcion:""
    })

    const {nombre, descripcion, tipo} = plantilla

    useEffect( () => {
        // console.log('window.location.pathname',)
        const idTrozos = window.location.pathname.split('/');
        if(idTrozos.length == 3){
            setLayoutId(idTrozos[2]);
        }
        if(LayoutId && plantillaSearch === null){
            console.log('LayoutId',LayoutId)
            dispatch(obtenerPlantilla(LayoutId))
        }
        if(plantillaSearch){
            setPlantilla(plantillaSearch);
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[LayoutId,plantillaSearch,search_custom_page])

   

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Ver Plantilla</span>
            </div>
            
            <div className="col-md-4 ml-3">
                <div className="form-group">
                    <label>Nombre</label>
                    <input type="text" className="form-control-lg form-control" disabled name="nombre" value={nombre}/>
                </div>
                <div className="form-group">
                    <label>Descripción</label>
                    <textarea className="form-control-lg form-control"  disabled name="descripcion" value={descripcion}> </textarea>
                </div>
                <div className="form-group">
                    <label>Tipo</label>
                    <input type="text" className="form-control-lg form-control" disabled name="tipo" value={tipo}/>
                </div>
                <Link to="/layoutlist" className="colorsito">Volver</Link>
            </div>
        </div>
    )
}
