import React,{useEffect, useRef, useState} from 'react'
// import EmailEditor from 'react-email-editor';
import EmailEditor from './react-email-editor';
import { useSelector, useDispatch } from 'react-redux';
import { actualizarDiseno, getLandingById } from '../../actions/landingActions';
import { Link, useHistory, useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import { get_designs } from '../../actions/landingActions';

import Swal from 'sweetalert2';
import $ from 'jquery'
import clienteAxios from '../../config/axios';
import { BreadCrumbLanding } from '../../components/BreadCrumbLanding';

// import Modal from 'react-bootstrap4-modal';

export const DrawLanding = () => {
    let history = useHistory();

    const dispatch = useDispatch()
    const { id } = useParams()
    const [ modalVisible, setModalVisible ] = useState(false);

    const emailEditorRef = useRef();
    const [busco, setBusco] = useState(false);
    const [buscoDisenos, setBuscoDisenos] = useState(false);
    const landing = useSelector( state => state.landings.landingEdit )
    const designs = useSelector( state => state.landings.designs )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    useEffect(()=>{
        if(!buscoDisenos){
            dispatch(get_designs('sinhtml'))
            setBuscoDisenos(true);
        }
        if(!busco){

            dispatch(getLandingById(id))
            
            setBusco(true);

        }
        if(landing && landing.html && emailEditorRef && busco){
            const html = JSON.parse(landing.html);
            console.log('html',html)
            emailEditorRef.current?.editor?.loadDesign(html.design);
        }
        if(search_custom_page == null){
          $('#loading').hide();
        }
        
    },[landing,search_custom_page,designs])


    const cerrarVentana = () => {
      setModalVisible(false)

    }
    const exportHtml = async () => {
        emailEditorRef.current.editor.exportHtml( async (data) => {
          const { design, html } = data;
          await dispatch(actualizarDiseno(data,id))
          history.push(`/form_landing/${id}`)
          
        });
    };
  
    const onLoad = () => {
      
        if(landing && landing.html && emailEditorRef){
            const html = JSON.parse(landing.html);
            console.log('html',html)
            emailEditorRef.current?.editor?.loadDesign(html.design);
        }
    };
    const showTemplates = () => {
      setModalVisible(true)
      
    }
    const setTemplate = () => {
      cerrarVentana();
    }
    const handleClick = async (design) => {
      // console.log('design',design)
      const designQuery = await clienteAxios.get(`/lading_page/get_designs?gethtml=${design.id}`);
      console.log('designQuery.data',designQuery.data)
      if(designQuery.data.html){
        const html = JSON.parse(designQuery.data.html);
        // console.log('html',html)
        emailEditorRef.current?.editor?.loadDesign(html.design);

      }

    
    }
    return (
        <div style={{"paddingTop":"20px"}} className="white_box QA_section card_height_100">
                <BreadCrumbLanding active={2}/>

            <div className="form-group">
                <Link className="btn btn-link float-right colorsito" to="/landing_pages">Volver</Link>
                <button className="btn btn-success float-right" onClick={()=>exportHtml()}>Guardar diseño</button>
                <button className="btn btn-secondary float-right mr-2" onClick={()=>showTemplates()}>Cargar Plantilla Predefinida</button>
            </div>
            <div style={{ "clear":"both", "marginBottom":"20px"}}></div>
            <EmailEditor
                tools={{
                    video: {
                      enabled: true
                    },
                    form:{
                        properties:{
                            fields:{
                                editor: {
                                    data: {
                                      defaultFields: [{
                                        "name": "nombre",
                                        "type": "text",
                                        "label": "Nombre",
                                        "placeholder_text": "Introduce el nombre",
                                        "show_label": true,
                                        "required": true
                                      },{
                                        "name": "correo",
                                        "type": "email",
                                        "label": "Correo electrónico",
                                        "placeholder_text": "Introduce el Correo electrónico",
                                        "show_label": true,
                                        "required": true
                                      },{
                                        "name": "telefono",
                                        "type": "text",
                                        "label": "Teléfono",
                                        "placeholder_text": "Introduce el número telefono",
                                        "show_label": true,
                                        "required": true
                                      }, {
                                        "name": "razon_social",
                                        "type": "text",
                                        "label": "Razón social",
                                        "placeholder_text": "Introduce Razón social",
                                        "show_label": true,
                                        "required": true
                                      }, {
                                        "name": "web",
                                        "type": "text",
                                        "label": "Web",
                                        "placeholder_text": "Introduce dirección web",
                                        "show_label": true,
                                        "required": false
                                      }]
                                    }
                                  },
                                value:[{
                                    "name": "nombre",
                                    "type": "text",
                                    "label": "Nombre",
                                    "placeholder_text": "Introduce el nombre",
                                    "show_label": true,
                                    "required": true
                                  },{
                                    "name": "correo",
                                    "type": "email",
                                    "label": "Correo electrónico",
                                    "placeholder_text": "Introduce el Correo electrónico",
                                    "show_label": true,
                                    "required": true
                                  },{
                                    "name": "telefono",
                                    "type": "text",
                                    "label": "Teléfono",
                                    "placeholder_text": "Introduce el número telefono",
                                    "show_label": true,
                                    "required": true
                                  }, {
                                    "name": "razon_social",
                                    "type": "text",
                                    "label": "Razón social",
                                    "placeholder_text": "Introduce Razón social",
                                    "show_label": true,
                                    "required": true
                                  }, {
                                    "name": "web",
                                    "type": "text",
                                    "label": "Web",
                                    "placeholder_text": "Introduce dirección web",
                                    "show_label": true,
                                    "required": false
                                  }],
                            }
                        }
                    }
                }}
                ref={emailEditorRef}
                onLoad={onLoad}
                minHeight={`1100px`}
            />
            <Modal show={modalVisible}  onHide={cerrarVentana} animation={false} size="lg">
                <div className="modal-header">
                    <h5 className="modal-title naranja">Selecciona una plantilla</h5>
                  </div>
                  <div className="modal-body row">
                    {
                      designs && designs.length ? designs.map( design => (
                            <div className="col-md-3"><div className="col-md-12" key={design.id}>
                               
                                <div class="card hover" style={{"width": "10rem"}} key={design.id} onClick={ () => handleClick(design) }>
                                  <img class="card-img-top" src={`${process.env.REACT_APP_BACKEND}/lading_page/get_image_template/${design.id}`} alt={design.id}/>
                                  <div class="card-body">
                                    <h5 class="card-title">{design.categoria}</h5>
                                    <p class="card-text">{design.nombre}</p>
                                  </div>
                                </div>
                            </div> 
                               
                            </div>
                        )) : <span>Cargando diseños disponibles... ...</span>
                    }
            

                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                      Cerrar
                    </button>
                    <button type="button" onClick={ setTemplate } className="btn btn-primary">
                      Listo
                    </button>
                  </div>
            </Modal>
          
        </div>
    )
}
