import React, { useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { obtenerListaAction } from '../../actions/listasActions';

import { useSelector, useDispatch } from 'react-redux';
import {Link} from 'react-router-dom'
import $ from 'jquery'

import {obtenerContactosAction} from '../../actions/contactosActions';
import {obtenerClientesAction} from '../../actions/clientesActions';

// import { LoaderSpinner } from '../../components/LoaderSpinner';
import Select from 'react-select';

export const ViewMailList = () => {
    const dispatch = useDispatch()

    const listedit = useSelector( state => state.listas.listedit )
    const clientes = useSelector( state => state.clientes.clientes )
    const contactos = useSelector( state => state.contactos.contactos )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    // const loading = useSelector( state => state.listas.loading )

    const [ contactoOrigen, setContactoOrigen ] = useState('clientes');

    const [ listId, setListId ] = useState(null);
    const [ encontro, setEncontro ] = useState(false);
    const [ selected, setSelected ] = useState(null);
    const [ options, setOptions ] = useState([]);
    const [cliente,setCliente] = useState({
        tags:[],
        origen:[]
    });

    const [ lista, setList ] = useState({
        nombre:'',
        descripcion:'',
        tipo:'estatica',
        tags:[],
        origen:[],
        contactoCliente:"",
        creado:null
    });

   

    useEffect( async () => {
        const idTrozos = window.location.pathname.split('/');
        if(idTrozos.length === 3){
            setListId(idTrozos[2]);
        }
        if(listId){
            if(lista.nombre === ''){

                dispatch(obtenerListaAction(listId));
            }
        }
        if( listedit ){
            console.log('listedit',listedit)
            setList(listedit)
            setCliente({
                origen:listedit.origen,
                tags:listedit.tags
            })
            setContactoOrigen(listedit.contactoCliente)
            setEncontro(true)
        }
        if(lista.options && lista.options.length){
       
            setSelected(lista.options)
        }
        if(lista.contactoCliente === 'clientes'){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions( clientes.records?.map( cliente => ({ value : cliente.id, label : cliente.nombre } )))
        }else{
            await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions( contactos.records?.map( contacto => ({ value : contacto.id, label : contacto.nombre } )))
        }
        
if(search_custom_page == null){
    $('#loading').hide();
  }
  
    },[listId,listedit,contactoOrigen,search_custom_page])

    const handleChange = (e) => {
        setList({
            ...lista,
            [e.target.name] : e.target.value
        })
    }
    const handleChangeSelect = (e) => {
        console.log(e);
        setList({
            ...lista,
            options:e
        })
        setSelected(e)
    }

    const handleChecked = (e) => {
        console.log(e.target.value)
        setList({
            ...lista,
            tipo:e.target.value
        })
    }

    const handleCheckedContactosClientes = (e) => {
        console.log(e.target.value);
        setContactoOrigen(e.target.value)
    }



    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Ver lista</span>
                <form>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="form-group">
                                <labe>Nombre</labe>
                                <input type="text" value={lista.nombre} disabled className="form-control" onChange={handleChange} name="nombre"/>
                            </div>
                            <div className="form-group">
                                <labe>Descripción</labe>
                                <textarea className="form-control" onChange={handleChange} disabled value={lista.descripcion} name="descripcion"></textarea>
                            </div>
                            <div className="form-group">
                                <labe>Tipo de Lista</labe>
                                <div className="checkbox p-1 mt-4">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} disabled checked={lista.tipo === 'dinamica'}  value="dinamica"/> Dinámica
                                    </label>
                                </div>
                                <div className="checkbox p-1">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} disabled checked={lista.tipo !== 'dinamica'}  value="estatica"/> Estática
                                    </label>
                                </div>
                            </div>
                            {
                                lista.tipo == 'dinamica' ? 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen === 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen === 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <ReactTagInput 
                                            tags={cliente.tags} 
                                            placeholder="Ingrese el texto de los tags, y presione enter."
                                            onChange={(newTags) => {
                                                setCliente({ ...cliente, tags:newTags})
                                            }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Origen</label>
                                        <ReactTagInput 
                                            tags={cliente.origen} 
                                            placeholder="Ingrese el texto de los origenes, y presione enter."
                                            onChange={(newOrigen) => setCliente({ ...cliente, origen:newOrigen})}
                                        />
                                    
                                    </div>
                                </fieldset>
                                : 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" disabled name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" disabled name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    {/* <select className="form-control" name="clientes_contactos"></select> */}
                                    <Select
                                        readonly={true}
                                        isMulti
                                        value={selected}
                                        onChange={handleChangeSelect}
                                        options={options}
                                    />
                                </fieldset>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Link className="ml-3 colorsito" to="/maillist">Volver</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
