import {
    ADD_CAMPAIGN,
    ADD_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_ERROR,

    BEGIN_GETTING_CAMPAIGNS,
    GETTING_CAMPAIGNS_SUCCESS,
    GETTING_CAMPAIGNS_ERROR,

    BEGIN_GETTING_CAMPAIGN,
    GETTING_CAMPAIGN_SUCCESS,
    GETTING_CAMPAIGN_ERROR,

    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_ERROR,

    TRYING_ADDING_ELEMENT_LIST,
    ADDING_ELEMENT_LIST_SUCCESS,
    ADDING_ELEMENT_LIST_ERROR,

    TRYING_GET_ELEMENT_LIST,
    GET_ELEMENT_LIST_SUCCESS,
    GET_ELEMENT_LIST_ERROR,

    TRY_UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_DRAW_SUCCESS,
    UPDATE_CAMPAIGN_DRAW_ERROR,

    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_ERROR,

    TRY_SEND_CAMPAIGN,
    SEND_CAMPAIGN_SUCCESS,
    SEND_CAMPAIGN_ERROR,

    TRY_SENDTESTMAIL,
    SENDTESTMAIL_SUCCESS,
    SENDTESTMAIL_ERROR,

    TRY_GETSTATICS,
    GETSTATICS_SUCCESS,
    GETSTATICS_ERROR,

    TRY_CREATE_DESIGN,
    CREATE_DESIGN_SUCCESS,
    CREATE_DESIGN_ERROR,

    TRY_MARKETING_DESIGN,
    MARKETING_DESIGN_SUCCESS,
    MARKETING_DESIGN_ERROR,

    TRY_DELETE_DESIGN,
    DELETE_DESIGN_SUCCESS,
    DELETE_DESIGN_ERROR,

    TRY_SET_DESIGN

}
from '../types';

// cada reducer tiene su propio state

const initialState = {
    campaigns: [],
    newcampaign:null,
    campaignedit:null,
    campaignList:null,
    error:null,
    loading:false,
    statics:null,
    design:null,
    designs:[]
}

export default function (state = initialState, action){
    switch(action.type){
        case ADD_CAMPAIGN:
            return {
                ...state,
                loading:true,
                newcampaign:null,

            }
        case ADD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading:false,
                newcampaign:action.payload,
                campaignedit:action.payload
            }
        case ADD_CAMPAIGN_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload,
                newcampaign:null,

            }
        case BEGIN_GETTING_CAMPAIGNS:
            return {
                ...state,
                loading:true,
                campaigns:[],
                campaignedit:null,
                campaignList:null
            }
        case GETTING_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                loading:false,
                campaigns:action.payload
            }
        case GETTING_CAMPAIGNS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case BEGIN_GETTING_CAMPAIGN:
            return {
                ...state,
                loading:true,
                campaignedit:null
            }
        case GETTING_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading:false,
                campaignedit:action.payload
            }
        case GETTING_CAMPAIGN_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        
        case UPDATE_CAMPAIGN:
            return {
                ...state
            }
        case UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state
            }
        case UPDATE_CAMPAIGN_ERROR:
            return {
                ...state
            }

        case TRYING_ADDING_ELEMENT_LIST:
            return {
                ...state,
                loading:true,
            }
        case ADDING_ELEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case ADDING_ELEMENT_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRYING_GET_ELEMENT_LIST:
            return {
                ...state,
                loading:true
            }
        case GET_ELEMENT_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                campaignList:action.payload
            }
        case GET_ELEMENT_LIST_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRY_UPDATE_CAMPAIGN:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CAMPAIGN_DRAW_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case UPDATE_CAMPAIGN_DRAW_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case DELETE_CAMPAIGN:
            return {
                ...state,
                loading:true
            }
        case DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case DELETE_CAMPAIGN_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case TRY_SEND_CAMPAIGN:
            return {
                ...state,
                loading:true
            }
        case SEND_CAMPAIGN_SUCCESS:
            return {
                ...state,
                loading:false
            }
        case SEND_CAMPAIGN_ERROR:
            return {
                ...state,
                error:action.payload,
                loading:false
            }
        case TRY_SENDTESTMAIL:
        case SENDTESTMAIL_SUCCESS:
        case SENDTESTMAIL_ERROR:
            return {
                ...state
            }

        case TRY_GETSTATICS:
            return {
                ...state,
                statics:null
            }
        case GETSTATICS_SUCCESS:
            return {
                ...state,
                statics:action.payload
            }
        case GETSTATICS_ERROR:
            return {
                ...state,
                error:action.payload
            }
        case TRY_CREATE_DESIGN:
            return{
                ...state,
                loading:true,
                designs:[]
            }
        case CREATE_DESIGN_SUCCESS:
            return{
                ...state,
                loading:false,
                design:action.payload

            }
        case CREATE_DESIGN_ERROR:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
        case TRY_MARKETING_DESIGN:
            return {
                ...state,
                loading:true,
                design:null
            }
        case MARKETING_DESIGN_SUCCESS:
            return {
                ...state,
                loading:false,
                designs:action.payload
            }
        case MARKETING_DESIGN_ERROR:
            return {
                ...state,
                loading:false,
                design:null,
                error:action.payload
            }
        case TRY_DELETE_DESIGN:
            return{
                ...state,
                loading:true,
            }
        case DELETE_DESIGN_SUCCESS:
            return{
                ...state,
                loading:true,
            }
        case DELETE_DESIGN_ERROR:
            return{
                ...state,
                loading:true,
            }
        case TRY_SET_DESIGN:
            return{
                ...state,
                design:action.payload
            }
        default:
            return state;
    }
}