import React,{ useEffect, useState} from 'react'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'

import { guardarLanding,set_landing_variable_action } from '../../actions/landingActions';

import { obtenerUsuarios } from '../../actions/usersActions';
import $ from 'jquery';
import { BreadCrumbLanding } from '../../components/BreadCrumbLanding';

export const CreateLandingPage = () => {
    let history = useHistory();

    const loading = useSelector( state => state.contactos.loading )
    const landingsaved = useSelector( state => state.landings.landing )
    const users = useSelector( state => state.user.users )
    const currentUser = useSelector( state => state.auth.user )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [busco, setBusco] = useState(false);
    const [inicioUnaVez,setInicioUnaVez] =  useState(false);
    const dispatch = useDispatch()

    const [ landing, setLanding ] = useState({
        nombre : '',
        tipo : '',
        url : '',
        propietario : currentUser ? currentUser.id : null,
    });
    useEffect(async ()=>{
        if(!inicioUnaVez){
            dispatch(set_landing_variable_action());
            setInicioUnaVez(true);
        }
        if(!busco){
            await dispatch(obtenerUsuarios());
            dispatch(set_landing_variable_action());
            setBusco(true);
        }
        if(search_custom_page == null){
            $('#loading').hide();
        }
        if(landingsaved && landingsaved.id){

            history.push(`/draw_landing/${landingsaved.id}`)
        }
    },[search_custom_page,landingsaved])
    const handleChange = (e) => {
        if(e.target.name == 'url'){
            setLanding({
                ...landing,
                [e.target.name] : e.target.value.toLowerCase().split(' ').join('_')
            })
        }else{

            setLanding({
                ...landing,
                [e.target.name] : e.target.value
            })
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        await dispatch(guardarLanding(landing));
    }
    return (
        <div>
            <div className="p-4">
                <BreadCrumbLanding active={1}/>
                <span className="mr-3 naranja">Crear Landing Page</span>
                <Link to="/landing_pages" className="btn btn-link float-right" >  Volver</Link>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row pl-4">
                <div className="col-md-4">
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label>Nombre de landing page</label>
                            <input type="text" className="form-control-lg form-control" onChange={ handleChange } name="nombre"/>
                        </div>
                        <div className="form-group">
                            <label>Tipo de URL</label>
                            <select onChange={ handleChange } className="form-control-lg form-control" name="tipo">
                                <option value="">Seleccione</option>
                                <option value="sistema">Del sistema</option>
                                {
                                    currentUser && currentUser.currentPlan?.plan == 'Crece' ? <option value="propia">Dominio Propio</option> : null
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Link del landing</label>
                            <input type="text" className="form-control-lg form-control" value={landing.url} onChange={ handleChange } name="url"/>
                        </div>
                        <div className="form-group">
                            <label>Propietario</label>
                            <select onChange={ handleChange } className="form-control-lg form-control" value={landing.propietario} name="propietario">
                                <option value="">Seleccione</option>
                                {
                                    users && users.length && users.map( user => {
                                            return <option value={user.id}>{user.email}</option>
                                    })
                                }
                            </select>
                        </div>
                        <input type="submit" value="Crear" className="btn btn-success"/>
                    </form>
                </div>
            </div>
        </div>
    )
}
