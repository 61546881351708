import React, { useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { Link, useHistory } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { crearNuevoCliente } from '../../actions/clientesActions';
import { obtenerContactosAction } from '../../actions/contactosActions';
import { obtenerClientsFieldsAction } from '../../actions/customFieldsActions';

import $ from 'jquery'


export const NewClient = () => {
    let history = useHistory();
    const dispatch = useDispatch()
    const [botonCrear, setBotonCrear] = useState(true);
    const loading = useSelector(state => state.clientes.loading)
    const error = useSelector(state => state.clientes.error)
    const newclient = useSelector(state => state.clientes.newclient)
    const contactos = useSelector(state => state.contactos.contactos)
    const search_custom_page = useSelector(state => state.landings.search_custom_page)

    const user = useSelector(state => state.auth.user)
    const clientsfields = useSelector(state => state.customFields.clientsfields.normal)
    const clientsfieldsCustom = useSelector(state => state.customFields.clientsfields.custom)
    useEffect(() => {
        console.log('clientsfields', JSON.stringify(clientsfields))
    }, [clientsfields])
    // Función para encontrar el campo por nombre
    const getField = (fieldName) => {
        return clientsfields.find(field => field.name.toLowerCase() === fieldName.toLowerCase() || field.custom_name?.toLowerCase() === fieldName.toLowerCase());
    };
    useEffect(async () => {
        await dispatch(obtenerContactosAction({ paginate: true, stringparams: `page=0&limit=10&size=10` }));
        await dispatch(obtenerClientsFieldsAction());
        if (error) {
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: 'Ocurrio un error creando el cliente.'
            });
            return;
        }
        console.log('newclient', newclient)
        if (newclient !== null) {
            Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: 'El cliente ha sido agregado con exito!'
            });
            return;
        }
        if (user) {
            console.log('user', user)
            setCliente({
                ...cliente,
                creador: user.id
            })
        }
        console.log('contactos.length', contactos.length)
        if (contactos.length == 0) {
            setBotonCrear(false)
        }
        if (search_custom_page == null) {
            $('#loading').hide();
        }
    }, [error, newclient, user, search_custom_page])

    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        creador: user,
        razon_social: '',
        mail: [],
        telefono: [],
        web: '',
        comentario: '',
        estado: '',
        visibilidad: '',
        tags: [],
        origen: [],
        contact_id: null
    });

    const handleChange = (e) => {
        setCliente({
            ...cliente,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const requiredFields = clientsfields.filter(field => field.required);
        const fieldErrors = requiredFields.map(field => {
            const fieldName = field.custom_name || field.name;
            let fieldKey = fieldName.toLowerCase().replace(' ', '_');
            let fieldValue = cliente[fieldKey];
            if (fieldKey === 'email') {
                fieldValue = cliente['mail'];
            } else if (fieldKey === 'telefono') {
                fieldValue = cliente['telefono'];
            } else if (fieldKey === 'tags') {
                fieldValue = cliente['tags'];
            } else if (fieldKey === 'origen') {
                fieldValue = cliente['origen'];
            }

            console.error(fieldKey, fieldValue, cliente);

            // Verificar si el campo es un array y está vacío
            if (Array.isArray(fieldValue)) {
                if (fieldValue.length === 0) {
                    return fieldName;
                }
            } else {
                if (!fieldValue) {
                    return fieldName;
                }
            }

            return null;
        }).filter(error => error !== null);

        if (fieldErrors.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: `Los siguientes campos son requeridos: ${fieldErrors.join(', ')}`
            });
            return;
        }

        // Enviar
        await dispatch(crearNuevoCliente(cliente));
        history.push('/clients');
    };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.error('clientsfields 1', clientsfields);

    //     const requiredFields = clientsfields.filter(field => field.required);
    //     const fieldErrors = requiredFields.map(field => {
    //         const fieldName = field.custom_name || field.name;
    //         let fieldKey = fieldName.toLowerCase().replace(' ', '_');
    //         let fieldValue = cliente[fieldKey];
    //         if (fieldKey == 'email') {
    //             fieldValue = cliente['mail'];
    //         }
    //         console.error(fieldKey, fieldValue, cliente)
    //         // Verificar si el campo es un array y está vacío
    //         if (Array.isArray(fieldValue)) {
    //             if (fieldValue.length === 0) {
    //                 return fieldName;
    //             }
    //         } else {
    //             if (!fieldValue) {
    //                 return fieldName;
    //             }
    //         }

    //         return null;
    //     }).filter(error => error !== null);

    //     if (fieldErrors.length > 0) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Hubo un error',
    //             text: `Los siguientes campos son requeridos: ${fieldErrors.join(', ')}`
    //         });
    //         return;
    //     }

    //     // Enviar
    //     await dispatch(crearNuevoCliente(cliente));
    //     history.push('/clients');
    // };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // validar
    //     if(clientsfields && clientsfields.length && clientsfields[2].required && cliente.mail.length === 0){
    //         Swal.fire({
    //             icon:'error',
    //             title:'Hubo un error',
    //             text:`El campo ${clientsfields[2].custom_name ? clientsfields[2].custom_name : clientsfields[2].name} es requerido`
    //         });
    //         return;
    //     }
    //     if(clientsfields && clientsfields.length && clientsfields[3].required && cliente.telefono.length === 0){
    //         Swal.fire({
    //             icon:'error',
    //             title:'Hubo un error',
    //             text:`El campo ${clientsfields[3].custom_name ? clientsfields[3].custom_name : clientsfields[3].name} es requerido`
    //         });
    //         return;
    //     }
    //     if(clientsfields && clientsfields.length && clientsfields[6].required && cliente.tags.length === 0){
    //         Swal.fire({
    //             icon:'error',
    //             title:'Hubo un error',
    //             text:`El campo ${clientsfields[6].custom_name ? clientsfields[6].custom_name : clientsfields[6].name} es requerido`
    //         });
    //         return;
    //     }
    //     if(clientsfields && clientsfields.length && clientsfields[7].required && cliente.origen.length === 0){
    //         Swal.fire({
    //             icon:'error',
    //             title:'Hubo un error',
    //             text:`El campo ${clientsfields[7].custom_name ? clientsfields[7].custom_name : clientsfields[7].name} es requerido`
    //         });
    //         return;
    //     }
    //     //  enviar

    //     await dispatch(crearNuevoCliente(cliente))

    //     history.push('/clients')
    // }

    // const handleChangeCustom = (e) => {
    //     console.log('option.selected',e.target.options.selected)
    //     const updatedOptions = [...e.target.options]
    //     .filter(option => option.selected)
    //     .map(x => x.value);
    //   console.log("updatedOptions", updatedOptions);
    //   setCliente({
    //       ...cliente,
    //       [e.target.name]:updatedOptions
    //   });
    // }
    const handleChangeCustom = (e) => {
        const daticos = e.target.name.replace(/\[|\]/gi, '').split('custom')[1];
        console.log('daticos', daticos)
        if (e.target.type == 'text') {

            setCliente({
                ...cliente,
                custom: {
                    ...cliente.custom,
                    [daticos]: e.target.value
                }
            });
        } else {

            console.log('option.selected', e.target.options.selected)
            const updatedOptions = [...e.target.options]
                .filter(option => option.selected)
                .map(x => x.value);
            console.log("updatedOptions", updatedOptions);
            setCliente({
                ...cliente,
                custom: {
                    ...cliente.custom,
                    [daticos]: updatedOptions
                }
            });
        }
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Nuevo Cliente</span>
            </div>
            {
                loading ? <LoaderSpinner /> : null
            }
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Nombre')?.custom_name) ? getField('Nombre').custom_name : "Nombre"}</label>
                        <input type="text" required={(clientsfields && clientsfields.length && getField('Nombre')?.required) ? true : false} value={cliente.nombre} onChange={handleChange} className="form-control-lg form-control" name="nombre" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Apellido')?.custom_name) ? getField('Apellido').custom_name : "Apellido"}</label>
                        <input type="text" required={(clientsfields && clientsfields.length && getField('Apellido')?.required) ? true : false} value={cliente.apellido} onChange={handleChange} className="form-control-lg form-control" name="apellido" />
                    </div>
                    <div className="form-group">
                        <label>Creador</label>
                        <input type="text" readOnly value={user?.name} className="form-control-lg form-control" name="creador" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Razón Social')?.custom_name) ? getField('Razón Social').custom_name : "Razón Social"}</label>
                        <input type="text" required={(clientsfields && clientsfields.length && getField('Razón Social')?.required) ? true : false} value={cliente.razon_social} onChange={handleChange} className="form-control-lg form-control" name="razon_social" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Email')?.custom_name) ? getField('Email').custom_name : "Email"}</label>
                        <ReactTagInput
                            tags={cliente.mail}
                            placeholder="Ingrese el email, y presione enter."
                            onChange={(newMails) => setCliente({ ...cliente, mail: newMails })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Teléfono')?.custom_name) ? getField('Teléfono').custom_name : "Teléfono"}</label>
                        <ReactTagInput
                            tags={cliente.telefono}
                            placeholder="Ingrese el telefono, y presione enter."
                            className="form-control-lg"
                            onChange={(newtlfs) => setCliente({ ...cliente, telefono: newtlfs })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Web')?.custom_name) ? getField('Web').custom_name : "Web"}</label>
                        <input type="text" required={(clientsfields && clientsfields.length && getField('Web')?.required) ? true : false} value={cliente.web} onChange={handleChange} className="form-control-lg form-control" name="web" />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Comentario')?.custom_name) ? getField('Comentario').custom_name : "Comentario"}</label>
                        <textarea required={(clientsfields && clientsfields.length && getField('Comentario')?.required) ? true : false} value={cliente.comentario} onChange={handleChange} className="form-control-lg form-control" name="comentario"></textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Tags')?.custom_name) ? getField('Tags').custom_name : "Tags"}</label>
                        <ReactTagInput
                            tags={cliente.tags}
                            placeholder="Ingrese el texto de los tags, y presione enter."
                            onChange={(newTags) => setCliente({ ...cliente, tags: newTags })}
                        />
                    </div>
                    <div className="form-group">
                        <label>{(clientsfields && clientsfields.length && getField('Origen')?.custom_name) ? getField('Origen').custom_name : "Origen"}</label>
                        <ReactTagInput
                            tags={cliente.origen}
                            placeholder="Ingrese el texto de los origenes, y presione enter."
                            onChange={(newOrigen) => setCliente({ ...cliente, origen: newOrigen })}
                        />
                    </div>
                    <div className="form-group" style={{ 'display': 'none' }}>
                        <label>{(clientsfields && clientsfields.length && getField('Estado')?.custom_name) ? getField('Estado').custom_name : "Estado"}</label>
                        <select required={(clientsfields && clientsfields.length && getField('Estado')?.required) ? true : false} value={cliente.estado} className="form-control-lg form-control" onChange={handleChange} name="estado">
                            <option value="">Seleccione</option>
                            <option value="activo" selected>Activo</option>
                            <option value="bloqueado">Bloqueado</option>
                            <option value="papelera">Papelera</option>
                        </select>
                    </div>
                    <div className="form-group" style={{ 'display': 'none' }}>
                        <label>{(clientsfields && clientsfields.length && getField('Visibilidad')?.custom_name) ? getField('Visibilidad').custom_name : "Visibilidad"}</label>
                        <select required={(clientsfields && clientsfields.length && getField('Visibilidad')?.required) ? true : false} value={cliente.visibilidad} className="form-control-lg form-control" onChange={handleChange} name="visibilidad">
                            <option value="">Seleccione</option>
                            <option value="admin" selected>Admin</option>
                        </select>
                    </div>
                    {
                        clientsfieldsCustom && clientsfieldsCustom.length ? clientsfieldsCustom.map(input => (
                            <div className="form-group">
                                <label>{input.name}</label>
                                {
                                    input.type !== 'text' ?
                                        input.type === 'select' ?
                                            <select required={input.required ? true : false} options={input.options} value={cliente[input.name]} className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom][" + input.name + "]"}>
                                                <option>Seleccione</option>
                                                {
                                                    input.options?.map((option, i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                            :
                                            <select required={input.required ? true : false} options={input.options} value={cliente[input.name]} multiple className="form-control-lg form-control" onChange={handleChangeCustom} name={"[custom][" + input.name + "]"}>
                                                {
                                                    input.options?.map((option, i) => (
                                                        <option key={i} value={option}>{option}</option>
                                                    ))
                                                }
                                            </select>
                                        : <input type="text" onChange={handleChangeCustom} className="form-control-lg form-control" name={"[custom][" + input.name + "]"} />
                                }
                            </div>
                        )) : null
                    }
                </div>
                <div className="row mb-5">
                    <div className="col-md-4">
                        <input type="submit" className="btn btn-success" value="Crear" />
                        <Link to="/clients" className="p-2 colorsito">Volver</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}
