import clienteAxios from './axios';

const tokenAuth = token =>{
    if(token){
        clienteAxios.defaults.headers['x-access'] = token;
    }else{
        delete clienteAxios.defaults.headers['x-access'];
    }
}

export default tokenAuth;