import React,{ useEffect, useState } from 'react'

import { obtenerUsuarios, deleteAccount } from '../../actions/usersActions';
import { Link, Redirect } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from 'react-redux';

import moment from "moment"
import 'moment/locale/es';




import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';


import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';




export const AdminAccount = () => {

    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const users = useSelector( state => state.user.users )
    let auth = useSelector( state => state.auth.user )

    const currentUserAuthenticated = useSelector( state => state.auth.user )

    useEffect( async ()=> {
        await dispatch(obtenerUsuarios());
        if(users){
            // $(document).ready(function () {
            //     $('.table').DataTable({
            //         language:spanish
            //     });
            // });
        } 
        if(currentUserAuthenticated){
            if(currentUserAuthenticated.roles[0].name != 'impulsa'){
                return <Redirect to="/" />
            }
        }
    },[])

    const handleDelete = async (id) => {
        await dispatch(deleteAccount(id));
        await dispatch(obtenerUsuarios());
    }
  
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (event) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }
      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
      };

      // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
        <div className="p-4" className="white_box QA_section card_height_100">
            <span className="mr-3 naranja">Administración de Cuentas</span>
            <Link to="/new_account" className="btn btn-primary float-right" ><span className="fas fa-plus "></span>   Crear Cuenta</Link>
            <Link to="/with_impulsa" className="btn btn-link float-right" > Ingresar a cuenta como impulsa</Link>
            <div class="table-responsive dataTables_wrapper no-footer">
                

                <TableContainer component={Paper} className="mt-3">
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead >
                        <TableRow >
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}}>Email</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Nombre Cuenta</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Nombre</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Teléfono</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Rol</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Estado</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Database</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} align="right">Creada</StyledTableCell>
                        <StyledTableCell style={{"backgroundColor":"#F2F9FF","color":"#d88603"}} width="150px"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                        ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : users
                        ).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                            {row.email}
                            </TableCell>
                            <TableCell  align="right">
                            {row.nombre_empresa}
                            </TableCell>
                            <TableCell  align="right">
                            {row.nombre}
                            </TableCell>
                            <TableCell  align="right">
                            {row.telefono}
                            </TableCell>
                            <TableCell  align="right">
                            {row.rol}
                            </TableCell>
                            <TableCell  align="right">
                            {row.isActive == '1' ? <span className='badge badge-success'>Activo</span> : <span className='badge badge-danger'>Inactivo</span>}
                            </TableCell>
                            <TableCell  align="right">
                            {row.database}
                            </TableCell>
                            <TableCell  align="right">
                            {moment(row.createdAt).fromNow()}
                            </TableCell>
                            
                            <TableCell  align="right">
                                <div className="input-group">
                                    <span onClick={ () => handleDelete(row.id) } className="btn btn-danger btn-sm"><i className="nav-icon fas fa-remove"></i></span>
                                    <Link to={`/edit_user/${row.id}`} className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit"></i></Link>
                                </div>
                            </TableCell>
                        </TableRow>
                        ))}

                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={3}
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        </TableRow>
                    </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
