import React,{ useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Modal from 'react-bootstrap4-modal';

import { obtenerCampaign, getElementosAlista, sendCampaign, sendTestEmail } from '../../actions/campaignsActions';

import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import $ from 'jquery'
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';

export const View = () => {

    const dispatch = useDispatch()

    const { idCampaign } = useParams()
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ testEmails, setTestEmails ] = useState("");

    const campaignedit = useSelector( state => state.campaigns.campaignedit )
    const campaignList = useSelector( state => state.campaigns.campaignList )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ htmltoCamp , setHtmlToCamp] = useState('');
    const loading = useSelector( state => state.campaigns.loading )
    const [designtype, setDesigntype] = useState('');
    const cerrarVentana = () => {
        setModalVisible(false);

    }
    useEffect(async () => {
        await dispatch(obtenerCampaign(idCampaign));
        await dispatch(getElementosAlista(idCampaign));
        if(campaignedit && designtype == '' && campaignedit.contenido){
            let content = JSON.parse(campaignedit.contenido)
            // if(JSON.parse(campaignedit.contenido).html){
            //     setHtmlToCamp(JSON.parse(campaignedit.contenido).html);
            //     setHtmlToCamp($(htmltoCamp).find("#image_to_track").html());
            // }
            if(typeof content.html != 'undefined'){
                setDesigntype('html')
            }else{
                setDesigntype('text')
            }
            console.log('designtype',designtype)
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[designtype,search_custom_page])


    const send = async (id) => {
        if(campaignedit && campaignedit.lista){
            
            //console.log(campaignedit);
            if(campaignedit.sending >= 1){
                let html = ``;
                Object.keys(campaignedit.lista).forEach(key => {
                    html += `Para evitar datos duplicados, no puede volver a enviar la campaña.`;
                });
                Swal.fire({
                    html,
                    title: 'La campaña ya fue enviada',
                    showDenyButton: true,
                    denyButtonText: 'OK',
                    showConfirmButton: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // Swal.fire('Saved!', '', 'success')
                        //await dispatch(sendCampaign(id));
                    }
                });
            }else{
                let html = ``;
                Object.keys(campaignedit.lista).forEach( key => {
                    html += `Será enviado a <b>${campaignedit.lista[key]}</b> correos de la lista '<b>${key}</b>'`
                })
                Swal.fire({
                    html,
                    title: '¿Está seguro desea enviar este mail?',
                    showDenyButton: true,
                    confirmButtonText: 'Enviar',
                }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // Swal.fire('Saved!', '', 'success')
                        await dispatch(sendCampaign(id))
                    }
                })
            }
            
        }
        
    }

    const sendtMail = (id) => {
        setModalVisible(true);

    }

    const enviarEmailPrueba = async () =>  {
        await dispatch(sendTestEmail({
            testEmails,
            campaignid:campaignedit.id
        }))
    }

    const handleTestMails = (e) => {
        setTestEmails(e.target.value)
    }

   
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbCampaign active={5}/>

                <span className="mr-3 naranja">Vista de Campa&ntilde;a "{campaignedit && campaignedit.nombre}"</span>
                <Link to="/mail_marketing" className="btn btn-link float-right colorsito" >Volver</Link>
                <button onClick={()=>send(campaignedit.id)} className="btn btn-success float-right" >Enviar</button>
                <button onClick={()=>sendtMail(campaignedit.id)} className="btn btn-secondary float-right mr-2" >Enviar email de prueba</button>
                {
                    loading ? <LoaderSpinner/> : null
                }
            </div>
            <div className="row mb-5">
                <div className="col-md-4">
                <div className="panel panel-default custom-panel">
                    <div className="panel-body"></div>
                    <div className="list-group list-group-custom list-group-min-padding list-group-object-info">
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Creada por</p>
                            <p className="list-group-item-text">{campaignedit && campaignedit.creado?.nombre}</p>
                        </div>
                        
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Listas</p>
                            <p className="list-group-item-text">
                                {
                                    campaignList && campaignList.arrList && campaignList.arrList.length && campaignList.arrList.map( (l,index) => (
                                        l && <Link key={index} className="marketing-list-contacts ml-2" style={{'display':"block"}} to={`/view_maillist/${l.id}`}>{l.nombre}</Link>
                                    ))
                                }
                                
                            </p>
                        </div>
                        
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Tipo</p>
                            <p className="list-group-item-text">{designtype}</p>
                        </div>
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Asunto</p>
                            <p className="list-group-item-text">{campaignedit && campaignedit.asunto}</p>
                        </div>
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Nombre de remitente</p>
                            <p className="list-group-item-text">{campaignedit && campaignedit.remitente}</p>
                        </div>
                        <div className="list-group-item">
                            <p className="list-group-item-heading">Email remitente</p>
                            <p className="list-group-item-text">{campaignedit && campaignedit.email_remitente}</p>
                        </div>
                        
                    </div>
                    </div>
                </div>
                
                <div className="col-md-8">
                    {   
                    campaignedit && campaignedit.contenido? 
                    <div id="myCustomContent" dangerouslySetInnerHTML={{__html: JSON.parse(campaignedit.contenido).html ? JSON.parse(campaignedit.contenido).html : JSON.parse(campaignedit.contenido)}}></div>
                    :null
                    }
                </div>
            </div>
            <Modal visible={modalVisible} >
                    <div className="modal-header">
                        <h5 className="modal-title">Introduzca la dirección de correo</h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Ingrese la/las direcciones de correo</label>
                            <input type="text" className="form-control" value={testEmails} onChange={handleTestMails}  placeholder="email1@gmail.com, email2@gmail.com" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                        Cerrar
                        </button>
                        <button type="button" onClick={ enviarEmailPrueba } className="btn btn-primary">
                        Enviar
                        </button>
                    </div>
                    </Modal>
        </div>
    )
}
