import React, { useRef, useState, useEffect } from 'react'
import EmailEditor from '../lading_pages/react-email-editor';
import { Link, useHistory } from 'react-router-dom'
import Modal from 'react-bootstrap4-modal';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { v4 as uuidv4 } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../screens/campaigns/textEditor.css';

import { guardarDiseno, obtenerDisenoLista, guardarBasico } from '../../actions/listasActions';
import { crearPlantillaAction } from '../../actions/plantillasActions';
import $ from 'jquery'

import { useSelector, useDispatch } from 'react-redux';

export const Editor = () => {
    let history = useHistory();
    const layoutEdit = useSelector( state => state.plantillas.layoutEdit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const emailEditorRef = useRef();
    const dispatch = useDispatch()

    const idTrozos = window.location.pathname.split('/');
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ ckeditorData, setCkeditorData ] = useState();
    const [ obtuvo, setObtuvo] = useState(false);
    const [ disenoLista, setDisenoLista ] = useState({
      id:null,
      html:null,
      design:null
    })

    const loading = useSelector( state => state.listas.loading )
    const draw = useSelector( state => state.listas.draw )
    const error = useSelector( state => state.listas.error )
    const layoutname = useSelector( state => state.listas.layoutname )
    


    const [ diseno, setDiseno ] = useState({
      id:uuidv4(),
      _name:null,
      type:null,
      html:null,
      design:null
    })

    useEffect( async () => {
      if(obtuvo === false){
        await dispatch(obtenerDisenoLista(layoutname))
        setObtuvo(true)
        
      }
      if(layoutEdit && layoutEdit.contenido && layoutEdit.tipo != 'texto'){
        setTimeout(() => {
          if(emailEditorRef && emailEditorRef.current && emailEditorRef.current?.editor){

            emailEditorRef.current?.editor.loadDesign(layoutEdit.contenido.design);
          }
        },2000)
      }
      if(search_custom_page == null){
        $('#loading').hide();
      }
      console.log('search_custom_page',search_custom_page)
    },[draw,search_custom_page])

    const exportHtml = async () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;
            
            let layoutContenido = layoutEdit;
            layoutContenido.contenido = data;
    
            //FormData
            const formData = new FormData();
            if (layoutContenido.id) {
                formData.append('id', layoutContenido.id);
            }
            formData.append('nombre', layoutContenido.nombre); 
            formData.append('descripcion', layoutContenido.descripcion);
            formData.append('tipo', layoutContenido.tipo);
            formData.append('contenido', JSON.stringify(layoutContenido.contenido));
            if (layoutContenido.image_preview) {
                formData.append('image_preview', layoutContenido.image_preview);
            }
            await dispatch(crearPlantillaAction(formData));
            history.push('/layoutlist');
        });
    };
  

    const handleChange = (e) => {
      setDiseno({
        ...diseno,
        [e.target.name] : e.target.value
      })
    }
    const cerrarVentana = () => {
      setModalVisible(false)

    }
    const guardarDisenoSubmit = (e) => {
      if(diseno.type === null || diseno._name === null){
              //alerta de error
     
        Swal.fire({
          icon:'error',
          title:'Hubo un error',
          text:'Los campos para agregar el diseño son obligatorios'
        })
        return;
      }
      dispatch(guardarBasico(diseno));
      setModalVisible(false)

    }
    
    const guardarALaLista = (e) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        // console.log('exportHtml', html);
        setDisenoLista({
          id:idTrozos[2],
          design,
          html
        })

        dispatch(guardarDiseno(disenoLista))
        setModalVisible(false)
        
      });
      console.log(idTrozos[2])
    }
    const onLoad = () => {
      // you can load your template here;
      // const templateJson = draw;
      // console.log('mailEditorRef.current',mailEditorRef.current)
      if(draw && emailEditorRef && emailEditorRef.current ){
        
        emailEditorRef.current?.editor.loadDesign(draw.design);
      }
    };
    const guardar = async () => {
        let layoutContenido = layoutEdit;
        layoutContenido.contenido = ckeditorData;
    
        //FormData
        const formData = new FormData();

        if (layoutContenido.id) {
            formData.append('id', layoutContenido.id);
        }
        formData.append('nombre', layoutContenido.nombre); 
        formData.append('descripcion', layoutContenido.descripcion);
        formData.append('tipo', layoutContenido.tipo);
        formData.append('contenido', JSON.stringify(layoutContenido.contenido));
        if (layoutContenido.image_preview) {
            formData.append('image_preview', layoutContenido.image_preview);
        }
        await dispatch(crearPlantillaAction(formData));

        // redirecciona
        history.push('/layoutlist');
    };
  
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Editor</span>
                <Link to={`/layoutlist`} className="float-right p-2 mr-4 colorsito">Volver a la lista</Link>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div>
            </div>
            {
              layoutEdit && layoutEdit.tipo == 'texto' ? 
                <>
                  <CKEditor
                      editor={ ClassicEditor }
                      data={(layoutname && layoutname.contenido) ? layoutname.contenido : "<p>Escribe Aqui</p>"}
                    
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setCkeditorData(data)
                          console.log( { event, editor, data } );
                      } }
                      onBlur={ ( event, editor ) => {
                          console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          console.log( 'Focus.', editor );
                      } }
                  />
                  <button className="btn btn-success mt-3" onClick={guardar}>Guardar</button>
                </>
              :
              <>
                <EmailEditor
                                      tools={{
                                        form:{
                                          enabled:false
                                        }
                                      }}
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  minHeight={`1100px`}
                />
                <Modal visible={modalVisible} >
                  <div className="modal-header">
                    <h5 className="modal-title">Guardar diseño</h5>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Nombre</label>
                      <input type="text" name="_name" className="form-control" value={diseno._name} onChange={handleChange}/>
                    </div>
                    <div className="form-group">
                      <label>Tipo</label>
                      <select onChange={handleChange} name="type" className="form-control" value={diseno.type}>
                        <option value="">Seleccione</option>
                        <option value="basic">Diseño basico</option>
                        <option value="theme">Tema</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                      Cerrar
                    </button>
                    <button type="button" onClick={ guardarDisenoSubmit } className="btn btn-primary">
                      Guadar
                    </button>
                  </div>
                </Modal>
                <button onClick={exportHtml} className="btn btn-success ml-3 mt-4 mb-5" data-toggle="modal" data-target="#exampleModal">Guardar</button>
              </>
            }

            {/* <button onClick={guardarALaLista} className="btn btn-primary ml-3 mt-4 mb-5">Guardar</button> */}
      </div>
    )
}
