import React from 'react'

export const BreadCrumbCampaign = ({active}) => {
  return (
    <div className="container-nav mb-5 bread_crumb_campaign">
            <div className="line-crumb"></div>
        <ul className="steps-progress-bar">
            <li id="first-step" className={`steps` + (active == 1 ? ' active' : '')}>
                <p className="circle">1</p> <p className="nameCrumb">Crear Campaña</p>
            </li>
            <li id="second-step" className={`steps` + (active == 2 ? ' active' : '')}>
                <p className="circle">2</p> <p className="nameCrumb">Lista</p>
            </li>
            <li id="third-step" className={`steps` + (active == 3 ? ' active' : '')}>
            <p className="circle">3</p> <p className="nameCrumb">Diseño</p>
            </li>
            <li id="fourth-step" className={`steps` + (active == 4 ? ' active' : '')}>
                <p className="circle">4</p>   <p className="nameCrumb">Editor</p>
            </li>
            <li id="fourth-step" className={`steps` + (active == 5 ? ' active' : '')}>
                <p className="circle">5</p>   <p className="nameCrumb">Resumen</p>
            </li>
        </ul>
    </div>
  )
}
