import React, { useState, useEffect } from 'react'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {actualizarListaAction, obtenerListaAction } from '../../actions/listasActions';

import $ from 'jquery'

import { useSelector, useDispatch } from 'react-redux';
import {Link} from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Select from 'react-select';
import {obtenerContactosAction} from '../../actions/contactosActions';
import {obtenerClientesAction} from '../../actions/clientesActions';
import AsyncSelect from 'react-select/async';


export const EditMailList = () => {
    const dispatch = useDispatch()

    const listedit = useSelector( state => state.listas.listedit )
    const loading = useSelector( state => state.listas.loading )
    const user = useSelector( state => state.user.user )
    const clientes = useSelector( state => state.clientes.clientes )
    const contactos = useSelector( state => state.contactos.contactos )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ contactoOrigen, setContactoOrigen ] = useState('clientes');
    const [ encontro, setEncontro ] = useState(false);
    const [ listId, setListId ] = useState(null);
    const [ selected, setSelected ] = useState(null);
    const [ options, setOptions ] = useState([]);
    const [ cambiando, setCambiando ] = useState(null);


    const [cliente,setCliente] = useState({
        tags:[],
        origen:[]
    });

    let [ lista, setList ] = useState({
        id:null,
        nombre:'',
        descripcion:'',
        tipo:'estatica',
        tags:[],
        origen:[],
        contactoCliente:"",
        creado:user,
        options:null
    });

    const handleChange = (e) => {
        setList({
            ...lista,
            [e.target.name] : e.target.value
        })
    }


    const handleChecked = (e) => {
        console.log(e.target.value)
        setList({
            ...lista,
            tipo:e.target.value
        })
    }
 
    const handleCheckedContactosClientes = (e) => {
        setCambiando(true);
        setSelected([]);
        setContactoOrigen(e.target.value)
        setList({
            ...lista,
            contactoCliente:e.target.value
        })
        console.log('e.target.value',e.target.value)
        console.log('lista',lista)

    }
    const handleChangeSelect = (e) => {
        setList({
            ...lista,
            options:e
        })
        setSelected(e)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
 
        if(lista.tipo != 'estatica'){
            const tags = [...cliente.tags]
            const origen = [...cliente.origen]
            const contactoCliente = contactoOrigen;
            
        }
        
        
        // return;
        dispatch(actualizarListaAction(lista));

        setList({
            nombre:'',
            descripcion:'',
            tipo:'estatica',
            tags:[],
            origen:[],
            contactoCliente:"",
            creado:null
        })

    }

    useEffect( async () => {

       

        const idTrozos = window.location.pathname.split('/');
        if(idTrozos.length == 3){
            setListId(idTrozos[2]);
        }
        if(listId){
            if(lista.nombre === ''){
                setList({
                    ...lista,
                    id:listId
                })
                dispatch(obtenerListaAction(listId));
                
            }
        }
        if(lista.options && lista.options.length && !cambiando){
       
            setSelected(lista.options)
        }
        if( listedit ){
            
            console.log('listedit',listedit)
            setList(listedit)
            setCliente({
                origen:listedit.origen,
                tags:listedit.tags
            })
            setEncontro(true)
            setContactoOrigen(listedit.contactoCliente)

        }


        setContactoOrigen(
            lista.contactoCliente
        )
        if(lista.contactoCliente === 'clientes'){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions( clientes.records?.map( cliente => ({ value : cliente.id, label : cliente.nombre } )))
        }else{
            await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10`}))
            setOptions( contactos.records?.map( contacto => ({ value : contacto.id, label : contacto.nombre } )))
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
          
    },[listId,listedit,search_custom_page,user])
    const getOptions = async (input) => {
        if(contactoOrigen === 'clientes'){
            await dispatch(obtenerClientesAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${input}`}))
            return clientes?.records.map( cliente => ({ value : cliente.id, label : cliente.nombre } ))
        }else{
            await dispatch(obtenerContactosAction({paginate:true,stringparams:`page=0&limit=10&size=10&q=${input}`}))
            return contactos?.records?.map( contacto => ({ value : contacto.id, label : contacto.nombre } ))
        }
    }
    return (
        <div>
            <div className="p-4">
                <span className="mr-3 naranja">Actualizar lista</span>
                <form onSubmit={ handleSubmit }>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="form-group">
                                <labe>Nombre</labe>
                                <input type="text" value={lista.nombre} className="form-control" onChange={handleChange} name="nombre"/>
                            </div>
                            <div className="form-group">
                                <labe>Descripción</labe>
                                <textarea className="form-control" onChange={handleChange} value={lista.descripcion} name="descripcion"></textarea>
                            </div>
                            <div className="form-group">
                                <labe>Tipo de Lista</labe>
                                <div className="checkbox p-1 mt-4">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} checked={lista.tipo == 'dinamica'}  value="dinamica"/> Dinámica
                                    </label>
                                </div>
                                <div className="checkbox p-1">
                                    <label>
                                        <input type="radio" name="tipo" onChange={handleChecked} checked={lista.tipo != 'dinamica'}  value="estatica"/> Estática
                                    </label>
                                </div>
                            </div>
                            {
                                lista.tipo == 'dinamica' ? 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <ReactTagInput 
                                            tags={lista.tags} 
                                            placeholder="Ingrese el texto de los tags, y presione enter."
                                            onChange={(newTags) => setList({ ...lista, tags:newTags})}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Origen</label>
                                        <ReactTagInput 
                                            tags={lista.origen} 
                                            placeholder="Ingrese el texto de los origenes, y presione enter."
                                            onChange={(newOrigen) => setList({ ...lista, origen:newOrigen})}
                                        />
                                    
                                    </div>
                                </fieldset>
                                : 
                                <fieldset className="mb-4" style={{"border":"1px solid #eee","padding":"1em","borderRadius":"1em"}}>
                                    <div className="form-group">
                                        <legend>Contenido de Lista</legend>
                                        <div className="checkbox p-1 mt-4">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'contactos'}  value="contactos"/> Contactos
                                            </label>
                                        </div>
                                        <div className="checkbox p-1">
                                            <label>
                                                <input type="radio" name="contactoOrigen" onChange={handleCheckedContactosClientes} checked={contactoOrigen == 'clientes'}  value="clientes"/> Clientes
                                            </label>
                                        </div>
                                    </div> 
                                    {/* <select className="form-control" name="clientes_contactos"></select> */}
                                    <AsyncSelect
                                        isMulti
                                        value={selected}
                                        onChange={handleChangeSelect}
                                        // options={options}
                                        loadOptions={getOptions}
                                    />
                                </fieldset>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button className="btn btn-primary" type="submit">Actualizar Lista</button>
                            <Link className="ml-3 colorsito" to="/maillist">Volver</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
