import React,{ useState , useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';


import { Link, useParams } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Modal from 'react-bootstrap4-modal';

import { obtenerCampaign, getElementosAlista, sendCampaign, sendTestEmail, get_statics } from '../../actions/campaignsActions';


import $ from 'jquery'
import clienteAxios from '../../config/axios';


export const Statistics = () => {
    
    const dispatch = useDispatch()

    const { idCampaign } = useParams()
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ testEmails, setTestEmails ] = useState("");

    const [unsuscribe, setunsuscribe] = useState('none');
    const [clicks, setclicks] = useState('none');
    const [seen, setseen] = useState('none');
    const [enviados, setenviados] = useState('none');
    const [noenviados, setnoenviados] = useState('none');
    
    const [busco, setBusco] = useState(false);

    const campaignedit = useSelector( state => state.campaigns.campaignedit )
    const campaignList = useSelector( state => state.campaigns.campaignList )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [bajas,setbajas] = useState([]);
    const [clicksRequest,setclicksRequest] = useState([]);
    const [sendsuccess,setsendSuccess] = useState([]);
    const [senderror,setsenderror] = useState([]);
    const [sendopen,setsendopen] = useState([]);

    const statics = useSelector( state => state.campaigns.statics )
    const loading = useSelector( state => state.campaigns.loading )
    const [designtype, setDesigntype] = useState('');
    const cerrarVentana = () => {
        setModalVisible(false);

    }
    useEffect(async () => {
        if(!busco){
            await dispatch(obtenerCampaign(idCampaign));
            await dispatch(getElementosAlista(idCampaign));
            await dispatch(get_statics(idCampaign));
            setbajas((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=5&bajas=true`)).data);
            setclicksRequest((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=5&clicks=true`)).data);
            setsendSuccess((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&sendsuccess=true`)).data);
            setsenderror((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&senderror=true`)).data);
            setsendopen((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&sendopen=true`)).data);
            
            console.log('bajas',bajas.data)
            if(campaignedit && designtype == ''){
                let content = JSON.parse(campaignedit.contenido)
                if(typeof content.html != 'undefined'){
                    setDesigntype('html')
                }else{
                    setDesigntype('text')
                }
                // console.log('designtype',designtype)
            }
            setBusco(true);
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[designtype,search_custom_page])


    const send = async (id) => {
        await dispatch(sendCampaign(id))
    }

    const sendtMail = (id) => {
        setModalVisible(true);

    }

    const enviarEmailPrueba = async () =>  {
        await dispatch(sendTestEmail({
            testEmails,
            campaignid:campaignedit.id
        }))
    }

    const handleTestMails = (e) => {
        setTestEmails(e.target.value)
    }

    const showClientesFrom = ( from ) => {
        switch (from) {
            case 'unsuscribe':
                if(unsuscribe == 'none'){
                    setunsuscribe('block')
                }else{
                    setunsuscribe('none')
                }
                break;
            case 'clicks':
                if(clicks == 'none'){
                    setclicks('block')
                }else{
                    setclicks('none')
                }
                break;
            case 'seen':
                if(seen == 'none'){
                    setseen('block')
                }else{
                    setseen('none')
                }
                break;
            case 'enviados':
                if(enviados == 'none'){
                    setenviados('block')
                }else{
                    setenviados('none')
                }
                break;
            case 'noenviados':
                if(noenviados == 'none'){
                    setnoenviados('block')
                }else{
                    setnoenviados('none')
                }
                break;
            default:
                break;
        }
    }

    const handleChangeBajasPage = async (page) => {
        setbajas((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=${page}&size=5&bajas=true`)).data);
    }
    const handleChangeBajasSearch = async (e) => {
       
        setbajas((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=5&bajas=true&q=${e.target.value}`)).data);
    }
    const handleChangeClicksPage = async (page)=>{
       setclicksRequest((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=${page}&size=5&clicks=true`)).data); 
    }
    const handleChangeClicksSearch = async (e)=>{
       setclicksRequest((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=5&clicks=true&q=${e.target.value}`)).data); 
    }
    const handleChangeSendedPage = async (page)=>{
       setsendSuccess((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=${page}&size=10&sendsuccess=true`)).data); 

    }
    const handleChangeSendedPageSearch = async (e)=>{
       setsendSuccess((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&sendsuccess=true&q=${e.target.value}`)).data); 

    }
    const handleChangeSendedErrorPage = async (page)=>{
       setsenderror((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=${page}&size=10&senderror=true`)).data); 

    }
    const handleChangeSendedErrorPageSearch = async (e)=>{
       setsenderror((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&senderror=true&q=${e.target.value}`)).data); 

    }
    const handleChangeSendedOpenPage = async (page)=>{
       setsendopen((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=${page}&size=10&sendopen=true`)).data); 

    }
    const handleChangeSendedOpenPageSearch = async (e)=>{
       setsendopen((await clienteAxios.get(`/campaigns/statics/${idCampaign}?page=0&size=10&sendopen=true&q=${e.target.value}`)).data); 

    }
    const handleSearch = async(e) => {
      // console.log(e.target.value)
    //   await dispatch((`?paginate=true&page=0&limit=10&size=10&q=${e.target.value}`))
    }
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Estadísticas</span>
                <Link to="/mail_marketing" className="btn btn-link float-right" >  Volver</Link>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <div className="row mb-5">
                <div className="col-md-4">
                <div className="panel panel-default custom-panel">
                    <div className="panel-body"></div>
                    <div className="list-group list-group-custom list-group-min-padding list-group-object-info">
                        <div className="list-group-item">
                            <p className="list-group-item-heading statistics_title">Creada por</p>
                            <p className="list-group-item-text statistics_text">{campaignedit && campaignedit?.creado?.nombre}</p>
                        </div>
                        
                        <div className="list-group-item">
                            <p className="list-group-item-heading statistics_title">Listas</p>
                            <p className="list-group-item-text statistics_text">
                                {
                                    campaignList && campaignList.arrList && campaignList.arrList.length && campaignList.arrList.map( l => (
                                        l && <Link className="marketing-list-contacts ml-2" style={{'display':"block"}} to={`/view_maillist/${l.id}`}>{l?.nombre}</Link>
                                    ))
                                }
                                
                            </p>
                        </div>
                        
                        <div className="list-group-item">
                            <p className="list-group-item-heading statistics_title">Tipo</p>
                            <p className="list-group-item-text statistics_text">{campaignList && campaignList.arrList[0].tipo}</p>
                        </div>
                        <div className="list-group-item">
                            <p className="list-group-item-heading statistics_title">Asunto</p>
                            <p className="list-group-item-text statistics_text">{campaignedit && campaignedit.asunto}</p>
                        </div>
                        <div className="list-group-item">
                            <p className="list-group-item-heading statistics_title">Nombre de remitente</p>
                            <p className="list-group-item-text statistics_text">{campaignedit && campaignedit.remitente}</p>
                        </div>
                        <div className="list-group-item statistics_title">
                            <p className="list-group-item-heading statistics_title">Email remitente</p>
                            <p className="list-group-item-text statistics_text">{campaignedit && campaignedit.email_remitente}</p>
                        </div>
                        
                    </div>
                    </div>
                </div>
                
                <div className="col-md-8" >
                    <ul className="list-group accordion">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            Cantidad de emails enviados
                            <span className="text_naranja">{statics && statics.data ? statics.data?.cantidad_eventos.count : null}</span>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-right">

                                Bajas debido a este email

                                <div>
                                    <span className="text_naranja hover espacio_derecho" >{(statics && statics.data && statics.data?.cantidad_eventos.count != 0) ? statics.data.bajas.count + " - " + (statics.data.bajas.count / statics.data.cantidad_eventos.count * 100).toFixed(2): null}%</span>
                                    <i className="fas fa-plus-circle text_naranja hover" onClick={ () => showClientesFrom('unsuscribe')}></i>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{"width": (statics && statics.data && statics.data?.cantidad_eventos.count != 0) ? ((statics.data.bajas.count / statics.data.cantidad_eventos.count * 100).toFixed(2))+"%" : "0%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        { statics && statics.data && statics.data?.bajas.count ? <div className="accordion-item" style={{display:unsuscribe}}>
                           
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="text" className="form-control input_busqueda" onChange={handleChangeBajasSearch} placeholder="Buscar [email]"/>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bajas && bajas.records ? bajas.records.map( baja => (
                                                    <tr>
                                                        <td>{JSON.parse(baja.mail)}</td>
                                                    </tr>
                                                )):<p>Cargando...</p>
                                            }
                                        </tbody>
                                    </table>
                                   
                                    <div className="text-center">
                                        
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <div className="page-item mr-10">
                                                    Pág {bajas.currentPage + 1} -

                                                    {bajas.totalItems} Registros 
                                                </div>
                                                <li className="page-item"><a className="page-link" onClick={ ()=> handleChangeBajasPage( bajas.currentPage>0 ? bajas.currentPage -1 : 0)}>Anterior</a></li>
                                                {
                                                    [...Array(bajas.totalPages).keys()].map( (page,index) => {
                                                        if(index < 15){

                                                            if(page == bajas.currentPage){
                                                                return <li className="page-item active">
                                                                <a className="page-link" onClick={ ()=> handleChangeBajasPage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                                                </li>
                                                            }else{
                                                                return <li className="page-item"><a className="page-link" onClick={ () => handleChangeBajasPage(page)} >{page + 1}</a></li>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    bajas.totalPages > 15 ? 
                                                    <li className="page-item"><a className="page-link" onClick={ () => handleChangeBajasPage(bajas.totalPages-1)}>{bajas.totalPages-1}</a></li> : null
                                                }
                                                <li className="page-item"><a className="page-link" onClick={ () => handleChangeBajasPage(bajas.currentPage < bajas.totalPages - 1 ? bajas.currentPage + 1 : bajas.totalPages - 1)}>Siguiente</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                     </div>
                                </div>
                            </div>
                        </div> : null }
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center"> 
                                Click en el email
                                <div>
                                    <span className="text_naranja hover espacio_derecho" onClick={ () => showClientesFrom('clicks')}>{(statics && statics.data && statics.data.cantidad_eventos.count != 0) ? statics.data.clicks.count + " - " + (statics.data.clicks.count / statics.data.cantidad_eventos.count * 100).toFixed(2): null}%</span>
                                    <i className="fas fa-plus-circle text_naranja hover" onClick={ () => showClientesFrom('clicks')}></i>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{"width": (statics && statics.data && statics.data.cantidad_eventos.count != 0) ? ((statics.data.clicks.count / statics.data.cantidad_eventos.count * 100).toFixed(2))+"%":"0%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        { statics && statics.data.clicks.count ? <div className="accordion-item" style={{display:clicks}}>
                           
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    
                                    <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="text" className="form-control input_busqueda" onChange={handleChangeClicksSearch} placeholder="Buscar [email]"/>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                clicksRequest && clicksRequest.records ? clicksRequest.records.map( click => (
                                                    <tr>
                                                        <td>{ click && JSON.parse(click.mail) }</td>
                                                    </tr>
                                                )):<p>Cargando...</p>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <div className="page-item mr-10">
                                                    Pág {clicksRequest.currentPage + 1} -
                                                    {clicksRequest.totalItems} Registros 
                                                </div>
                                                <li className="page-item"><a className="page-link" onClick={ ()=> handleChangeClicksPage( clicksRequest.currentPage>0 ? clicksRequest.currentPage -1 : 0)}>Anterior</a></li>
                                                {
                                                    [...Array(clicksRequest.totalPages).keys()].map( (page,index) => {
                                                        if(index < 15){

                                                            if(page  == clicksRequest.currentPage ){
                                                                return <li className="page-item active">
                                                                <a className="page-link" onClick={ ()=> handleChangeClicksPage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                                                </li>
                                                            }else{
                                                                return <li className="page-item"><a className="page-link" onClick={ () => handleChangeClicksPage(page)} >{page + 1}</a></li>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    clicksRequest.totalPages > 15 ? 
                                                    <li className="page-item"><a className="page-link" onClick={ () => handleChangeClicksPage(clicksRequest.totalPages-1)}>{clicksRequest.totalPages-1}</a></li> : null
                                                }
                                                <li className="page-item"><a className="page-link" onClick={ () => handleChangeClicksPage(clicksRequest.currentPage < clicksRequest.totalPages -1 ? clicksRequest.currentPage + 1 : clicksRequest.totalPages -1)}>Siguiente</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null }
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center">
                                Que Abrieron el email
                                <div>
                                    <span className="text_naranja hover espacio_derecho" >{(statics && statics.data && statics.data.cantidad_eventos.count != 0) ? statics.data.seen.count + " - " + (statics.data.seen.count / statics.data.cantidad_eventos.count * 100).toFixed(2): null}%</span>
                                    <i className="fas fa-plus-circle text_naranja hover" onClick={ () => showClientesFrom('seen')}></i>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{"width": (statics && statics.data && statics.data.cantidad_eventos.count != 0) ? ((statics.data.seen.count / statics.data.cantidad_eventos.count * 100).toFixed(2))+"%" : "0%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        { statics && statics.data.seen.count ? <div className="accordion-item" style={{display:seen}}>
                           
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="text" className="form-control input_busqueda" onChange={handleChangeSendedOpenPageSearch} placeholder="Buscar [email]"/>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sendopen && sendopen.records ? sendopen.records.map( sended => (
                                                    <tr>
                                                        <td>{ JSON.parse(sended.mail) }</td>
                                                    </tr>
                                                )):<p>Cargando...</p>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <div className="page-item mr-10">
                                                    Pág {sendopen.currentPage + 1} -

                                                    {sendopen.totalItems} Registros 
                                                </div>
                                                <li className="page-item"><a className="page-link" onClick={ ()=> handleChangeSendedOpenPage( sendopen.currentPage>0 ? sendopen.currentPage -1 : 0)}>Anterior</a></li>
                                                {
                                                    [...Array(sendopen.totalPages).keys()].map( (page,index) => {
                                                        if(index < 15){

                                                            if(page  == sendopen.currentPage ){
                                                                return <li className="page-item active">
                                                                <a className="page-link" onClick={ ()=> handleChangeSendedOpenPage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                                                </li>
                                                            }else{
                                                                return <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedOpenPage(page)} >{page + 1}</a></li>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    sendopen.totalPages > 15 ? 
                                                    <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedOpenPage(sendopen.totalPages-1)}>{sendopen.totalPages-1}</a></li> : null
                                                }
                                                <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedOpenPage(sendopen.currentPage < sendopen.totalPages -1 ? sendopen.currentPage + 1 : senderror.totalPages -1)}>Siguiente</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null }
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center">
                                Enviados Correctamente
                                <div>
                                    <span className="text_naranja hover espacio_derecho">{(statics && statics.data && statics.data.cantidad_eventos.count != 0) ? statics.data.enviados.count + " - " + (statics.data.enviados.count / statics.data.cantidad_eventos.count * 100).toFixed(2): null}%</span>
                                    <i className="fas fa-plus-circle text_naranja hover" onClick={ () => showClientesFrom('enviados')}></i>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{"width": (statics && statics.data && statics.data.cantidad_eventos.count != 0) ? ((statics.data.enviados.count / statics.data.cantidad_eventos.count * 100).toFixed(2))+"%" : "0%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        { statics && statics.data.enviados.count ? <div className="accordion-item" style={{display:enviados}}>
                           
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="text" className="form-control input_busqueda" onChange={handleChangeSendedPageSearch} placeholder="Buscar [email]"/>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sendsuccess && sendsuccess.records ? sendsuccess.records.map( sended => (
                                                    <tr>
                                                        <td>{ JSON.parse(sended.mail) }</td>
                                                    </tr>
                                                )):<p>Cargando...</p>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <div className="page-item mr-10">
                                                    Pág {sendsuccess.currentPage + 1} -

                                                    {sendsuccess.totalItems} Registros 
                                                </div>
                                                <li className="page-item"><a className="page-link" onClick={ ()=> handleChangeSendedPage( sendsuccess.currentPage>0 ? sendsuccess.currentPage -1 : 0)}>Anterior</a></li>
                                                {
                                                    [...Array(sendsuccess.totalPages).keys()].map( (page,index) => {
                                                        if(index < 15){

                                                            if(page  == sendsuccess.currentPage ){
                                                                return <li className="page-item active">
                                                                <a className="page-link" onClick={ ()=> handleChangeSendedPage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                                                </li>
                                                            }else{
                                                                return <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedPage(page)} >{page + 1}</a></li>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    sendsuccess.totalPages > 15 ? 
                                                    <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedPage(sendsuccess.totalPages-1)}>{sendsuccess.totalPages-1}</a></li> : null
                                                }
                                                <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedPage(sendsuccess.currentPage < sendsuccess.totalPages -1 ? sendsuccess.currentPage + 1 : sendsuccess.totalPages -1)}>Siguiente</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null }
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center">
                                No pudieron ser enviados
                                <div>
                                <span className="text_naranja hover espacio_derecho" >{(statics && statics.data && statics.data.cantidad_eventos.count != 0) ? statics.data.noenviados.count + " - " + (statics.data.noenviados.count / statics.data.cantidad_eventos.count * 100).toFixed(2): null}%</span>
                                <i className="fas fa-plus-circle text_naranja hover" onClick={ () => showClientesFrom('noenviados')}></i>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{"width": (statics && statics.data && statics.data.cantidad_eventos.count != 0) ? ((statics.data.noenviados.count / statics.data.cantidad_eventos.count * 100).toFixed(2))+"%":"0%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </li>
                        { statics && statics.data.noenviados.count ? <div className="accordion-item" style={{display:noenviados}}>
                           
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="table-responsive">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="text" className="form-control input_busqueda" onChange={handleChangeSendedErrorPageSearch} placeholder="Buscar [email]"/>
                                        </div>
                                    </div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                senderror && senderror.records ? senderror.records.map( sended => (
                                                    <tr>
                                                        <td>{ JSON.parse(sended.mail) }</td>
                                                    </tr>
                                                )):<p>Cargando...</p>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <div className="page-item mr-10">
                                                    Pág {senderror.currentPage + 1} -

                                                    {senderror.totalItems} Registros 
                                                </div>
                                                <li className="page-item"><a className="page-link" onClick={ ()=> handleChangeSendedErrorPage( senderror.currentPage>0 ? senderror.currentPage -1 : 0)}>Anterior</a></li>
                                                {
                                                    [...Array(senderror.totalPages).keys()].map( (page,index) => {
                                                        if(index < 15){

                                                            if(page  == senderror.currentPage ){
                                                                return <li className="page-item active">
                                                                <a className="page-link" onClick={ ()=> handleChangeSendedErrorPage(page+1)}>{page + 1} <span className="sr-only">(current)</span></a>
                                                                </li>
                                                            }else{
                                                                return <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedErrorPage(page)} >{page + 1}</a></li>
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    senderror.totalPages > 15 ? 
                                                    <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedErrorPage(senderror.totalPages-1)}>{senderror.totalPages-1}</a></li> : null
                                                }
                                                <li className="page-item"><a className="page-link" onClick={ () => handleChangeSendedErrorPage(senderror.currentPage < senderror.totalPages -1 ? senderror.currentPage + 1 : senderror.totalPages -1)}>Siguiente</a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null }
                    </ul>
                </div>
            </div>
            
        </div>
    )
}
