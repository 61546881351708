import React from 'react'
import {Link } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';

export const LayoutConfig = () => {

    const layoutEdit = useSelector( state => state.plantillas.layoutEdit )

    const idTrozos = window.location.pathname.split('/');
    if(idTrozos.length == 3){
        // idTrozos[2]
    }
    return (
        <div>
            <div className="p-4">
                <span className="mr-3 naranja">Configuración de plantilla</span>
                <Link to="/layoutlist" className="float-right p-2 mr-4">Volver</Link>
            </div>
            <div className="text-center">

                <div className="btn-group ml-4 mt-5" >
                    <Link className="btn btn-default p-5" style={{ "fontSize":"2em"}}  to={`/layout_basic/${idTrozos[2]}`}><i class="far fa-address-card"></i> Diseños Basicos</Link>
                    {
                        layoutEdit.tipo != 'texto' ?
                        <Link className="btn btn-default p-5" style={{ "fontSize":"2em"}} to={`/layout_template/${idTrozos[2]}`}><i class="fab fa-buromobelexperte"></i>  Temas</Link>
                        : null
                    }
                </div>       
            </div>
        </div>
    )
}
