import React, { useEffect, useState } from 'react'
import { Link, useParams, Redirect } from 'react-router-dom';
import { createAccount, obtenerUsuario, actualizarCuenta, getMembership, setMembership } from '../../actions/usersActions';
// redux
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
moment.locale('es');


export const EditAccount = () => {
    const [displayMembership, setdisplayMembership] = useState('block');
    const [emails_boolean, setemails_boolean ] = useState(false);
    const [emprende, setEmprende] = useState('none');
    const [crece, setCrece] = useState('none');
    const [frecuency, setfrecuency] = useState();
    Date.isLeapYear = function (year) { 
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    };
    
    Date.getDaysInMonth = function (year, month) {
        return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };
    
    Date.prototype.isLeapYear = function () { 
        return Date.isLeapYear(this.getFullYear()); 
    };
    
    Date.prototype.getDaysInMonth = function () { 
        return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
    };
    
    Date.prototype.addMonths = function (value) {
        var n = this.getDate();
        this.setDate(1);
        this.setMonth(this.getMonth() + value);
        this.setDate(Math.min(n, this.getDaysInMonth()));
        return this;
    };

    const dispatch = useDispatch()
    const { id } = useParams();
    const [membershipplan, setMemberShip] = useState({});
    const [ new_account, setNewAccount ] = useState({
        id:null,
        email:null,
        nombre_cuenta:null,
        nombre:null,
        nombre_empresa:null,
        telefono:null,
        foto:null,
        cuenta:null,
        rol:"",
        isActive:"",
        password:null,
        database:null
        
    });

    const [suscripcion, setSuscripcion] = useState({
        dbname:null,
        plan:null,
        date_begin:null,
        date_finish:null
    });

    const userAccount = useSelector( state => state.user.user )
    const membershipError = useSelector( state => state.user.membershipError )
    const membership = useSelector( state => state.user.membership )
    const currentUserAuthenticated = useSelector( state => state.auth.user )
    


    const handleSubmit = async (e) => {
        // alert('submit')
        e.preventDefault();
        await dispatch(actualizarCuenta({new_account:new_account,membership:suscripcion}));
    }

    const handleChange = (e) => {
        // console.log('e.target.value',e.target.value)
        if(e.target.name == 'nombre_cuenta'){
            setNewAccount({
                ...new_account,
                [e.target.name]:e.target.value.split(' ').join('_')
            })
        }else{

            setNewAccount({
                ...new_account,
                [e.target.name]:e.target.value
            })
        }
    }

    const handleChangeSuscripcion = (e) => {
        const plan = e.target.value;
        const currentDate = new Date();
        let nextDate = currentDate;
        switch(plan){
            case "1 mes":
                nextDate = nextDate.addMonths(1);
            break;
            case "3 meses":
                nextDate = nextDate.addMonths(3);
            break;
            case "6 meses":
                nextDate = nextDate.addMonths(6);
            break;
            case "1 año":
                nextDate = nextDate.addMonths(12);
            break;
            default:
                return
        }

        var date = new Date();
        var strdate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        nextDate = nextDate.getFullYear() + "-" + ('0' + (nextDate.getMonth() + 1)).slice(-2) + "-" + ('0' + nextDate.getDate()).slice(-2);
      

        setSuscripcion({
            ...suscripcion,
            date_begin:strdate,
            date_finish:nextDate
        })

        setfrecuency(plan)
    }

    const actualizarSuscripcion = async () => {
        await dispatch(setMembership(suscripcion))
    }
    const handleChangeMontlyEmails = (e) => {
        setSuscripcion({
            ...suscripcion,
            monthly_emails:e.target.value,
            id:membershipplan.id
        })
        setMemberShip({
            ...membershipplan,
            monthly_emails:e.target.value,
            id:membershipplan.id
        })
    }
    const handleChangePlan = (e) => {
        // console.log('membershipplan',membershipplan)
        // console.log('suscripcion',suscripcion)
        setMemberShip({
            ...membershipplan,
            plan:e.target.value,
            id:membershipplan.id
        })
        if(e.target.value == 'Emprende'){
            setSuscripcion({
                ...suscripcion,
                monthly_emails:2000,
                plan:e.target.value,
                id:membershipplan.id
            })
            setemails_boolean(true);
        }else{
            setSuscripcion({
                ...suscripcion,
                monthly_emails:"",
                plan:e.target.value,
                id:membershipplan.id
            })
            setemails_boolean(false);
        }
        
        
      
    }
    const getDaysDiference = (date1,date2) => {
        var date1 = new Date(date1);
        var date2 = new Date(date2);
        
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();
        
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        // console.log('Difference_In_Days',Difference_In_Days)
        return Difference_In_Days;
    }
    useEffect(async () => {
        if(!userAccount){
            await dispatch(obtenerUsuario(id))
        }
        if(userAccount && userAccount.data){
            console.log('userAccount.data',userAccount)
            // return;
            setNewAccount(userAccount.data);
            setMemberShip(userAccount.membership)
            
            const difdays = getDaysDiference(userAccount.membership.date_begin,userAccount.membership.date_finish)
            // console.log('difdays',difdays)
            if(difdays >= 29 && difdays <= 31){
                setfrecuency("1 mes");
            }
            if(difdays >= 90 && difdays <= 93){
                setfrecuency("3 mes");
            }
            if(difdays >= 179 && difdays <= 186){
                setfrecuency("6 mes");
            }
            if(difdays == 365){
                setfrecuency("1 año");
            }
            if(userAccount.data.database){
                setSuscripcion({
                    ...suscripcion,
                    dbname:userAccount.data.database
                })
                await dispatch(getMembership(userAccount.data.database))
            }
        }
        if(currentUserAuthenticated){
            if(currentUserAuthenticated.roles[0].name != 'impulsa'){
                return <Redirect to="/" />
            }
        }
    }, [userAccount])

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Editar Cuenta</span>
                

            </div>
            <Link to="/admin_accounts" className="btn btn-link" >Volver a la lista</Link>
            <form onSubmit={ handleSubmit } className="mt-5 ml-3">
                {
                    new_account.rol != 'impulsa' ?
                    <>
                    <div className="row">
                        <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Correo Electrónico</label>
                                    <input type="email" required name="email" onChange={ handleChange } placeholder="Correo Electrónico" className="form-control-lg form-control" value={new_account.email} />
                                </div>
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nombre para conjunto de Landing Page</label>
                                    <input type="text" required name="nombre_cuenta" onChange={ handleChange } placeholder="Nombre para conjunto de Landing Page" className="form-control-lg form-control" value={new_account.nombre_cuenta} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nombre Empresa</label>
                                    <input type="text" required name="nombre_empresa" onChange={ handleChange } placeholder="Nombre Empresa" className="form-control-lg form-control" value={new_account.nombre_empresa} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Nombre</label>
                                    <input type="text" required name="nombre" onChange={ handleChange } placeholder="Nombre" className="form-control-lg form-control" value={new_account.nombre} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Teléfono</label>
                                    <input type="text" required name="telefono" onChange={ handleChange } placeholder="Teléfono" className="form-control-lg form-control" value={new_account.telefono} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Rol</label>
                                    <select name="rol" required value={new_account.rol} onChange={ handleChange } className="form-control-lg form-control">
                                        <option value="">Seleccione</option>
                                        <option value="admin">Administrador</option>
                                        <option value="impulsa">Impulsa</option>
                                        <option value="user">Usuario</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Estado</label>
                                    <select name="isActive" required value={ new_account.isActive == "1" ? "1" : "0"} onChange={ handleChange } className="form-control-lg form-control">
                                        <option value="">Seleccione</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            currentUserAuthenticated && currentUserAuthenticated.roles[0].name == 'impulsa' ? 
                            <div className='col-md-12' style={{"display":displayMembership}}>
                                <div className="form-group">
                                    <fieldset>
                                        {
                                            membershipplan && membershipplan.date_finish ? <h2 style={{"color":"green"}}>Este plan termina en {membershipplan.date_finish}.</h2> : null
                                        }

                                        <legend>Afiliación</legend>
                                        <div className="form-group">
                                            <label>Emails Mensuales</label>
                                            <input type='text' className='form-control-lg form-control' value={membershipplan && membershipplan.monthly_emails} readOnly={emails_boolean} style={{"width":"100px"}} name="monthly_emails" onChange={handleChangeMontlyEmails}/>
                                        </div>
                                        <div className="form-group" >
                                            <label>Frecuencia</label>
                                            <select className="form-control-lg form-control"  name="frecuencia" value={frecuency} onChange={handleChangeSuscripcion}>
                                                <option value="">Seleccione</option>
                                                <option value="1 mes">1 mes</option>
                                                <option value="3 meses">3 meses</option>
                                                <option value="6 meses">6 meses</option>
                                                <option value="1 año">1 año</option>
                                            </select>
                                        </div>
                                        <div className="form-group" >
                                            <label>Plan</label>
                                            <select className="form-control-lg form-control"  name="plan" value={membershipplan && membershipplan.plan} onChange={handleChangePlan}>
                                                <option value="">Seleccione</option>
                                                <option value="Emprende">Emprende</option>
                                                <option value="Crece">Crece</option>
                                            </select>
                                        </div>
                                        <div id="emprende" className={{'display': emprende ? 'block' : 'none'}}>
                                            <h3>Emprende</h3>
                                            <ul>
                                                <li>Puede usar hasta 500 clientes</li>
                                                <li>Módulo contacto no lo puede utilizar. Este módulo solo disponible Plan Crece.</li>
                                                <li>Puede enviar 2.000 emails mensuales máximo (mes calendario)</li>
                                                <li>Puede crear 20 landing pages máximo, pero solo con la opción de link de impulsa.click (no con dominio personalizado)</li>
                                            </ul>    
                                        </div>
                                        <div id="crece" className={{'display': crece ? 'block' : 'none'}}>
                                            <h3>Crece</h3>
                                            <ul>
                                                <li>Puede usar clientes ilimitados</li>
                                                <li>Puede usar contactos ilimitados</li>
                                                <li>Puede crear landing page ilimitados con dominio propio </li>
                                                <li>Puede enviar hasta X cantidad mensual, según se destine en la creación de la cuenta</li>
                                                <li>Agregar: Cantidad de mails mensuales (10.000 a 100.000, de 10 mil en 10 mil)</li>
                                            </ul>    
                                        </div>
                                        
                                    </fieldset>
                                    
                                </div>
                    </div>
                            : null
                        }
                        
                    </div>
                    </div></>:null
                }
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Contraseña</label>
                            <input type="password"  name="password" onChange={ handleChange } placeholder="Contraseña" className="form-control-lg form-control" value={new_account.password} />
                        </div>
                    </div>
                </div>
                <input type="submit" className="btn btn-success mb-5" value="Actualizar Cuenta"/>
            </form>
        </div>
    )
}
