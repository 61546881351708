import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    OBTENER_USUARIO,
    SET_CURRENT_MENU,
    SET_HOME_DATA
}
from '../types';

// cada reducer tiene su propio state
import $ from 'jquery';
const initialState = {
    isAuthenticated:false,
    token:null,
    user:null,
    error:null,
    loading:false,
    current_menu:'home',
    home_data:null
}

export default function (state = initialState, action){
    switch(action.type){
        case SET_HOME_DATA:
            return {
                ...state,
                home_data:action.payload
            }
        case SET_CURRENT_MENU:
            return {
                ...state,
                current_menu:action.payload
            }
        case LOGIN:
            
            return {
                ...state,
                loading:true,
                user:null
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('app_marketing',action.payload.token);
            return {
                ...state,
                loading:false,
                user:action.payload.user,
                token:action.payload.token,
                isAuthenticated:true
            }
        case LOGIN_ERROR:
            return {
                ...state,
                loading:false,
                user:action.payload,
                token:null,
                isAuthenticated:false
            }
        case LOGOUT:
        
            return {
                ...state,
                loading:true,
                user:null,
                current_menu:'home'
            }
        case LOGOUT_SUCCESS:
            localStorage.removeItem('app_marketing');
            return {
                ...state,
                loading:false,
                user:null,
                token:null,
                isAuthenticated:false
            }
        case LOGOUT_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case OBTENER_USUARIO:
            setTimeout(()=>{
                $("#loading").hide();
                console.log('se activo!')
            },5000)
            return{
                ...state,
                isAuthenticated:true,
                user:action.payload
            }
        default:
            return state;
    }
}