import React, { useState, useEffect } from 'react'
import { Link,useHistory } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Swal from 'sweetalert2';

import $ from 'jquery'

// redux
import { useSelector, useDispatch } from 'react-redux';

import { crearCampaign, obtenerCampaigns } from '../../actions/campaignsActions';
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';

export const CreateCampaign = () => {
    let history = useHistory();

    const dispatch = useDispatch()
    const loading = useSelector( state => state.campaigns.loading )
    const campaignedit = useSelector( state => state.campaigns.campaignedit )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ campaign, setCampaign ] = useState({
        nombre:'',
        asunto:'',
        remitente:'',
        email_remitente:''
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(campaign.nombre.trim() === '' || campaign.asunto.trim() === '' || campaign.remitente.trim() === '' || campaign.email_remitente.trim() === '') {
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Todos los campos son obligatorios'
            })

            return;
        }

        await dispatch(crearCampaign(campaign));

        // setCampaign({
        //     nombre:'',
        //     asunto:'',
        //     remitente:'',
        //     email_remitente:''
        // });

        
    }

    const handleChange = (e) => {
        setCampaign({
            ...campaign,
            [e.target.name] : e.target.value
        })
    }

    useEffect(()=>{
        if(campaignedit && campaignedit.id){

            history.push(`/campaign_list/${campaignedit.id}`)
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
    },[campaignedit,search_custom_page])

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <BreadCrumbCampaign active={1}/>
                <span className="mr-3 naranja">Crear Campaña</span>
                <Link to="/mail_marketing" className="btn btn-link float-right colorsito" >Volver</Link>
                {
                    loading ? <LoaderSpinner/> : null
                }
                <form onSubmit={ handleSubmit } className="mt-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.nombre } onChange={ handleChange } name="nombre" placeholder="Nombre" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Asunto</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.asunto } onChange={ handleChange } name="asunto" placeholder="Asunto"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Nombre Remitente</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.remitente } onChange={ handleChange } name="remitente" placeholder="Nombre Remitente" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Email Remitente</label>
                                <input type="email" className="form-control-lg form-control" value={ campaign.email_remitente } onChange={ handleChange } name="email_remitente" placeholder="Email Remitente"/>
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="btn btn-primary mt-4" value="Guardar"/>
                </form>
            </div>
            
        </div>
    )
}
