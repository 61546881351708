import React, { useEffect, useState } from 'react'

import ReCAPTCHA from "react-google-recaptcha";

import { Link, useHistory } from 'react-router-dom';
import { LoaderSpinner } from '../../components/LoaderSpinner';

import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { createAccount,reset_user_created } from '../../actions/usersActions';
import $ from 'jquery'
import { GoogleReCaptchaProvider , GoogleReCaptcha } from 'react-google-recaptcha-v3';


export const Register = () => {

    let history = useHistory();

    const [showwithplan, setShowWithPlan] = useState('none');
    const [booleanWithPlan, setBooleanWithPlan] = useState(false);
    // https://openbase.com/js/react-google-recaptcha
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch()

    const loading = useSelector( state => state.auth.loading )
    const successUserCreated = useSelector( state => state.user.successUserCreated )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    
    let siteKeyPort = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    siteKeyPort = '6Le2coIfAAAAAPltqg9uIN_SkLnbyhc5_4HnCDwO';
    
    let siteKey = '6Lc4VAMeAAAAAEdS1QUY9sPdVP4GpznHF7D-VjVL';
    siteKey = '6Le2coIfAAAAAPltqg9uIN_SkLnbyhc5_4HnCDwO';
    
    
    const initialState = {
        nombre_empresa:'',
        nombre:'',
        nombre_conjunto:'',
        emails_quantity:'',
        frecuencia:'',
        plan:'',
        email:'',
        password:'',
        date_begin:'',
        date_finish:'',
        captcha:''
    }
    
    const [user, setUser ] = useState(initialState);
    

    Date.isLeapYear = function (year) { 
        return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    };
    
    Date.getDaysInMonth = function (year, month) {
        return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    };
    Date.prototype.isLeapYear = function () { 
        return Date.isLeapYear(this.getFullYear()); 
    };
    
    Date.prototype.getDaysInMonth = function () { 
        return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
    };
    
    Date.prototype.addMonths = function (value) {
        var n = this.getDate();
        this.setDate(1);
        this.setMonth(this.getMonth() + value);
        this.setDate(Math.min(n, this.getDaysInMonth()));
        return this;
    };
    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
          window.grecaptcha
            .execute(`${process.env.REACT_APP_GOOGLE_AUTH}`, { action: "login" })
            .then(token => {
                console.log(token)
                setUser({
                    ...user,
                    captcha:token
                })
            })
        })
      }
    useEffect(()=>{
        if(successUserCreated){
            dispatch(reset_user_created())
            history.push('/login');
        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
            // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_AUTH}`
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)

    },[successUserCreated])
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!user.captcha){
            Swal.fire({
                icon:'error',
                title:'Error',
                text:'Falta el captcha o es incorrecto'
            })
            return;
        }
        if(user.plan == 'Crece' && !user.emails_quantity){
            Swal.fire({
                icon:'error',
                title:'Error',
                text:'Falta la cantidad de emails por mes'
            })
            return;
        }
        if(user.plan == 'Crece' && !user.frecuencia){
            Swal.fire({
                icon:'error',
                title:'Error',
                text:'Falta frecuencia del plan'
            })
            return;
        }
        await dispatch(createAccount({new_account:user}));

        
    }
const handleVerify = () => {
  console.log('handleVerify')
}
    const handleChange = (e) => {
        if(e.target.name === 'nombre_conjunto'){
            console.log('entro en 1',e.target.name,e.target.value)
            setUser({
                ...user,
                [e.target.name] : e.target.value.split(' ').join('_')
            })
        } 
        if(e.target.name === 'plan'){
            console.log('entro en 2',e.target.name,e.target.value)
            
            if(e.target.value == 'Crece'){
                setShowWithPlan("block");
         
            }
            if(e.target.value == 'Emprende'){
                setUser({
                    nombre_empresa:user.nombre_empresa,
                    nombre:user.nombre,
                    nombre_conjunto:user.nombre_conjunto,
                    emails_quantity:'',
                    frecuencia:'',
                    plan:user.plan,
                    email:user.email,
                    password:user.password,
                    date_begin:'',
                    date_finish:'',
                    captcha:user.captcha
                });
                
                setShowWithPlan("none");
                setBooleanWithPlan(false);
            
            }
            
            setUser({
                ...user,
                [e.target.name] : e.target.value
            })
            
        }
        if(e.target.name !== 'plan' && e.target.name !== 'nombre_conjunto'){
            console.log('entro en 3',e.target.name,e.target.value)
            setUser({
                ...user,
                [e.target.name] : e.target.value
            })
        }
    }

    const emailsQuantityCreator = () => {
        let items = [];
        let itemNumber = 10000
        while (itemNumber <= 100000) {
            items.push(<option value={itemNumber}>{itemNumber}</option>)
            itemNumber += 10000;
        }
        return items;
    }
    const onChange = (value) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        // console.log("Captcha value:", value);
        setUser({
            ...user,
            'captcha' : value
        })
    }
    const handleChangeSuscripcion = (e) => {
        const frecuencia = e.target.value;
        const currentDate = new Date();
        let nextDate = currentDate;
        switch(frecuencia){
            case "Mensual":
                nextDate = nextDate.addMonths(1);
            break;
            // case "Semestral":
            //     nextDate = nextDate.addMonths(3);
            // break;
            case "Semestral":
                nextDate = nextDate.addMonths(6);
            break;
            case "Anual":
                nextDate = nextDate.addMonths(12);
            break;
            default:
                return
        }

        var date = new Date();
        var strdate = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2);
        nextDate = nextDate.getFullYear() + "-" + ('0' + (nextDate.getMonth() + 1)).slice(-2) + "-" + ('0' + nextDate.getDate()).slice(-2);
      

       
        setUser({
            ...user,
            frecuencia : frecuencia,
            date_begin :strdate,
            date_finish :nextDate
        })
    }


    return (
              <GoogleReCaptchaProvider
        reCaptchaKey="6Le2coIfAAAAAPltqg9uIN_SkLnbyhc5_4HnCDwO"
       
      >
        <GoogleReCaptcha onVerify={handleVerify} />
        <div id="registerWrapper">
            <div id="register">
            <div className="container">
                <div id="register-row" className="row justify-content-center align-items-center">
                    <div id="register-column" className="col-md-6">
                        <div id="register-box" className="col-md-12">
                            <div className="text-center mt-4">
                                <img src="/Logos-impulsa-4.png" className="mt-4"/>
                            </div>
                            <form id="register-form" className="form" action="" method="post" onSubmit={ handleSubmit }>
                                <h3 className="text-center colorsito">Registro</h3>
                                {
                                    loading ? <LoaderSpinner/> : null
                                }
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Nombre Empresa:</label><br/>
                                    <input type="text" name="nombre_empresa" required value={user.nombre_empresa} onChange={ handleChange } id="nombre_empresa" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Nombre Usuario:</label><br/>
                                    <input type="text" name="nombre" required value={user.nombre} onChange={ handleChange } id="nombre" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Email:</label><br/>
                                    <input type="email" name="email" required value={user.email} onChange={ handleChange } id="email" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Nombre para el conjunto de landing page:</label><br/>
                                    <input type="text" name="nombre_conjunto" required value={user.nombre_conjunto} onChange={ handleChange } id="nombre_conjunto" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="username" className="label-login">Plan:</label><br/>
                                    <select className="form-control-lg form-control" required name="plan" value={user.plan} onChange={ handleChange }>
                                        <option value="">Seleccione</option>
                                        <option value="Crece">Crece</option>
                                        <option value="Emprende">Emprende</option>
                                    </select>
                                </div>
                                <div className="form-group" style={{"display":showwithplan}}>
                                    <label htmlFor="username" className="label-login">Frecuencia:</label><br/>
                                    <select className="form-control-lg form-control" required={booleanWithPlan} name="frecuencia" value={user.frecuencia} onChange={ handleChangeSuscripcion }>
                                        <option value="">Seleccione</option>
                                        <option value="Mensual">Mensual</option>
                                        <option value="Semestral">Semestral</option>
                                        <option value="Anual">Anual</option>
                                    </select>
                                </div>
                                <div className="form-group" style={{"display":showwithplan}}>
                                    <label htmlFor="username" className="label-login">Cantidad de Emails mensual:</label><br/>
                                    <select className="form-control-lg form-control" name="emails_quantity" required={booleanWithPlan} value={user.emails_quantity} onChange={ handleChange }>
                                        <option>Seleccione</option>
                                        {
                                            emailsQuantityCreator().map( item => item )
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="label-login">Password:</label><br/>
                                    <input type="password" name="password" required value={user.password} onChange={ handleChange } id="password" className="form-control-lg form-control"/>
                                </div>
                                <div className="form-group text-center">
                                    <div
                                        className="g-recaptcha"
                                        data-sitekey={process.env.REACT_APP_GOOGLE_AUTH}
                                        data-size="invisible"
                                    ></div>
                                </div>
                                <div className="form-group">
                                    {/* <label htmlFor="remember-me" className="label-login"><span>Remember me</span>聽<span><input id="remember-me" name="remember-me" type="checkbox"/></span></label><br/> */}
                                    <input type="submit" name="submit" className="btn btn-info btn-md" value="Registrarme" />
                                    
                                    
                                        <Link to="/login" className="label-login text-right pull-right colorsito">Volver</Link> 
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </GoogleReCaptchaProvider>

    )
}
