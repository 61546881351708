import React, { useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { Unsubcribe } from '../screens/campaigns/Unsubcribe';
import { ShowLanding } from '../screens/lading_pages/ShowLanding';
// import { HomeScreen } from '../screens/HomeScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { NotFoundScreen } from '../screens/NotFoundScreen';
// import { AboutScreen } from '../screens/AboutScreen';
// import { NotFoundScreen } from '../screens/NotFoundScreen';
// import { Navbar } from '../screens/Navbar';
// import { TopNav } from '../components/TopNav';
import { DashboardRoutes } from './DashboardRoutes';

import clienteAxios from '../config/axios';
import { set_current_landing_page } from '../actions/landingActions';
import {  useDispatch } from 'react-redux';
import { ResetPassword } from '../screens/ResetPassword';
import { Register } from '../screens/perfil/Register';
import $ from 'jquery';

export const AppRouter = () => {
    const dispatch = useDispatch()
    

    useEffect(async ()=>{
        // const landing = await checkCustomerLandingPage();
        // // console.log(landing.data);
        // if(landing.data.success){
        //     dispatch(set_current_landing_page(landing.data.landing,landing.data.form))
        // }
        
    },[])
    const checkCustomerLandingPage = async () => {
        $('#loading').show();
        // alert("1")
        const page = await clienteAxios.get('/lading_page/customerlandingpage/'+window.location.hostname+"/"+JSON.stringify(window.location.href.split('/')));
        $('#loading').hide();
        return page;
    }
    return (
        <Router>
            <div>
                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/login" component={LoginScreen}/>
                    <Route exact path="/register" component={Register}/>
                    <Route path="/p/:account_name/:slug" component={ShowLanding} />
                    <Route exact path="/no_found" component={ NotFoundScreen } />
                    <Route exact path="/campaigns/unsubcribe/:v1/:content" component={ Unsubcribe } />
                    <Route exact path="/reset_password" component={ ResetPassword } />
                  
                    <Route path="/" component={DashboardRoutes} render={props => <DashboardRoutes {...props} />}/>
                </Switch>
            </div>
        </Router>
    )
}