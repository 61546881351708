import React, { useState, useEffect } from 'react';
import '../App.css';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { login, validarCodigo2FA } from '../actions/authActions';
import { LoaderSpinner } from '../components/LoaderSpinner';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export const LoginScreen = ({ history }) => {
    const [user, setUser] = useState({
        email: '',
        password: '',
    });
    const [remember, setRemember] = useState(false);
    const [codigo2FA, setCodigo2FA] = useState('');
    const [userID, setUserID] = useState('');
    const dispatch = useDispatch();

    const isAuth = useSelector(state => state.auth.isAuthenticated);
    const loading = useSelector(state => state.auth.loading);
    const search_custom_page = useSelector(state => state.landings.search_custom_page);

    useEffect(() => {
        let access = localStorage.getItem('app_marketing_access');
        if (access) {
            access = JSON.parse(access);
            if (access.email && access.password) {
                setUser({
                    email: access.email,
                    password: access.password,
                });
            }
        }
        if (search_custom_page == null) {
            $('#loading').hide();
        }
    }, [isAuth, search_custom_page, history]);

    const handleVerify2FACode = async () => {
        const isValid = await dispatch(validarCodigo2FA(userID, codigo2FA));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(user.email, user.password, setUserID)); // Envío del callback para establecer el userID
    };

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value.trim(),
        });
    };

    return (
        <div id="loginWrapper">
            <div id="login">
                <div className="container">
                    <div id="login-row" className="row justify-content-center align-items-center">
                        <div id="login-column" className="col-md-6">
                            <div id="login-box" className="col-md-12">
                                <div className="text-center mt-4">
                                    <img src="/Logos-impulsa-4.png" className="mt-4" alt="Logo" />
                                </div>
                                <form id="login-form" className="form" onSubmit={handleSubmit}>
                                    <h3 className="text-center colorsito">Inicio de sesión</h3>
                                    {loading ? <LoaderSpinner /> : null}
                                    <div className="form-group">
                                        <label htmlFor="email" className="label-login">Correo Electrónico:</label>
                                        <input type="text" name="email" value={user.email} onChange={handleChange} id="email" className="form-control-lg form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="label-login">Contraseña:</label>
                                        <input type="password" name="password" value={user.password} onChange={handleChange} id="password" className="form-control-lg form-control" />
                                        <label className='mt-1' style={{ "fontSize": "13px" }}>
                                            <input type="checkbox" defaultChecked={remember} onChange={() => setRemember(!remember)} style={{ "width": "13px" }} /> Recordar datos de sesión
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" name="submit" className="btn btn-info btn-md" value="Iniciar sesión" />
                                        <Link to="/register" className="label-login text-right pull-right colorsito">Registro</Link>
                                        <div style={{ "display": "block", "marginTop": "0px" }}>
                                            <Link to="/reset_password" className="label-login text-right pull-right colorsito">Recuperar contraseña</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* Modal para el código de 2FA */}
                    <div className="modal fade" id="modal2FACode" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Verificación 2FA</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Por favor, introduce el código de 6 dígitos de tu aplicación de Autenticación Google.</p>
                                    <input type="text" className="form-control" value={codigo2FA} onChange={(e) => setCodigo2FA(e.target.value)} placeholder="Código 2FA" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn btn-primary" onClick={handleVerify2FACode}>Verificar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
};
