export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR'

export const BEGIN_LIST_CLIENTS = 'BEGIN_LIST_CLIENTS';
export const LIST_CLIENTS_SUCCESS = 'LIST_CLIENTS_SUCCESS';
export const LIST_CLIENTS_ERROR = 'LIST_CLIENTS_ERROR';



export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_ERROR = 'ADD_CONTACT_ERROR'

export const BEGIN_LIST_CONTACTS = 'BEGIN_LIST_CONTACTS';
export const LIST_CONTACTS_SUCCESS = 'LIST_CONTACTS_SUCCESS';
export const LIST_CONTACTS_ERROR = 'LIST_CONTACTS_ERROR';

export const BEGIN_POST_CLIENT = 'BEGIN_POST_CLIENT';
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS';
export const POST_CLIENT_ERROR = 'POST_CLIENT_ERROR';

export const  TRYING_DELETE_CLIENTE = 'TRYING_DELETE_CLIENTE';
export const  CLIENTE_DELETED_SUCCESS = 'CLIENTE_DELETED_SUCCESS';
export const  CLIENTE_DELETED_ERROR = 'CLIENTE_DELETED_ERROR';

export const SEARCH_CLIENT = 'SEARCH_CLIENT';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
export const SEARCH_CLIENT_ERROR = 'SEARCH_CLIENT_ERROR';

export const BEGIN_UPDATE_CLIENT = 'BEGIN_UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_ERROR = 'UPDATE_CLIENT_ERROR';

export const BEGIN_NEW_CONTACTO = 'BEGIN_NEW_CONTACTO';
export const NEW_CONTACTO_SUCCESS = 'NEW_CONTACTO_SUCCESS';
export const NEW_CONTACTO_ERROR = 'NEW_CONTACTO_ERROR';

export const SEARCH_CONTACT = 'SEARCH_CONTACT';
export const SEARCH_CONTACT_SUCCESS = 'SEARCH_CONTACT_SUCCESS';
export const SEARCH_CONTACT_ERROR = 'SEARCH_CONTACT_ERROR';

export const BEGIN_UPDATE_CONTACT = 'BEGIN_UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';


export const TRYING_DELETE_CONTACTO = 'TRYING_DELETE_CONTACTO';
export const CONTACTO_DELETED_SUCCESS = 'CONTACTO_DELETED_SUCCESS';
export const CONTACTO_DELETED_ERROR = 'CONTACTO_DELETED_ERROR';

export const TRYING_GET_USER = 'TRYING_GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const BEGIN_NEW_LIST = 'BEGIN_NEW_LIST';
export const NEW_LIST_SUCCESS = 'NEW_LIST_SUCCESS';
export const NEW_LIST_ERROR = 'NEW_LIST_ERROR';

export const BEGIN_GET_LIST = 'BEGIN_GET_LIST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'GET_LIST_ERROR';

export const BEGIN_GET_ONE_LIST = 'BEGIN_GET_ONE_LIST'
export const GET_ONE_LIST_SUCCESS = 'GET_ONE_LIST_SUCCESS'
export const GET_ONE_LIST_ERROR = 'GET_ONE_LIST_ERROR'

export const TRY_REMOVE_LIST = 'TRY_REMOVE_LIST';
export const TRY_REMOVE_LIST_SUCCESS = 'TRY_REMOVE_LIST_SUCCESS';
export const TRY_REMOVE_LIST_ERROR = 'TRY_REMOVE_LIST_ERROR';


export const TRY_UPDATE_LIST = 'TRY_UPDATE_LIST';
export const TRY_UPDATE_LIST_SUCCESS = 'TRY_UPDATE_LIST_SUCCESS';
export const TRY_UPDATE_LIST_ERROR = 'TRY_UPDATE_LIST_ERROR';

export const GETTING_LAYOUT = 'GETTING_LAYOUT';
export const GETTING_LAYOUT_SUCCESS = 'GETTING_LAYOUT_SUCCESS';
export const GETTING_LAYOUT_ERROR = 'GETTING_LAYOUT_ERROR';

export const CREATING_LAYOUT = 'CREATING_LAYOUT';
export const CREATING_LAYOUT_SUCCESS = 'CREATING_LAYOUT_SUCCESS';
export const CREATING_LAYOUT_ERROR = 'CREATING_LAYOUT_ERROR';

export const SEARCH_LAYOUT = 'SEARCH_LAYOUT';
export const SEARCH_LAYOUT_SUCCESS = 'SEARCH_LAYOUT_SUCCESS';
export const SEARCH_LAYOUT_ERROR = 'SEARCH_LAYOUT_ERROR';


export const TRY_SAVE_DRAW = 'TRY_SAVE_DRAW';
export const TRY_SAVE_DRAW_SUCCESS = 'TRY_SAVE_DRAW_SUCCESS';
export const TRY_SAVE_DRAW_ERROR = 'TRY_SAVE_DRAW_ERROR';

export const TRY_GET_DRAW = 'TRY_GET_DRAW';
export const TRY_GET_DRAW_SUCCESS = 'TRY_GET_DRAW_SUCCESS';
export const TRY_GET_DRAW_ERROR = 'TRY_GET_DRAW_ERROR';


export const TRY_SAVE_BASIC = 'TRY_SAVE_BASIC'
export const TRY_SAVE_BASIC_SUCCESS = 'TRY_SAVE_BASIC_SUCCESS'
export const TRY_SAVE_BASIC_ERROR = 'TRY_SAVE_BASIC_ERROR'

export const TRY_GET_BASICS = 'TRY_GET_BASICS';
export const TRY_GET_BASICS_SUCCESS = 'TRY_GET_BASICS_SUCCESS';
export const TRY_GET_BASICS_ERROR = 'TRY_GET_BASICS_ERROR';


export const TRY_SET_LAYOUT = 'TRY_SET_LAYOUT';

export const TRY_DELETE_DRAW = 'TRY_DELETE_DRAW';
export const TRY_DELETE_DRAW_SUCCESS = 'TRY_DELETE_DRAW_SUCCESS';
export const TRY_DELETE_DRAW_ERROR = 'TRY_DELETE_DRAW_ERROR';

export const BEGIN_LIST_CONTACTFIELDS = 'BEGIN_LIST_CONTACTFIELDS';
export const LIST_CONTACTFIELDS_SUCCESS = 'LIST_CONTACTFIELDS_SUCCESS';
export const LIST_CONTACTFIELDS_ERROR = 'LIST_CONTACTFIELDS_ERROR';

export const BEGIN_LIST_CLIENTSFIELDS = 'BEGIN_LIST_CLIENTSFIELDS';
export const LIST_CLIENTSFIELDS_SUCCESS = 'LIST_CLIENTSFIELDS_SUCCESS';
export const LIST_CLIENTSFIELDS_ERROR = 'LIST_CLIENTSFIELDS_ERROR';


export const BEGIN_UPDATE_CLIENTSFIELDS = 'BEGIN_UPDATE_CLIENTSFIELDS';
export const UPDATE_CLIENTSFIELDS_SUCCESS = 'UPDATE_CLIENTSFIELDS_SUCCESS';
export const UPDATE_CLIENTSFIELDS_ERROR = 'UPDATE_CLIENTSFIELDS_ERROR';

export const BEGIN_UPDATE_CONTACTSFIELDS = 'BEGIN_UPDATE_CONTACTSFIELDS';
export const UPDATE_CONTACTSFIELDS_SUCCESS = 'UPDATE_CONTACTSFIELDS_SUCCESS';
export const UPDATE_CONTACTSFIELDS_ERROR = 'UPDATE_CONTACTSFIELDS_ERROR';


export const BEGIN_GETTING_CAMPAIGNS = 'BEGIN_GETTING_CAMPAIGNS';
export const GETTING_CAMPAIGNS_SUCCESS = 'GETTING_CAMPAIGNS_SUCCESS';
export const GETTING_CAMPAIGNS_ERROR = 'GETTING_CAMPAIGNS_ERROR';


export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_ERROR = 'ADD_CAMPAIGN_ERROR';

export const BEGIN_GETTING_CAMPAIGN = 'BEGIN_GETTING_CAMPAIGN';
export const GETTING_CAMPAIGN_SUCCESS = 'GETTING_CAMPAIGN_SUCCESS';
export const GETTING_CAMPAIGN_ERROR = 'GETTING_CAMPAIGN_ERROR';


export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';

export const CREATE_LIST_CAMPAIGN = 'CREATE_LIST_CAMPAIGN';
export const CREATE_LIST_CAMPAIGN_SUCCESS = 'CREATE_LIST_CAMPAIGN_SUCCESS';
export const CREATE_LIST_CAMPAIGN_ERROR = 'CREATE_LIST_CAMPAIGN_ERROR';

export const UPDATE_LIST_CAMPAIGN = 'UPDATE_LIST_CAMPAIGN';
export const UPDATE_LIST_CAMPAIGN_SUCCESS = 'UPDATE_LIST_CAMPAIGN_SUCCESS';
export const UPDATE_LIST_CAMPAIGN_ERROR = 'UPDATE_LIST_CAMPAIGN_ERROR';


export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const TRYING_ADDING_ELEMENT_LIST = 'TRYING_ADDING_ELEMENT_LIST';
export const ADDING_ELEMENT_LIST_SUCCESS = 'ADDING_ELEMENT_LIST_SUCCESS';
export const ADDING_ELEMENT_LIST_ERROR = 'ADDING_ELEMENT_LIST_ERROR';

export const TRYING_GET_ELEMENT_LIST = 'TRYING_GET_ELEMENT_LIST';
export const GET_ELEMENT_LIST_SUCCESS = 'GET_ELEMENT_LIST_SUCCESS';
export const GET_ELEMENT_LIST_ERROR = 'GET_ELEMENT_LIST_ERROR';

export const SET_LAYOUT_TO_EDIT = 'SET_LAYOUT_TO_EDIT';

export const TRY_GET_LAYOUTS_TEXT = 'TRY_GET_LAYOUTS_TEXT';
export const GET_LAYOUTS_TEXT_SUCCESS = 'GET_LAYOUTS_TEXT_SUCCESS';
export const GET_LAYOUTS_TEXT_ERROR = 'GET_LAYOUTS_TEXT_ERROR';

export const TRY_UPDATE_CAMPAIGN = 'TRY_UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_DRAW_SUCCESS = 'UPDATE_CAMPAIGN_DRAW_SUCCESS';
export const UPDATE_CAMPAIGN_DRAW_ERROR = 'UPDATE_CAMPAIGN_DRAW_ERROR';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';

export const TRY_SEND_CAMPAIGN = 'TRY_SEND_CAMPAIGN';
export const SEND_CAMPAIGN_SUCCESS = 'SEND_CAMPAIGN_SUCCESS';
export const SEND_CAMPAIGN_ERROR = 'SEND_CAMPAIGN_ERROR';

export const TRYING_GET_USERS = 'TRYING_GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';


export const TRYING_CREATE_ACCOUNT = 'TRYING_CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';

export const TRYING_UPDATE_USER = 'TRYING_UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const TRYING_DELETE_ACCOUNT = 'TRYING_DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const TRYING_GET_DATABASES = 'TRYING_GET_DATABASES';
export const GET_DATABASES_SUCCESS = 'GET_DATABASES_SUCCESS';
export const GET_DATABASES_ERROR = 'GET_DATABASES_ERROR';


export const TRYING_UPDATE_IMAGE = 'TRYING_UPDATE_IMAGE';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_ERROR = 'UPDATE_IMAGE_ERROR';


export const TRYING_GET_IMAGE = 'TRYING_GET_IMAGE'
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS'
export const GET_IMAGE_ERROR = 'GET_IMAGE_ERROR'

export const OBTENER_USUARIO = 'OBTENER_USUARIO';

export const TRYING_GET_MEMBERSHIP = 'TRYING_GET_MEMBERSHIP';
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_ERROR = 'GET_MEMBERSHIP_ERROR';

export const TRYING_SET_MEMBERSHIP = 'TRYING_SET_MEMBERSHIP';
export const SET_MEMBERSHIP_SUCCESS = 'SET_MEMBERSHIP_SUCCESS';
export const SET_MEMBERSHIP_ERROR = 'SET_MEMBERSHIP_ERROR';


export const DELETING_LAYOUT = 'DELETING_LAYOUT';
export const DELETING_LAYOUT_SUCCESS = 'DELETING_LAYOUT_SUCCESS';
export const DELETING_LAYOUT_ERROR = 'DELETING_LAYOUT_ERROR';


export const TRY_SAVE_LANDING = 'TRY_SAVE_LANDING';
export const TRY_SAVE_LANDING_SUCCESS = 'TRY_SAVE_LANDING_SUCCESS';
export const TRY_SAVE_LANDING_ERROR = 'TRY_SAVE_LANDING_ERROR';

export const TRY_GET_LANDING = 'TRY_GET_LANDING'
export const TRY_GET_LANDING_SUCCESS = 'TRY_GET_LANDING_SUCCESS'
export const TRY_GET_LANDING_ERROR = 'TRY_GET_LANDING_ERROR'


export const TRY_GETBYID_LANDING = 'TRY_GETBYID_LANDING';
export const TRY_GETBYID_SUCCESS = 'TRY_GETBYID_SUCCESS';
export const TRY_GETBYID_ERROR = 'TRY_GETBYID_ERROR';


export const TRY_UPDATE_LANDING = 'TRY_UPDATE_LANDING';
export const TRY_UPDATE_LANDING_SUCCESS = 'TRY_UPDATE_LANDING_SUCCESS';
export const TRY_UPDATE_LANDING_ERROR = 'TRY_UPDATE_LANDING_ERROR';


export const TRY_UPDATE_DRAW_LANDING = 'TRY_UPDATE_DRAW_LANDING';
export const TRY_UPDATE_DRAW_SUCCESS = 'TRY_UPDATE_DRAW_SUCCESS';
export const TRY_UPDATE_DRAW_ERROR = 'TRY_UPDATE_DRAW_ERROR';


export const TRY_GET_LADINGSHOW_LANDING = 'TRY_GET_LADINGSHOW_LANDING';
export const TRY_GET_LADINGSHOW_SUCCESS = 'TRY_GET_LADINGSHOW_SUCCESS';
export const TRY_GET_LADINGSHOW_ERROR = 'TRY_GET_LADINGSHOW_ERROR';


export const TRY_ADDCOUNT_LANDING = 'TRY_ADDCOUNT_LANDING';
export const TRY_ADDCOUNT_SUCCESS = 'TRY_ADDCOUNT_SUCCESS';
export const TRY_ADDCOUNT_ERROR = 'TRY_ADDCOUNT_ERROR';

export const TRY_ADDVISIT_LANDING = 'TRY_ADDVISIT_LANDING';
export const TRY_ADDVISIT_SUCCESS = 'TRY_ADDVISIT_SUCCESS';
export const TRY_ADDVISIT_ERROR = 'TRY_ADDVISIT_ERROR';

export const TRY_ADDCONVERTION_LANDING = 'TRY_ADDCONVERTION_LANDING';
export const TRY_ADDCONVERTION_SUCCESS = 'TRY_ADDCONVERTION_SUCCESS';
export const TRY_ADDCONVERTION_ERROR = 'TRY_ADDCONVERTION_ERROR';


export const TRY_SAVEDESIGN_LANDING = 'TRY_SAVEDESIGN_LANDING';
export const TRY_SAVEDESIGN_SUCCESS = 'TRY_SAVEDESIGN_SUCCESS';
export const TRY_SAVEDESIGN_ERROR = 'TRY_SAVEDESIGN_ERROR';


export const TRY_GETDESIGN_LANDING = 'TRY_GETDESIGN_LANDING';
export const TRY_GETDESIGN_SUCCESS = 'TRY_GETDESIGN_SUCCESS';
export const TRY_GETDESIGN_ERROR = 'TRY_GETDESIGN_ERROR';

export const TRY_SETDESIGN_LANDING = 'TRY_SETDESIGN_LANDING';

export const TRY_DELETEDESIGN_LANDING = 'TRY_DELETEDESIGN_LANDING';
export const TRY_DELETEDESIGN_SUCCESS = 'TRY_DELETEDESIGN_SUCCESS';
export const TRY_DELETEDESIGN_ERROR = 'TRY_DELETEDESIGN_ERROR';

export const DESIGN_CAT_LANDING = 'DESIGN_CAT_LANDING';
export const DESIGN_CAT_SUCCESS = 'DESIGN_CAT_SUCCESS';
export const DESIGN_CAT_ERROR = 'DESIGN_CAT_ERROR';

export const TRY_SAVE_FORM = 'TRY_SAVE_FORM';
export const SAVE_FORM_SUCCESS = 'SAVE_FORM_SUCCESS';
export const SAVE_FORM_ERROR = 'SAVE_FORM_ERROR';

export const GET_FORMINFO = 'GET_FORMINFO';
export const GET_FORMINFO_SUCCESS = 'GET_FORMINFO_SUCCESS';
export const GET_FORMINFO_ERROR = 'GET_FORMINFO_ERROR';

export const SEND_FORM_DATA = 'SEND_FORM_DATA';
export const SEND_FORM_DATA_SUCCESS = 'SEND_FORM_DATA_SUCCESS';
export const SEND_FORM_DATA_ERROR = 'SEND_FORM_DATA_ERROR';


export const DUPLICATE = 'DUPLICATE';
export const DUPLICATE_SUCCESS = 'DUPLICATE_SUCCESS';
export const DUPLICATE_ERROR = 'DUPLICATE_ERROR';


export const TRY_SENDTESTMAIL = 'TRY_SENDTESTMAIL';
export const SENDTESTMAIL_SUCCESS = 'SENDTESTMAIL_SUCCESS';
export const SENDTESTMAIL_ERROR = 'SENDTESTMAIL_ERROR';


export const TRY_GETSTATICS = 'TRY_GETSTATICS';
export const GETSTATICS_SUCCESS = 'GETSTATICS_SUCCESS';
export const GETSTATICS_ERROR = 'GETSTATICS_ERROR';


export const SETTING_CURRENT_LANDING = 'SETTING_CURRENT_LANDING';
export const RESET_USER_CREATED = 'RESET_USER_CREATED';

export const TRY_CREATE_DESIGN = 'TRY_CREATE_DESIGN';
export const CREATE_DESIGN_SUCCESS = 'CREATE_DESIGN_SUCCESS';
export const CREATE_DESIGN_ERROR = 'CREATE_DESIGN_ERROR';

export const TRY_MARKETING_DESIGN = 'TRY_MARKETING_DESIGN';
export const MARKETING_DESIGN_SUCCESS = 'MARKETING_DESIGN_SUCCESS';
export const MARKETING_DESIGN_ERROR = 'MARKETING_DESIGN_ERROR';


export const TRY_DELETE_DESIGN = 'TRY_DELETE_DESIGN'
export const DELETE_DESIGN_SUCCESS = 'DELETE_DESIGN_SUCCESS'
export const DELETE_DESIGN_ERROR = 'DELETE_DESIGN_ERROR'

export const TRY_SET_DESIGN = 'TRY_SET_DESIGN';
export const SET_CURRENT_MENU = 'SET_CURRENT_MENU';


export const SEARCH_CUSTOM_PAGE = 'SEARCH_CUSTOM_PAGE';
export const FINISH_CUSTOM_PAGE = 'FINISH_CUSTOM_PAGE';


export const TRY_SAVE_DOMAIN = 'TRY_SAVE_DOMAIN'
export const SAVE_DOMAIN_SUCCESS = 'SAVE_DOMAIN_SUCCESS'
export const SAVE_DOMAIN_ERROR = 'SAVE_DOMAIN_ERROR'

export const TRY_GET_DOMAINS = 'TRY_GET_DOMAINS';
export const GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS';
export const GET_DOMAINS_ERROR = 'GET_DOMAINS_ERROR';


export const TRY_VERIFY_DOMAIN = 'TRY_VERIFY_DOMAIN';
export const VERIFY_DOMAIN_SUCCESS = 'VERIFY_DOMAIN_SUCCESS';
export const VERIFY_DOMAIN_ERROR = 'VERIFY_DOMAIN_ERROR';

export const SET_LANDING_VARIABLE = 'SET_LANDING_VARIABLE';


export const UPLOAD_MASIVE_EXCEL_TRY = 'UPLOAD_MASIVE_EXCEL_TRY'
export const UPLOAD_MASIVE_EXCEL_SUCCESS = 'UPLOAD_MASIVE_EXCEL_SUCCESS'
export const UPLOAD_MASIVE_EXCEL_ERROR = 'UPLOAD_MASIVE_EXCEL_ERROR'


export const SET_HOME_DATA = 'SET_HOME_DATA';

export const BEGIN_LIST_MODULESFIELDS = 'BEGIN_LIST_MODULESFIELDS';
export const LIST_MODULESFIELDS_SUCCESS = 'LIST_MODULESFIELDS_SUCCESS';
export const LIST_MODULESFIELDS_ERROR = 'LISTMODULESFIELDS_ERROR';

export const BEGIN_UPDATE_MODULESFIELDS = 'BEGIN_UPDATE_MODULESFIELDS';
export const UPDATE_MODULESFIELDS_SUCCESS = 'UPDATE_MODULESFIELDS_SUCCESS';
export const UPDATE_MODULESFIELDS_ERROR = 'UPDATE_MODULESFIELDS_ERROR';

export const UPDATE_STATE_MODULES = 'UPDATE_STATE_MODULES';