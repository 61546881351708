import React,{ useRef, useState, useEffect} from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import EmailEditor from './react-email-editor';
import { guardarDiseno, get_design_categories } from '../../actions/landingActions';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

export const NewDesign = () => {
    let history = useHistory();
    
    const dispatch = useDispatch()
    const [ busco, setBusco ] = useState(false);
    const [ nombre, setNombre ] = useState('');
    const [ imagen, setImagen ] = useState();
    const [ categoria, setCategoria ] = useState('');
    const [ selectcat, setselectcat ] = useState('');
    const emailEditorRef = useRef();
    const design = useSelector( state => state.landings.design )
    const categories = useSelector( state => state.landings.categories )

    const exportHtml = async () => {
        if(!imagen && !design){
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'La imagen es obligatoria'
            })
            return;
        }
        emailEditorRef.current.editor.exportHtml( async (data) => {
            const { html } = data;
            if(nombre){
                await dispatch(guardarDiseno(data, nombre, categoria, imagen, design))
                history.push('/design_list')
            }else{
                if(!design){

                    Swal.fire({
                        icon:'error',
                        title:'Hubo un error',
                        text:'El nombre del diseño el obligatorio.'
                    })
                }else{
                    await dispatch(guardarDiseno(data, nombre ? nombre : design.nombre, categoria ? categoria : design.categoria, imagen, design))
                    history.push('/design_list')
                }
            }
     
        });
    };

    useEffect(async ()=>{
        if(!busco){
            console.log('buscando categorias');
            await dispatch(get_design_categories());
            setBusco(true);
            
        }
    },[design,busco])

    const onLoad = () => {

        if(design && design.html && emailEditorRef){
            setTimeout (()=>{

                const html = JSON.parse(design.html);
                console.log('html',html)
                emailEditorRef.current?.editor?.loadDesign(html.design);
            }, 4000);
        }
    }
    const handleChange = (e) => {
        if(e.target.name == 'nombre'){
            setNombre(e.target.value)
        }
        if(e.target.name == 'categoria'){
            setCategoria(e.target.value)
        }
        if(e.target.name == 'setcategoria'){
            if(e.target.value){
                setCategoria(e.target.value)
            }else{
                setCategoria("")
            }
            setselectcat(e.target.value)
        }
    }

    const handleChangePreview = (e) => {
        setImagen(e.target.files[0])
    }

    
  
    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">{design ? 'Editar Diseño' : 'Nuevo Diseño'}</span>
                <button  className="btn btn-success float-right ml-2" onClick={ () => exportHtml() } > {design ? 'Actualizar Diseño' : 'Guardar Diseño'}</button>
                <Link to="/design_list" className="btn btn-link float-right " > Volver</Link>
            </div>
            <div
                className="row"
            >
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="text" className="form-control-lg form-control" required name="nombre" value={nombre} onChange={ handleChange } placeholder="Nombre de diseño"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input type="text" className="form-control-lg form-control" required name="categoria" value={categoria} onChange={ handleChange } style={{fontSize:"18px"}} placeholder="Categoria Seleccionada"/> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <select className="form-control-lg form-control" required name="setcategoria" value={selectcat} onChange={ handleChange }>
                                    <option value="">Seleccione una Categoria</option>
                                    {
                                        categories && categories.map( category => (
                                            <option value={category.categoria}>{category.categoria}</option>
                                        ))
                                    }
                                </select> 
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label>Imagen</label>
                            <input type="file" name="preview" onChange={ handleChangePreview }/>
                        </div>
                    </div>
                </div>
            </div>
            <EmailEditor
                tools={{
                    form:{
                        properties:{
                            fields:{
                                editor: {
                                    data: {
                                        defaultFields: [{
                                        "name": "nombre",
                                        "type": "text",
                                        "label": "Nombre",
                                        "placeholder_text": "Introduce el nombre",
                                        "show_label": true,
                                        "required": true
                                        },{
                                        "name": "correo",
                                        "type": "email",
                                        "label": "Correo electrónico",
                                        "placeholder_text": "Introduce el Correo electrónico",
                                        "show_label": true,
                                        "required": true
                                        },{
                                        "name": "telefono",
                                        "type": "text",
                                        "label": "Teléfono",
                                        "placeholder_text": "Introduce el número telefono",
                                        "show_label": true,
                                        "required": true
                                        }, {
                                        "name": "razon_social",
                                        "type": "text",
                                        "label": "Razón social",
                                        "placeholder_text": "Introduce Razón social",
                                        "show_label": true,
                                        "required": true
                                        }, {
                                        "name": "web",
                                        "type": "text",
                                        "label": "Web",
                                        "placeholder_text": "Introduce dirección web",
                                        "show_label": true,
                                        "required": false
                                        }]
                                    }
                                    },
                                value:[{
                                    "name": "nombre",
                                    "type": "text",
                                    "label": "Nombre",
                                    "placeholder_text": "Introduce el nombre",
                                    "show_label": true,
                                    "required": true
                                    },{
                                    "name": "correo",
                                    "type": "email",
                                    "label": "Correo electrónico",
                                    "placeholder_text": "Introduce el Correo electrónico",
                                    "show_label": true,
                                    "required": true
                                    },{
                                    "name": "telefono",
                                    "type": "text",
                                    "label": "Teléfono",
                                    "placeholder_text": "Introduce el número telefono",
                                    "show_label": true,
                                    "required": true
                                    }, {
                                    "name": "razon_social",
                                    "type": "text",
                                    "label": "Razón social",
                                    "placeholder_text": "Introduce Razón social",
                                    "show_label": true,
                                    "required": true
                                    }, {
                                    "name": "web",
                                    "type": "text",
                                    "label": "Web",
                                    "placeholder_text": "Introduce dirección web",
                                    "show_label": true,
                                    "required": false
                                    }],
                            }
                        }
                    }
                }}
                onLoad={onLoad}
                ref={emailEditorRef}
                minHeight={`1100px`}
            />
        </div>
    )
}
