import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LoaderSpinner } from '../../components/LoaderSpinner';
import Swal from 'sweetalert2';


// redux
import { useSelector, useDispatch } from 'react-redux';

import { obtenerCampaign, actualizarCampaign } from '../../actions/campaignsActions';
import $ from 'jquery'
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';

export const EditCampaign = () => {

    const dispatch = useDispatch()
    const loading = useSelector( state => state.campaigns.loading )
    const campaignSearch = useSelector( state => state.campaigns.campaignedit )

    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [campaignId ,setCampaignId] = useState(null);

    const [ campaign, setCampaign ] = useState({
        nombre:'',
        asunto:'',
        remitente:'',
        email_remitente:''
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(campaign.nombre.trim() === '' || campaign.asunto.trim() === '' || campaign.remitente.trim() === '' || campaign.email_remitente.trim() === '') {
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Todos los campos son obligatorios'
            })

            return;
        }

        await dispatch(actualizarCampaign(campaign));

        

    }

    const handleChange = (e) => {
        setCampaign({
            ...campaign,
            [e.target.name] : e.target.value
        })
    }



    useEffect(()=>{
        // console.log('window.location.pathname',)
        const idTrozos = window.location.pathname.split('/');
        // console.log('idTrozos',idTrozos)
        if(idTrozos.length === 3){
            setCampaignId(idTrozos[2]);
        }
        if(campaignId && campaignSearch === null){
            console.log('campaignId',campaignId)
            dispatch(obtenerCampaign(campaignId))
        }
        if(campaignSearch){
            setCampaign(campaignSearch);
        }
        
if(search_custom_page == null){
    $('#loading').hide();
  }
  
    },[campaignId,campaignSearch,search_custom_page])

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
            <BreadCrumbCampaign active={1}/>

                <span className="mr-3 naranja">Editar Campaña</span>
                <Link to="/mail_marketing" className="btn btn-link float-right colorsito" >Volver</Link>
                {
                    loading ? <LoaderSpinner/> : null
                }
                <form onSubmit={ handleSubmit } className="mt-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Nombre</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.nombre } onChange={ handleChange } name="nombre" placeholder="Nombre" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Asunto</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.asunto } onChange={ handleChange } name="asunto" placeholder="Asunto"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Nombre Remitente</label>
                                <input type="text" className="form-control-lg form-control" value={ campaign.remitente } onChange={ handleChange } name="remitente" placeholder="Nombre Remitente" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Email Remitente</label>
                                <input type="email" className="form-control-lg form-control" value={ campaign.email_remitente } onChange={ handleChange } name="email_remitente" placeholder="Email Remitente"/>
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="btn btn-primary mt-4" value="Actualizar"/>
                </form>
            </div>
            
        </div>
    )
}
