import {
    BEGIN_LIST_CONTACTFIELDS,
    LIST_CONTACTFIELDS_SUCCESS,
    LIST_CONTACTFIELDS_ERROR,

    BEGIN_LIST_CLIENTSFIELDS,
    LIST_CLIENTSFIELDS_SUCCESS,
    LIST_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CLIENTSFIELDS,
    UPDATE_CLIENTSFIELDS_SUCCESS,
    UPDATE_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CONTACTSFIELDS,
    UPDATE_CONTACTSFIELDS_SUCCESS,
    UPDATE_CONTACTSFIELDS_ERROR,

    BEGIN_UPDATE_MODULESFIELDS,
    UPDATE_MODULESFIELDS_SUCCESS,
    UPDATE_MODULESFIELDS_ERROR,
    
    UPDATE_STATE_MODULES,
    LIST_MODULESFIELDS_ERROR,
    LIST_MODULESFIELDS_SUCCESS,
    BEGIN_LIST_MODULESFIELDS
}
from '../types';


// cada reducer tiene su propio state

const initialState = {
    contactsfields:[],
    clientsfields:[],
    modulesfields:[],
    type:null,
    error:null,
    loading:false
}

export default function (state = initialState, action){
    switch(action.type){
        case BEGIN_LIST_CONTACTFIELDS:
            return {
                ...state,
                loading:true,
                fields:[]
            }
        case LIST_CONTACTFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                contactsfields:action.payload
            }  
        case LIST_CONTACTFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                contactsfields:[],
                error:action.payload
            }   

        case BEGIN_LIST_CLIENTSFIELDS:
            return {
                ...state,
                loading:true,
                fields:[]
            }
        case LIST_CLIENTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                clientsfields:action.payload
            }  
        case LIST_CLIENTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                clientsfields:[],
                error:action.payload
            }   
        case BEGIN_LIST_MODULESFIELDS:
            return {
                ...state,
                loading:true,
                fields:[]
            }
        case LIST_MODULESFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                modulesfields:action.payload
            }  
        case LIST_MODULESFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                modulesfields:[],
                error:action.payload
            }        
        case BEGIN_UPDATE_CLIENTSFIELDS:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CLIENTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                clientsfields:action.payload
            }
        case UPDATE_CLIENTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

        case BEGIN_UPDATE_CONTACTSFIELDS:
            return {
                ...state,
                loading:true
            }
        case UPDATE_CONTACTSFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                contactsfields:action.payload
            }
        case UPDATE_CONTACTSFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }  
        case BEGIN_UPDATE_MODULESFIELDS:
            return {
                ...state,
                loading:true
            }
        case UPDATE_MODULESFIELDS_SUCCESS:
            return {
                ...state,
                loading:false,
                modulesfields:action.payload
            }
        case UPDATE_MODULESFIELDS_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case UPDATE_STATE_MODULES:

            const module_state = state.modulesfields.filter(module => module.id == action.payload.id);            
            module_state[0].estado = action.payload.estado
            const module_find_index = state.modulesfields.findIndex(module => module.id == action.payload.id);
            let new_modules_fields = state.modulesfields
            new_modules_fields[module_find_index] = module_state[0]
            
            return {
                ...state,
                loading:false,
                modulesfields:new_modules_fields
            }     
        default:
            return state;
    }
}