import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { LoaderSpinner } from '../../components/LoaderSpinner';

import { obtenerListasAction, borrarListaAction } from '../../actions/listasActions';


import $ from 'jquery'

export const MailList = () => {

    const dispatch = useDispatch()
    const listas = useSelector( state => state.listas.listas )
    const loading = useSelector( state => state.listas.loading )
    const search_custom_page = useSelector( state => state.landings.search_custom_page )

    const [ hacerConsulta, setHacerConsulta ] = useState(true);
    
    useEffect(() => {
        if(hacerConsulta){
            dispatch(obtenerListasAction());
            setHacerConsulta(false)

        }
        if(search_custom_page == null){
            $('#loading').hide();
          }
          
    },[hacerConsulta,search_custom_page])

    const handleRemove = ( id ) => {
        dispatch(borrarListaAction(id));
        setHacerConsulta(true)
    }

    return (
        <div className="white_box QA_section card_height_100">
            <div className="p-4">
                <span className="mr-3 naranja">Listas</span>
                
                <Link to="/maillist_create" class="btn btn-primary float-right" ><span className="fas fa-plus "></span>   Crear Lista</Link>
                <Link to="/mail_marketing" class="float-right p-2 mr-4 colorsito">Volver</Link>
            </div>
            {
                loading ? <LoaderSpinner/> : null
            }
            <table className="table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Creada Por</th>
                        <th>Descripción</th>
                        <th>Correos Electrónicos</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listas ? listas.map( (fila) => (
                            <tr key={fila.id}>
                                <td>{fila.nombre}</td>
                                <td>{fila.tipo}</td>
                                <td>{fila.creado && JSON.parse(fila.creado)?.nombre}</td>
                                <td>{fila.descripcion}</td>
                                <td>{fila.nombre}</td>
                                <td>
                                    <div className="input-group">
                                        <Link to={`/view_maillist/${fila.id}`} className="btn btn-primary btn-sm"><i className="nav-icon fas fa-eye"></i></Link>
                                        <span onClick={ () => handleRemove(fila.id) }  className="btn btn-danger btn-sm"><i className="nav-icon fas fa-remove"></i></span>
                                        <Link to={`/edit_maillist/${fila.id}`} className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit"></i></Link>
                                    </div>
                                </td>
                            </tr>
                        )) : null
                    }
                </tbody>
            </table>
        </div>
    )
}
