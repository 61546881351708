import {
    BEGIN_LIST_CONTACTFIELDS,
    LIST_CONTACTFIELDS_SUCCESS,
    LIST_CONTACTFIELDS_ERROR,

    BEGIN_LIST_CLIENTSFIELDS,
    LIST_CLIENTSFIELDS_SUCCESS,
    LIST_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CLIENTSFIELDS,
    UPDATE_CLIENTSFIELDS_SUCCESS,
    UPDATE_CLIENTSFIELDS_ERROR,

    BEGIN_UPDATE_CONTACTSFIELDS,
    UPDATE_CONTACTSFIELDS_SUCCESS,
    UPDATE_CONTACTSFIELDS_ERROR,

    BEGIN_UPDATE_MODULESFIELDS,
    UPDATE_MODULESFIELDS_SUCCESS,
    UPDATE_MODULESFIELDS_ERROR,
    
    UPDATE_STATE_MODULES,
    BEGIN_LIST_MODULESFIELDS,
    LIST_MODULESFIELDS_SUCCESS,
    LIST_MODULESFIELDS_ERROR
}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerContactFieldsAction(){
    return async ( dispatch ) => {
        dispatch( descargarFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.get(`/contacts_fields`);

            dispatch( descargaFieldsExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarFields = () => ({
    type:BEGIN_LIST_CONTACTFIELDS,
    payload:true
})

const descargaFieldsExitosa = (FIELDS) => ({
    type:LIST_CONTACTFIELDS_SUCCESS,
    payload:FIELDS
})

const descargaFieldsError = () => ({
    type:LIST_CONTACTFIELDS_ERROR,
    payload:true   
})

export function obtenerClientsFieldsAction(){
    return async ( dispatch ) => {
        dispatch( descargarClientsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.get(`/clients_fields`);

            dispatch( descargaClientsFieldsExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaClientsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarClientsFields = () => ({
    type:BEGIN_LIST_CLIENTSFIELDS,
    payload:true
})

const descargaClientsFieldsExitosa = (FIELDS) => ({
    type:LIST_CLIENTSFIELDS_SUCCESS,
    payload:FIELDS
})

const descargaClientsFieldsError = () => ({
    type:LIST_CLIENTSFIELDS_ERROR,
    payload:true   
})

export function obtenerModulesFieldsAction(){
    return async ( dispatch ) => {
        dispatch( descargarModulesFields() );
        try {

            const respuesta = await clienteAxios.get(`/modules_fields`);

            dispatch( descargaModulesFieldsExitosa(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( descargaModulesFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const descargarModulesFields = () => ({
    type:BEGIN_LIST_MODULESFIELDS,
    payload:true
})

const descargaModulesFieldsExitosa = (FIELDS) => ({
    type:LIST_MODULESFIELDS_SUCCESS,
    payload:FIELDS
})

const descargaModulesFieldsError = () => ({
    type:LIST_MODULESFIELDS_ERROR,
    payload:true   
})

export function actualizarClientsFieldsAction( clientsFieds ){
    return async ( dispatch ) => {
        dispatch( actualizarClientsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.put(`/clients_fields`,clientsFieds);

            dispatch( actualizarClientsFieldsExitosa(respuesta.data) )
            
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El campo se actualizó con éxito'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarClientsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const actualizarClientsFields = () => ({
    type:BEGIN_UPDATE_CLIENTSFIELDS,
    payload:true
})

const actualizarClientsFieldsExitosa = (FIELDS) => ({
    type:UPDATE_CLIENTSFIELDS_SUCCESS,
    payload:FIELDS
})

const actualizarClientsFieldsError = () => ({
    type:UPDATE_CLIENTSFIELDS_ERROR,
    payload:true   
})

export function actualizarContactosFieldsAction( contactsFieds ){
    return async ( dispatch ) => {
        dispatch( actualizarContactsFields() );
        try {
            // contacts_fields;
            // clients_fields;
            const respuesta = await clienteAxios.put(`/contacts_fields`,contactsFieds);

            dispatch( actualizarContactsFieldsExitosa(respuesta.data) )

            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El campo se actualizó con éxito'
            })

        } catch (error) {
            console.log(error)

            dispatch( actualizarContactsFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const actualizarContactsFields = () => ({
    type:BEGIN_UPDATE_CONTACTSFIELDS,
    payload:true
})

const actualizarContactsFieldsExitosa = (FIELDS) => ({
    type:UPDATE_CONTACTSFIELDS_SUCCESS,
    payload:FIELDS
})

const actualizarContactsFieldsError = () => ({
    type:UPDATE_CONTACTSFIELDS_ERROR,
    payload:true   
})

export function actualizarModulesFieldsAction( modulesFieds ){
    return async ( dispatch ) => {
        dispatch( actualizarModulesFields() );
        try {

            const respuesta = await clienteAxios.put(`/modules_fields`,modulesFieds);

            dispatch( actualizarModulesFieldsExitosa(respuesta.data) )
            
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El campo se actualizó con éxito'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarModulesFieldsError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }
}

const actualizarModulesFields = () => ({
    type:BEGIN_UPDATE_MODULESFIELDS,
    payload:true
})

const actualizarModulesFieldsExitosa = (FIELDS) => ({
    type:UPDATE_MODULESFIELDS_SUCCESS,
    payload:FIELDS
})

const actualizarModulesFieldsError = () => ({
    type:UPDATE_MODULESFIELDS_ERROR,
    payload:true   
})

export function actualizarEstadoModulesAction( estado, id ){
    
    return async ( dispatch ) => {
        try {

            // dispatch( actualizarEstadoModules(estado, id) );
            const respuesta = await clienteAxios.post(`/modules_fields/update_module`,{estado, id});
            dispatch( descargaModulesFieldsExitosa(respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Se ha actualizado el estado correctamente!'
            })
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, no se ha podido actualizar el estado'
            })
        }
    }
    
}