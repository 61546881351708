import React, { useRef, useState, useEffect } from 'react'
import EmailEditor from '../lading_pages/react-email-editor';
import { Link, useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LoaderSpinner } from '../../components/LoaderSpinner';
import { v4 as uuidv4 } from 'uuid';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../screens/campaigns/textEditor.css';
import $ from 'jquery'

import { guardarDiseno, obtenerDisenoLista, guardarBasico } from '../../actions/listasActions';
import { actualizarDisenoCampana, get_marketing_designs, set_design } from '../../actions/campaignsActions';
import { crearPlantillaAction } from '../../actions/plantillasActions';

import { useSelector, useDispatch } from 'react-redux';
import clienteAxios from '../../config/axios';
import { BreadCrumbCampaign } from '../../components/BreadCrumbCampaign';

export const CampaignEditor = () => {
 
        let history = useHistory();

        const layoutEdit = useSelector( state => state.plantillas.layoutEdit )
        const designs = useSelector( state => state.campaigns.designs )
        const search_custom_page = useSelector( state => state.landings.search_custom_page )

        const emailEditorRef = useRef();
        const dispatch = useDispatch()
    
        const idTrozos = window.location.pathname.split('/');
        const [ modalVisible, setModalVisible ] = useState(false);
        const [ ckeditorData, setCkeditorData ] = useState();
        const [ obtuvo, setObtuvo] = useState(false);
        const [ disenoLista, setDisenoLista ] = useState({
          id:null,
          html:null,
          design:null
        })
    
        const loading = useSelector( state => state.listas.loading )
        const draw = useSelector( state => state.listas.draw )
        const error = useSelector( state => state.listas.error )
        const layoutname = useSelector( state => state.listas.layoutname )
        const [busco, setBusco] = useState(false);
        
    
    
        const [ diseno, setDiseno ] = useState({
          id:uuidv4(),
          _name:null,
          type:null,
          html:null,
          design:null
        })
    
        useEffect( async () => {
          if(!busco){
              await dispatch(get_marketing_designs("sinhtml"));
              setBusco(true)
          }
          if(obtuvo === false && layoutname){
            console.log('layoutname',layoutname)
            await dispatch(obtenerDisenoLista(layoutname.id))
            setObtuvo(true)
            
          }
          if(layoutname && layoutname.contenido && layoutname.tipo != 'texto'){
            setTimeout(() => {
              if(emailEditorRef && emailEditorRef.current && emailEditorRef.current?.editor){
    
                emailEditorRef.current?.editor.loadDesign(JSON.parse(layoutname.contenido).design);
              }
            },2000)
          }
          if(layoutname?.tipo == 'texto' || idTrozos[3] == 'plaintext'){
           
            setCkeditorData(layoutname?.contenido ? layoutname?.contenido : "<p>Escribe Aqui</p>");
          }
          if(search_custom_page == null){
            $('#loading').hide();
          }
          
        },[draw,busco,designs,search_custom_page])
    
        const exportHtml = async () => {
          emailEditorRef.current.editor.exportHtml( async (data) => {
            const { design, html } = data;
            // console.log('exportHtml', html);
            let layoutContenido = layoutEdit;
            layoutContenido.contenido = data
            await dispatch(crearPlantillaAction(layoutContenido))
            // setDiseno({
            //   ...diseno,
            //   html,
            //   design
            // })
            // setModalVisible(true)
          });
        };
    
        const handleChange = (e) => {
          setDiseno({
            ...diseno,
            [e.target.name] : e.target.value
          })
        }
        const cerrarVentana = () => {
          setModalVisible(false)
    
        }
        const guardarDisenoSubmit = (e) => {
          if(diseno.type === null || diseno._name === null){
                  //alerta de error
         
            Swal.fire({
              icon:'error',
              title:'Hubo un error',
              text:'Los campos para agregar el diseño son obligatorios'
            })
            return;
          }
          dispatch(guardarBasico(diseno));
          setModalVisible(false)
    
        }
        
        const guardarALaLista = (e) => {
          emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            // console.log('exportHtml', html);
            setDisenoLista({
              id:idTrozos[2],
              design,
              html
            })
    
            dispatch(guardarDiseno(disenoLista))
            setModalVisible(false)
            
          });
          // console.log(idTrozos[2])
        }
        const onLoad = () => {
          // you can load your template here;
          // const templateJson = draw;
          // console.log('mailEditorRef.current',mailEditorRef.current)
          
          if(layoutname && emailEditorRef && emailEditorRef.current ){
            // console.log('layoutname.design',layoutname.design)
            if(layoutname.design){

              emailEditorRef.current?.editor.loadDesign(JSON.parse(layoutname.design));
            }
          }
          setTimeout(()=>{
            console.log("loco")
            $('.blockbuilder-branding > .brand').textContent='CRM Impulsa'
          },15000)
        };
        const guardar = async () => {
            let data;
          if(layoutname && layoutname.tipo == 'texto' || idTrozos[3] == 'plaintext'){
            data = ckeditorData;
            await dispatch(actualizarDisenoCampana(idTrozos[2],data))
              history.push(`/campaign_design/view/${idTrozos[2]}`)
          }else{
            emailEditorRef.current.editor.exportHtml( async (data) => {
                const { design, html } = data;
                await dispatch(actualizarDisenoCampana(idTrozos[2],data))
                history.push(`/campaign_design/view/${idTrozos[2]}`)
              });
              
          }
          
        }
        const showTemplates = () => {
          setModalVisible(true)
          
        }
        const handleClick = async (design) => {
          // console.log('design',design)
          const designQuery = await clienteAxios.get(`/campaigns/get_marketing_designs/?gethtml=${design.id}`);
          // console.log('designQuery',designQuery.data)
          const html = JSON.parse(designQuery.data.html);
          // console.log('html',html)
          emailEditorRef.current?.editor?.loadDesign(html.design);
    
        }
        const setTemplate = () => {
          cerrarVentana();
        }
        return (
            <div>
                <div className="p-4">
                <BreadCrumbCampaign active={4}/>

                    <span className="mr-3 naranja">Editor</span>

                    
                    {
                        layoutname && layoutname.tipo == 'texto' || idTrozos[3] == 'plaintext' ? 
                        <Link to={`/campaign_design/plaintext/${idTrozos[2]}`} className="float-right p-2 mr-4 colorsito">Volver</Link>
                        :
                        <>
                          <button onClick={guardar} className="btn btn-success mr-2 float-right">Guardar</button>
                          <button className="btn btn-secondary float-right mr-2" onClick={()=>showTemplates()}>Cargar Plantilla Predefinida</button>
                          <Link to={`/campaign_design/html/${idTrozos[2]}`} className="float-right p-2 mr-4 colorsito">Volver</Link>

                        </>
                    }
                    
                </div>
                {
                    loading ? <LoaderSpinner/> : null
                }
                <div>
                </div>
                {
                  layoutname && layoutname.tipo == 'texto' || idTrozos[3] == 'plaintext' ? 
                    <>
                      <CKEditor
                          editor={ ClassicEditor }
                          data={ layoutname?.contenido ? layoutname?.contenido : "<p>Escribe Aqui</p>"}
                        
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setCkeditorData(data)
                              console.log( { event, editor, data } );
                          } }
                          onBlur={ ( event, editor ) => {
                              console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              console.log( 'Focus.', editor );
                          } }
                      />
                      <button className="btn btn-success mt-3" onClick={guardar}>Guardar</button>
                    </>
                  :
                  <>
                    <EmailEditor
                      tools={{
                        form:{
                          enabled:false
                        }
                      }}
                      ref={emailEditorRef}
                      onLoad={onLoad}
                      minHeight={`1100px`}
                    />
                    <Modal show={modalVisible}  onHide={cerrarVentana} animation={false} size="lg">
                      <div className="modal-header">
                          <h5 className="modal-title naranja">Selecciona una plantilla</h5>
                        </div>
                        <div className="modal-body row">
                          {
                            designs && designs.length ? designs.map( design => (
                                  <div className="col-md-3">
                                     
                                      <div class="card hover" style={{"width": "11rem"}} key={design.id} onClick={ () => handleClick(design) }>
                                        <img class="card-img-top" src={`${process.env.REACT_APP_BACKEND}/campaigns/get_image_template/${design.id}`} alt={design.id}/>
                                        <div class="card-body">
                                          <h5 class="card-title">{design.categoria}</h5>
                                          <p class="card-text">{design.nombre}</p>
                                        </div>
                                      </div>
                                  </div> 
                                    
                              )) : <span>Cargando diseños disponibles... ...</span>
                          }
                  

                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={ cerrarVentana } className="btn btn-default">
                            Cerrar
                          </button>
                          <button type="button" onClick={ setTemplate } className="btn btn-primary">
                            Listo
                          </button>
                        </div>
                  </Modal>
                  </>
                }
    
                {/* <button onClick={guardarALaLista} className="btn btn-primary ml-3 mt-4 mb-5">Guardar</button> */}
          </div>
        )
}
