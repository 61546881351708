import {
    TRY_SAVE_LANDING,
    TRY_SAVE_LANDING_SUCCESS,
    TRY_SAVE_LANDING_ERROR,

    TRY_GET_LANDING,
    TRY_GET_LANDING_SUCCESS,
    TRY_GET_LANDING_ERROR,

    TRY_GETBYID_LANDING,
    TRY_GETBYID_SUCCESS,
    TRY_GETBYID_ERROR,

    TRY_UPDATE_LANDING,
    TRY_UPDATE_LANDING_SUCCESS,
    TRY_UPDATE_LANDING_ERROR,

    TRY_UPDATE_DRAW_LANDING,
    TRY_UPDATE_DRAW_SUCCESS,
    TRY_UPDATE_DRAW_ERROR,

    TRY_GET_LADINGSHOW_LANDING,
    TRY_GET_LADINGSHOW_SUCCESS,
    TRY_GET_LADINGSHOW_ERROR,

    TRY_ADDCOUNT_LANDING,
    TRY_ADDCOUNT_SUCCESS,
    TRY_ADDCOUNT_ERROR,


    TRY_ADDVISIT_LANDING,
    TRY_ADDVISIT_SUCCESS,
    TRY_ADDVISIT_ERROR,

    TRY_ADDCONVERTION_LANDING,
    TRY_ADDCONVERTION_SUCCESS,
    TRY_ADDCONVERTION_ERROR,

    TRY_SAVEDESIGN_LANDING,
    TRY_SAVEDESIGN_SUCCESS,
    TRY_SAVEDESIGN_ERROR,

    TRY_GETDESIGN_LANDING,
    TRY_GETDESIGN_SUCCESS,
    TRY_GETDESIGN_ERROR,
    TRY_SETDESIGN_LANDING,

    TRY_DELETEDESIGN_LANDING,
    TRY_DELETEDESIGN_SUCCESS,
    TRY_DELETEDESIGN_ERROR,

    DESIGN_CAT_LANDING,
    DESIGN_CAT_SUCCESS,
    DESIGN_CAT_ERROR,

    GET_FORMINFO,
    GET_FORMINFO_SUCCESS,
    GET_FORMINFO_ERROR,

    SEND_FORM_DATA,
    SEND_FORM_DATA_SUCCESS,
    SEND_FORM_DATA_ERROR,

    DUPLICATE,
    DUPLICATE_SUCCESS,
    DUPLICATE_ERROR,

    SETTING_CURRENT_LANDING,

    SEARCH_CUSTOM_PAGE,
    FINISH_CUSTOM_PAGE,

    SET_LANDING_VARIABLE

    }
from '../types';

import $ from 'jquery';
// cada reducer tiene su propio state

const initialState = {
    landings: [],
    landing:null,
    landingEdit:null,
    error:null,
    loading:false,
    draw:null,
    show:null,
    designs:[],
    design:null,
    categories:null,
    forminfo:null,
    current_landing:null,
    account_info:null,
    search_custom_page:null
}

export default function (state = initialState, action){
    switch(action.type){
        case SET_LANDING_VARIABLE:
            return{
                ...state,
                landing:null,
                landingEdit:null
            }
        case SETTING_CURRENT_LANDING:
            console.log('action.payload',action.payload)
            return {
                ...state,
                current_landing:action.payload.landing,
                forminfo:action.payload.form,
                account_info:action.payload.account_info,
            }
        case TRY_SAVE_LANDING:
            return {
                ...state,
                loading:true,
                landing:null
            }
        case TRY_SAVE_LANDING_SUCCESS:
            return {
                ...state,
                loading:false,
                landing:action.payload,
                error:null
            }
        case TRY_SAVE_LANDING_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }

            case TRY_GET_LANDING:
                return {
                    ...state,
                    landings:[],
                    landingEdit:null,
                    landing:null,
                    loading:true
                }
            case TRY_GET_LANDING_SUCCESS:
                return {
                    ...state,
                    landings:action.payload,
                    loading:false
                }
            case TRY_GET_LANDING_ERROR:
                return {
                    ...state,
                    landings:[],
                    loading:false

                }
            case TRY_GETBYID_LANDING:
                return {
                    ...state,
                    loading:true,
                    landingEdit:null
                }
            case TRY_GETBYID_SUCCESS:
                return {
                    ...state,
                    landingEdit:action.payload,
                    loading:false
                }
            case TRY_GETBYID_ERROR:
                return {
                    ...state,
                    loading:false
                }
            case TRY_UPDATE_LANDING:
                return {
                    ...state,
                    loading:true
                }
            case TRY_UPDATE_LANDING_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    landingEdit:null
                }
            case TRY_UPDATE_LANDING_ERROR:
                return {
                    ...state,
                    loading:false,
                    landingEdit:null
                }
            case TRY_UPDATE_DRAW_LANDING:
                return {
                    ...state,
                    loading:true
                }
            case TRY_UPDATE_DRAW_SUCCESS:
                return {
                    ...state,
                    loading:false
                }
            case TRY_UPDATE_DRAW_ERROR:
                return {
                    ...state,
                    loading:false
                }
            case DUPLICATE:
            case TRY_GET_LADINGSHOW_LANDING:
                return {
                    ...state,
                    loading:true,
                    show:null
                }
            case DUPLICATE_SUCCESS:
            case TRY_GET_LADINGSHOW_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    show:action.payload
                }
            case TRY_GET_LADINGSHOW_ERROR:
                return {
                    ...state,
                    loading:false,
                    show:null
                }
            case TRY_ADDCOUNT_LANDING:
            case TRY_ADDCOUNT_SUCCESS:
            case TRY_ADDCOUNT_ERROR:
            case TRY_ADDVISIT_SUCCESS:
            case TRY_ADDVISIT_ERROR:
            case TRY_ADDVISIT_LANDING:
            case TRY_ADDCONVERTION_LANDING:
            case TRY_ADDCONVERTION_SUCCESS:
            case TRY_ADDCONVERTION_ERROR:
            
            
            case DUPLICATE_ERROR:
                return{
                    ...state
                }
            case TRY_SAVEDESIGN_LANDING:
                return {
                    ...state
                }
            case TRY_SAVEDESIGN_SUCCESS:
                return {
                    ...state
                }
            case TRY_SAVEDESIGN_ERROR:
                return {
                    ...state
                }
            case TRY_GETDESIGN_LANDING:
                return {
                    ...state,
                    loading:true,
                    designs:[],
                    design:null

                }
            case TRY_GETDESIGN_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    designs:action.payload
                }
            case TRY_GETDESIGN_ERROR:
                return {
                    ...state,
                    loading:false,
                    designs:[]

                }
            case TRY_SETDESIGN_LANDING:
                return {
                    ...state,
                    design:action.payload
                }
            case TRY_DELETEDESIGN_LANDING:
                return {
                    ...state,
                    loading:true
                }
            case TRY_DELETEDESIGN_SUCCESS:
                return {
                    ...state,
                    loading:false
                }
            case TRY_DELETEDESIGN_ERROR:
                return {
                    ...state,
                    loading:false
                }
            case DESIGN_CAT_LANDING:
                return {
                    ...state,
                    categories:null
                }
            case DESIGN_CAT_SUCCESS:
                return {
                    ...state,
                    categories: action.payload
                }
            case DESIGN_CAT_ERROR:
                return {
                    ...state,
                    categories:null
                }
            case GET_FORMINFO:
                return {
                    ...state,
                    forminfo:null
                }
            case GET_FORMINFO_SUCCESS:
                return {
                    ...state,
                    forminfo:action.payload
                }
            case GET_FORMINFO_ERROR:
                return {
                    ...state,
                    forminfo:null,
                    error:action.payload
                }
            case SEND_FORM_DATA:
                return {
                    ...state
                }
            case SEND_FORM_DATA_SUCCESS:
                return {
                    ...state
                }
            case SEND_FORM_DATA_ERROR:
                return {
                    ...state
                }
            case SEARCH_CUSTOM_PAGE:
                $('#loading').show();
                return {
                    ...state,
                    search_custom_page:true
                }
            case FINISH_CUSTOM_PAGE:
                // if(state.search_custom_page == true){
                    $('#loading').hide();
                // }
                return {
                    ...state,
                    search_custom_page:false

                }
        default:
            return state;
    }
}

