import React, { useEffect, useState } from 'react';
import clienteAxios from '../config/axios';
import {
    Link,
    useHistory
  } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery'
import { TopStatistics } from '../components/TopStatistics';
import { setHomeDataAction } from '../actions/authActions';
export const HomeScreen = () => {
    const dispatch = useDispatch()

    const userAuth = useSelector( state => state.auth.user )
    const home_data = useSelector( state => state.auth.home_data )
    
    const search_custom_page = useSelector( state => state.landings.search_custom_page )
    
    const [busco, setBusco] = useState(false);
    const [data, setData] = useState(false);
    useEffect(async ()=>{
        if(!busco){
            try {
                const response = await clienteAxios.get('/dashboard');
                // console.log('response',response.data)
                setData(response.data.data);
                dispatch(setHomeDataAction(response.data.data));
                setBusco(true);
            } catch (error) {
                console.log('error',error)
                
            }
        }
        if(search_custom_page == null){
            $('#loading').hide();
        }
    },[search_custom_page])
    return (
  
        
        <div className="row justify-content-center">
            <div className="row">
                <div className="col-lg-4 click_card">
                    <div className="white_box QA_section card_height_100">
                        <div className="box_header m-0">
                            <div className="main-title">
                                <h3 className="m-0">Mail Marketing</h3>
                            </div>
                        </div>
                        <div className="QA_table ">
                            <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                                <table className="resume_table table lms_table_active2 dataTable no-footer dtr-inline" role="grid">
                                    <tbody>
                                    {data && data.mailMarketing && data.mailMarketing.length ? data.mailMarketing.map( mail => (
                                        <tr role="row" className="odd"><td><Link className="home_item_text" to={`campaign_design/view/${mail.id}`}>{mail.nombre}</Link></td></tr>
                                    )): null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-4 click_card">
                    <div className="white_box QA_section card_height_100">
                        <div className="box_header m-0">
                            <div className="main-title">
                                <h3 className="m-0">Landing Page</h3>
                            </div>
                        </div>
                        <div className="QA_table ">
                            <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                                <table className="resume_table table lms_table_active2 dataTable no-footer dtr-inline" role="grid">
                                    <tbody>
                                        
                                    {data && data.landingPages && data.landingPages.length ? data.landingPages.map( landing => (
                                        <tr role="row" className="odd"><td><Link className="home_item_text" to={`edit_landing/${landing.id}`}>{landing.nombre}</Link></td></tr>
                                    )): null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-4 click_card">
                    <div className="white_box QA_section card_height_100">
                        <div className="box_header m-0">
                            <div className="main-title">
                                <h3 className="m-0">Clientes</h3>
                            </div>
                        </div>
                        <div className="QA_table ">
                            <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                                <table className="resume_table table lms_table_active2 dataTable no-footer dtr-inline" role="grid">
                                    <tbody>
                                    {data && data.clients && data.clients.length ? data.clients.map( cliente => (
                                        <tr role="row" className="odd"><td><Link  className="home_item_text" to={`/view_client/${cliente.id}`}>{cliente.nombre}</Link></td></tr>
                                    )): null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {

                    (userAuth && userAuth.currentPlan && userAuth.currentPlan.plan == 'Crece' && userAuth.roles[0].name != 'impulsa') ?
                    <div className="col-lg-4 click_card">
                        <div className="white_box QA_section card_height_100">
                            <div className="box_header m-0">
                                <div className="main-title">
                                    <h3 className="m-0">Contactos </h3>
                                </div>
                            </div>
                            <div className="QA_table ">
                                <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                                    <table className="resume_table table lms_table_active2 dataTable no-footer dtr-inline" role="grid">
                                        <tbody>
                                        {data && data.contacts && data.contacts.length ? data.contacts.map( contacto => (
                                            <tr role="row" className="odd"><td><Link  className="home_item_text"to={`/view_contact/${contacto.id}`}>{contacto.nombre}</Link></td></tr>
                                        )): null }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    :null
                }

                
                <div className="col-lg-4 click_card">
                    <div className="white_box QA_section card_height_100">
                        <div className="box_header m-0">
                            <div className="main-title">
                                <h3 className="m-0">Listas </h3>
                            </div>
                        </div>
                        <div className="QA_table ">
                            <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper no-footer">
                                <table className="resume_table table lms_table_active2 dataTable no-footer dtr-inline" role="grid">
                                    <tbody>
                                    {data && data.campaignLists && data.campaignLists.length ? data.campaignLists.map( lista => (
                                        
                                        <tr role="row" className="odd"><td><Link className="home_item_text" to={`/view_maillist/${lista.id}`}>{lista.nombre}</Link></td></tr>
                                    )): null }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
