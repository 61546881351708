import React from 'react'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export const LoaderSpinner = () => {
    return (
        <div className="text-center">
        <Loader
            type="ThreeDots"
            color="#d88603"
            height={30}
            width={30}
            />
            </div>
    )
}
