import {
    ADD_CAMPAIGN,
    ADD_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_ERROR,

    BEGIN_GETTING_CAMPAIGNS,
    GETTING_CAMPAIGNS_SUCCESS,
    GETTING_CAMPAIGNS_ERROR,

    BEGIN_GETTING_CAMPAIGN,
    GETTING_CAMPAIGN_SUCCESS,
    GETTING_CAMPAIGN_ERROR,

    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_ERROR,

    CREATE_LIST_CAMPAIGN,
    CREATE_LIST_CAMPAIGN_SUCCESS,
    CREATE_LIST_CAMPAIGN_ERROR,

    UPDATE_LIST_CAMPAIGN,
    UPDATE_LIST_CAMPAIGN_SUCCESS,
    UPDATE_LIST_CAMPAIGN_ERROR,

    TRYING_ADDING_ELEMENT_LIST,
    ADDING_ELEMENT_LIST_SUCCESS,
    ADDING_ELEMENT_LIST_ERROR,

    TRYING_GET_ELEMENT_LIST,
    GET_ELEMENT_LIST_SUCCESS,
    GET_ELEMENT_LIST_ERROR,

    TRY_UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_DRAW_SUCCESS,
    UPDATE_CAMPAIGN_DRAW_ERROR,

    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_ERROR,

    TRY_SEND_CAMPAIGN,
    SEND_CAMPAIGN_SUCCESS,
    SEND_CAMPAIGN_ERROR,

    TRY_SENDTESTMAIL,
    SENDTESTMAIL_SUCCESS,
    SENDTESTMAIL_ERROR,

    TRY_GETSTATICS,
    GETSTATICS_SUCCESS,
    GETSTATICS_ERROR,

    TRY_CREATE_DESIGN,
    CREATE_DESIGN_SUCCESS,
    CREATE_DESIGN_ERROR,

    TRY_MARKETING_DESIGN,
    MARKETING_DESIGN_SUCCESS,
    MARKETING_DESIGN_ERROR,

    TRY_DELETE_DESIGN,
    DELETE_DESIGN_SUCCESS,
    DELETE_DESIGN_ERROR,

    TRY_SET_DESIGN,

}
from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function set_design(design){
    return async ( dispatch ) => {
        dispatch( try_set_design(design) );
        
    }  
}
const try_set_design = (design) => ({
    type:TRY_SET_DESIGN,
    payload:design   
})

export function delete_design(id){
    return async ( dispatch ) => {
        dispatch( try_delete_design() );
        try {
            
            const respuesta = await clienteAxios.delete(`/campaigns/delete_design/${id}`);
            dispatch( delete_design_success (respuesta.data) )
            
        } catch (error) {
            console.log(error)
            dispatch( delete_design_error(error.message) );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const try_delete_design = () => ({
    type:TRY_DELETE_DESIGN,
    payload:true   
})

const delete_design_success = ( data ) => ({
    type:DELETE_DESIGN_SUCCESS,
    payload:data   
})

const delete_design_error = (error) => ({
    type:DELETE_DESIGN_ERROR,
    payload:error   
})


export function get_marketing_designs(sinhtml = null){
    return async ( dispatch ) => {
        dispatch( try_marketing_designs() );
        try {
            
            const respuesta = await clienteAxios.get(`/campaigns/get_marketing_designs/${sinhtml ? '' : '?html=true'}`);
            dispatch( marketing_designs_success (respuesta.data) )
            
        } catch (error) {
            console.log(error)
            dispatch( marketing_designs_error(error.message) );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const try_marketing_designs = () => ({
    type:TRY_MARKETING_DESIGN,
    payload:true   
})

const marketing_designs_success = ( data ) => ({
    type:MARKETING_DESIGN_SUCCESS,
    payload:data   
})

const marketing_designs_error = (error) => ({
    type:MARKETING_DESIGN_ERROR,
    payload:error   
})

export function create_design( data ){
    return async ( dispatch ) => {
        dispatch( try_create_design() );
        try {
            const formData = new FormData();
            formData.append("imagen", data.imagen);
            formData.append("nombre", data.nombre);
            formData.append("categoria", data.categoria);
            formData.append("data", JSON.stringify(data.data));
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            const respuesta = await clienteAxios.post(`/campaigns/create_design${data.design ? '?design='+data.design.id : ''}`,formData,config);
            dispatch( create_design_success (respuesta.data) )
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'El diseño ha sido creado'
            })
        } catch (error) {
            console.log(error)
            dispatch( create_design_error(error.message) );
            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const try_create_design = () => ({
    type:TRY_CREATE_DESIGN,
    payload:true   
})

const create_design_success = ( data ) => ({
    type:CREATE_DESIGN_SUCCESS,
    payload:data   
})

const create_design_error = (error) => ({
    type:CREATE_DESIGN_ERROR,
    payload:error   
})


export function get_statics(id){
    return async ( dispatch ) => {
        dispatch( tryget_statics() );
        try {
            const respuesta = await clienteAxios.get(`/campaigns/statics/${id}`);
            dispatch( get_staticsSuccess(respuesta.data) )

          

        } catch (error) {
            console.log(error)

            dispatch( get_staticsError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const tryget_statics = () => ({
    type:TRY_GETSTATICS,
    payload:true   
})

const get_staticsSuccess = ( data ) => ({
    type:GETSTATICS_SUCCESS,
    payload:data   
})

const get_staticsError = (error) => ({
    type:GETSTATICS_ERROR,
    payload:error   
})



export function sendTestEmail(data){
    return async ( dispatch ) => {
        dispatch( trysendTestEmail() );
        try {
            await clienteAxios.post(`/campaigns/sendtestmail/`,data);
            dispatch( sendTestEmailSuccess() )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Se ha enviado el email de prueba!'
            })

        } catch (error) {
            console.log(error)

            dispatch( sendTestEmailError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const trysendTestEmail = () => ({
    type:TRY_SENDTESTMAIL,
    payload:true   
})

const sendTestEmailSuccess = () => ({
    type:SENDTESTMAIL_SUCCESS,
    payload:true   
})

const sendTestEmailError = (error) => ({
    type:SENDTESTMAIL_ERROR,
    payload:error   
})


export function sendCampaign(capaignId){
    return async ( dispatch ) => {
        dispatch( trysendCampaign() );
        try {
            await clienteAxios.get(`/campaigns/send/${capaignId}`);
            dispatch( sendCampaignSuccess() )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Se ha enviado la campaña!'
            })

        } catch (error) {

            if(error.response && error.response.data && error.response.data.message){
                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:error.response.data.message
                })

                // dispatch( postNuevoClienteError(error.response.data.message) );
            }else{

                //alerta de error
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Hubo un error, intenta de nuevo'
                })
                dispatch( sendCampaignError("Hubo un error, intenta de nuevo") );
            }

        }
    }  
}

const trysendCampaign = () => ({
    type:TRY_SEND_CAMPAIGN,
    payload:true   
})

const sendCampaignSuccess = () => ({
    type:SEND_CAMPAIGN_SUCCESS,
    payload:true   
})

const sendCampaignError = (error) => ({
    type:SEND_CAMPAIGN_ERROR,
    payload:error   
})

export function actualizarDisenoCampana(capaignId, data){
    return async ( dispatch ) => {
        dispatch( tryUpdateCampaign() );
        try {
             await clienteAxios.post(`/campaigns/campaigns_update`,{capaignId, data});
            dispatch( UpdateCampaignSuccess() )

            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Actualizado correctamente'
            })

        } catch (error) {
            console.log(error)

            dispatch( UpdateCampaignError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }  
}

const tryUpdateCampaign = () => ({
    type:TRY_UPDATE_CAMPAIGN,
    payload:true    
})

const UpdateCampaignSuccess = () => ({
    type:UPDATE_CAMPAIGN_DRAW_SUCCESS,
    payload:true    
})

const UpdateCampaignError = () => ({
    type:UPDATE_CAMPAIGN_DRAW_ERROR,
    payload:true    
})

export function obtenerCampaigns(params){
    return async ( dispatch ) => {
        dispatch( intentaObtenerCampaigns() );
        try {
            let respuesta;
            if(params){
                respuesta = await clienteAxios.get(`/campaigns${params}`);
            }else{
                respuesta = await clienteAxios.get(`/campaigns`);
            }
            dispatch( intentaObtenerCampaignsSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( intentaObtenerCampaignsError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const intentaObtenerCampaigns = () => ({
    type:BEGIN_GETTING_CAMPAIGNS,
    payload:true
})


const intentaObtenerCampaignsSuccess = ( campaings ) => ({
    type:GETTING_CAMPAIGNS_SUCCESS,
    payload:campaings
})

const intentaObtenerCampaignsError = () => ({
    type:GETTING_CAMPAIGNS_ERROR,
    payload:true
})

export function obtenerCampaign( id ){
    return async ( dispatch ) => {
        dispatch( intentaObtenerCampaign() );
        try {
            const respuesta = await clienteAxios.get(`/campaigns/${id}`);
            dispatch( intentaObtenerCampaignSuccess(respuesta.data) )

        } catch (error) {
            console.log(error)

            dispatch( intentaObtenerCampaignError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const intentaObtenerCampaign = () => ({
    type:BEGIN_GETTING_CAMPAIGN,
    payload:true
})


const intentaObtenerCampaignSuccess = ( campaing ) => ({
    type:GETTING_CAMPAIGN_SUCCESS,
    payload:campaing
})

const intentaObtenerCampaignError = () => ({
    type:GETTING_CAMPAIGN_ERROR,
    payload:true
})


export function actualizarCampaign( campaign ){
    return async ( dispatch ) => {
        dispatch( actualizarCampana() );
        try {
            const respuesta = await clienteAxios.put(`/campaigns/${campaign.id}`,campaign);
            // console.log('respuesta.data',respuesta.data)
            if(respuesta.data.success == true){

                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:'Campaña actualizada con éxito!'
                })
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Error',
                    text:respuesta.data.message
                })
            }
            dispatch( actualizarCampanaSuccess(respuesta.data) )
            //alerta de error
        } catch (error) {
            console.log(error)

            dispatch( actualizarCampanaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const actualizarCampana = () => ({
    type:UPDATE_CAMPAIGN,
    payload:true
})


const actualizarCampanaSuccess = ( campaing ) => ({
    type:UPDATE_CAMPAIGN_SUCCESS,
    payload:campaing
})

const actualizarCampanaError = () => ({
    type:UPDATE_CAMPAIGN_ERROR,
    payload:true
})

export function crearCampaign( campaign ){
    return async ( dispatch ) => {
        dispatch( agregarCampana() );
        try {
            const respuesta = await clienteAxios.post(`/campaigns`,campaign);
            // console.log('respuesta.data',respuesta.data)
            if(respuesta.data.success == true){
                dispatch( agregarCampanaSuccess(respuesta.data.data) )
                Swal.fire({
                    icon:'success',
                    title:'Éxito',
                    text:'Campaña agregada con éxito!'
                })
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Error',
                    text:respuesta.data.message
                })
                dispatch( agregarCampanaError(respuesta.data.message) );
            }
            
        } catch (error) {
            console.log(error)

            dispatch( agregarCampanaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const agregarCampana = () => ({
    type:ADD_CAMPAIGN,
    payload:true
})


const agregarCampanaSuccess = ( campaing ) => ({
    type:ADD_CAMPAIGN_SUCCESS,
    payload:campaing
})

const agregarCampanaError = () => ({
    type:ADD_CAMPAIGN_ERROR,
    payload:true
})
export function crearCampanaLista( campaign ){
    return async ( dispatch ) => {
        dispatch( crearListaCampana() );
        try {
             await clienteAxios.post(`/campaign_list`,campaign);
            dispatch( crearListaCampanaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Información creada'
            })
        } catch (error) {
            console.log(error)

            dispatch( crearListaCampanaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const crearListaCampana = () => ({
    type:CREATE_LIST_CAMPAIGN,
    payload:true
})


const crearListaCampanaSuccess = ( campaing ) => ({
    type:CREATE_LIST_CAMPAIGN_SUCCESS,
    payload:campaing
})

const crearListaCampanaError = () => ({
    type:CREATE_LIST_CAMPAIGN_ERROR,
    payload:true
})

export function actualizarCampanaLista( campaign ){
    return async ( dispatch ) => {
        dispatch( actualizarListaCampana() );
        try {
             await clienteAxios.put(`/campaign_list`,campaign);
            dispatch( actualizarListaCampanaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Información actualizada'
            })
        } catch (error) {
            console.log(error)

            dispatch( actualizarListaCampanaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const actualizarListaCampana = () => ({
    type:UPDATE_LIST_CAMPAIGN,
    payload:true
})


const actualizarListaCampanaSuccess = ( campaing ) => ({
    type:UPDATE_LIST_CAMPAIGN_SUCCESS,
    payload:campaing
})

const actualizarListaCampanaError = () => ({
    type:UPDATE_LIST_CAMPAIGN_ERROR,
    payload:true
})


export function agregarElementosAlista( idCampaing, list ){
    return async ( dispatch ) => {
        dispatch( IntentoAgregarElementosAlista() );
        try {
             await clienteAxios.post(`/campaign_list`,{idCampaing, list});
            dispatch( agregarElementosAlistaSuccess() )
            //alerta de error
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Información actualizada'
            })
        } catch (error) {
            console.log(error)

            dispatch( agregarElementosAlistaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const IntentoAgregarElementosAlista = () => ({
    type:TRYING_ADDING_ELEMENT_LIST,
    payload:true
})


const agregarElementosAlistaSuccess = ( campaing ) => ({
    type:ADDING_ELEMENT_LIST_SUCCESS,
    payload:campaing
})

const agregarElementosAlistaError = () => ({
    type:ADDING_ELEMENT_LIST_ERROR,
    payload:true
})


export function getElementosAlista( idCampaing ){
    return async ( dispatch ) => {
        dispatch( IntentoGetElementosAlista() );
        try {
            const respuesta = await clienteAxios.get(`/campaign_list/${idCampaing}`);
            dispatch( getElementosAlistaSuccess(respuesta.data) )
            
        } catch (error) {
            console.log(error)

            dispatch( getElementosAlistaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const IntentoGetElementosAlista = () => ({
    type:TRYING_GET_ELEMENT_LIST,
    payload:true
})


const getElementosAlistaSuccess = ( campaing ) => ({
    type:GET_ELEMENT_LIST_SUCCESS,
    payload:campaing
})

const getElementosAlistaError = () => ({
    type:GET_ELEMENT_LIST_ERROR,
    payload:true
})






export function borrarCampanaAction( idCampaing ){
    return async ( dispatch ) => {
        dispatch( borrarCampana() );
        try {
            const respuesta = await clienteAxios.delete(`/campaigns/${idCampaing}`);
            dispatch( borrarCampanaSuccess(respuesta.data) )
           
            Swal.fire({
                icon:'success',
                title:'Éxito',
                text:'Campaña eliminada'
            })
        } catch (error) {
            console.log(error)

            dispatch( borrarCampanaError(error.message) );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }
    }   
}  

const borrarCampana = () => ({
    type:DELETE_CAMPAIGN,
    payload:true    
})

const borrarCampanaSuccess = () => ({
    type:DELETE_CAMPAIGN_SUCCESS,
    payload:true    
})

const borrarCampanaError = () => ({
    type:DELETE_CAMPAIGN_ERROR,
    payload:true    
})

//
export function duplicate( id ){
    return async ( dispatch )=> {
        dispatch( intentaduplicate() );
        //console.log(id);
        try {
      
            let respuesta = await clienteAxios.post(`/campaigns/duplicate`, {id});
            //console.log(respuesta);
            dispatch( intentaduplicateSuccess(respuesta.data) )

           
        } catch (error) {
            console.log(error)

            dispatch( intentaduplicateError() );

            //alerta de error
            Swal.fire({
                icon:'error',
                title:'Hubo un error',
                text:'Hubo un error, intenta de nuevo'
            })
        }        
    }          
}

const intentaduplicate = () => ({
    type:'DUPLICATE',
    payload:true
})

const intentaduplicateSuccess = ( data ) => ({
    type:'DUPLICATE_SUCCESS',
    payload:data      
})

const intentaduplicateError = () => ({
    type:'DUPLICATE_ERROR',
    payload:true      
})